@import "../../../styles/uitilities.scss";

.Read_or_hide {
  color: rgb(192, 192, 192);
  cursor: pointer;
}

.User_profile_overview {
  &_right_side,
  &_main_col {
    width: 100%;
  }
  &_sec {
    width: 100%;
    background-color: $dashboard-header-bg;
    padding: 30px 28px;
    @include min(768) {
      border-radius: 10px;
      margin: 25px 0;
    }
    @include max(767.98) {
      margin: 10px 0;
    }
    @include max(575.98) {
      padding: 15px;
      margin: 6px 0;
    }
    :global {
      .my-masonry-grid {
        margin-left: 0;
        margin-right: 0;
      }
    }
    &_head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      @include max(575.98) {
        margin-bottom: 12px;
      }
      + div {
        &:global(:not(.profile_overview_blank_page)) {
          margin-left: -10px;
          margin-right: -10px;
        }
      }
      .edit_btn {
        min-width: inherit;
        width: max-content;
        margin: 0;
        padding: 12px 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        color: $text-color-alt;
        @include max(767.98) {
          height: 32px;
          padding-top: 0;
          padding-bottom: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.875rem;
        }
        svg {
          margin-right: 8px;
          margin-top: 1px;
          path {
            fill: $text-color-alt;
            transition: 0.3s ease fill;
          }
        }
        &:hover {
          background-color: $text-color-alt !important;
          color: $dashboard-add-btn-hover !important;
          svg {
            path {
              fill: $dashboard-add-btn-hover;
            }
          }
        }
      }
    }
  }
  &_title {
    font-weight: 500;
    color: $text-color;
    @include min(1200) {
      font-size: size(24);
    }
    @include min-max(576, 1199.98) {
      font-size: size(20);
    }
    @include max(575.98) {
      font-size: size(16);
    }
  }
  :global(.profile_overview_blank_page) {
    min-height: inherit;
    padding: 20px 0;
    @include min(768) {
      padding: 50px 0;
    }
  }
  &_p {
    white-space: pre-line;
    color: $text-color;
    @include min(1200) {
      font-size: size(16);
    }
    @include min-max(576, 1199.98) {
      font-size: size(15);
    }
    @include max(575.98) {
      font-size: size(14);
    }
  }
  &_sec_errorWrap{
    display: block!important;
    margin-bottom: 0px;
    .errorMessage{
      text-align: left!important;
      font-size: 1rem!important;
      opacity: 0.5;
      p{
        margin-bottom: 0px;
      }
      
    }
  }
}

.Stared_actions {
  display: flex;

  :global(.btn:first-child) {
    margin-right: 10px;
  }
}

.errorMessage{
text-align: right;
color: white;
}