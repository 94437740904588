@import '../../styles/uitilities.scss';

.BackHeader {
    width: 100%;
    height: 51px;
    padding: 0 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: $btn-secondary;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    &_btn {
        background-color: transparent;
        border: none;
        color: $white;
        font-size: size(17);
        font-weight: $font-regular;
        svg {
            margin-right: 10px;
            path {
                fill: $white;
            }
        }
    }
}