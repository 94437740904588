@import "../../styles/uitilities.scss";

.contact_form {
  padding: 51px;
  padding-bottom: 63px;
  background: $modal-bg;
  border-radius: 16px;
  max-width: 820px;
  margin-left: auto;
  margin-right: 0;
  z-index: 2;
  position: relative;

  @include max(1399.98) {
    max-width: 640px;
  }

  @include max(1279.98) {
    margin-right: auto;
  }

  @include max(991.98) {
    max-width: 100%;
  }

  @include max(767.98) {
    padding: 50px 30px;
  }

  &_title {
    font-size: size(32);
    font-weight: $font-medium;
    color: $text-color-alt;
    margin-bottom: 61px;
    width: max-content;
    position: relative;

    @include max(1279.98) {
      margin-left: auto;
      margin-right: auto;
    }

    @include max(767.98) {
      font-size: size(26);
      margin-bottom: 40px;
    }

    &::after {
      content: "";
      width: 33px;
      height: 4px;
      background: #267ffd;
      border-radius: 22px;
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translateY(200%);

      @include max(1279.98) {
        left: 50%;
        transform: translate(-50%, 200%);
      }
    }
  }

  .field_error {
    font-size: size(14);
    color: $error-message;
  }

  &_inputs {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;

    :global(.form-group) {
      flex: 0 0 50%;
      max-width: 50%;
      padding: 0 15px;
      margin-bottom: 36px;
      height: 49px;

      @include max(575.98) {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 25px;
      }

      &:global(.textarea) {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 44px;
        height: 100px;

        @include max(767.98) {
          margin-bottom: 30px;
        }

        textarea {
          height: 100px;
          padding-top: 20px;
        }

        label {
          //   top: 25%;
        }
      }

      :global(textarea.focused ~ label) {
        // top: 0;
        // transform: translateY(-60%);
        // font-size: size(12) !important;
      }
    }

    :global(.form-group.error input) {
      border-bottom: 1px solid $label-color;
      color: $label-color;
    }

    :global(.form-group.error label) {
      color: $label-color !important;
    }
  }

  &_label {
    position: absolute;
    top: 50%;
    left: 18px;
    transform: translateY(-50%);
    font-size: size(18) !important;
    font-weight: $font-regular !important;
    color: $contact-input !important;
    padding-bottom: 0 !important;
    z-index: 2;
    transition: 0.3s ease all;

    @include max(767.98) {
      font-size: size(16) !important;
    }
  }

  &_input {
    position: relative;
    background: transparent;
    border: none;
    border-bottom: 1px solid #47475d;
    border-radius: 0;
    height: 100%;
    padding-left: 5px;
    padding-right: 5px;

    &:global(.focused) {
      border-bottom: 1px solid #47475d;
      background: transparent;

      ~.contact_form_label {
        top: 0;
        transform: translateY(-80%);
        font-size: size(12) !important;
      }
    }
    :global{
      .select__input-container{
        &,input{
          color: $text-color-alt;
        }
      }
      .select__single-value{
        color: $text-color-alt;
      }
      .select__menu{
        transform: translateY(4px);
        box-shadow: 0 0 2px rgba(0,0,0,0.5);
      }
      
    }

    +div {
      color: $label-color;
    }
  }

  :global(.dropdown-toggle) {
    width: 100%;
    background-color: transparent;
    border-color: transparent;
    padding: 0;
    height: 49px;
    border-color: transparent !important;

    &::after {
      position: absolute;
      top: 50%;
      right: 5px;
      transform: translateY(-50%) rotate(45deg);
      width: 8px;
      height: 8px;
      background: transparent;
      border-top: 1px solid transparent;
      border-right: 1px solid $text-color-alt;
      border-bottom: 1px solid $text-color-alt;
      border-left: 1px solid transparent;
    }

    &:hover,
    &:focus,
    &:active {
      background-color: transparent !important;
      border-color: transparent !important;
    }

    span {
      left: 5px;
    }
  }

  :global(.dropdown-menu) {
    width: 100%;
    left: -7px !important;
    top: 0px !important;
    height: 300px;
    overflow-y: scroll;
    background-color: $white;
    border-radius: 10px;
    padding: 13px;
    position: relative;

    // transition: 0.5s ease all;
    a {
      font-size: size(17);
      font-weight: $font-regular;
      padding: 6px 10px;
      border-radius: 6px;
      overflow: hidden;
      transition: 0.5s ease all;

      &:hover,
      &:focus {
        color: $text-alt;
        background-color: $dropdown-bg-hover;
      }
    }

    &::-webkit-scrollbar {
      display: block;
    }

    &::-webkit-scrollbar-thumb {
      background: #808080;
      border-radius: 10px;
    }
  }

  &_submit {
    padding: 0 15px;

    @include max(1279.98) {
      text-align: center;
      width: 100%;
    }

    :global(.btn) {
      width: max-content;
      min-width: inherit;
      font-size: size(18);
      font-weight: $font-medium;

      @include max(575.98) {
        max-width: 100%;
        width: 100%;
      }
    }
  }
}

.textArea {
  resize: none !important;
}

.contact_form {

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: none;
    border-bottom: 1px solid #47475d;
    -webkit-text-fill-color: $text-color-alt;
    -webkit-box-shadow: 0 0 0px 1000px $modal-bg inset !important;
    transition: background-color 5000s ease-in-out 0s;
  }
}

.success_message {
  font-size: size(15);
  font-weight: $font-regular;
  display: flex;
  align-items: center;

  @include max(1279.98) {
    text-align: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
  }

  .success {
    color: $green;
  }

  .error {
    color: $error-message;
  }
}