@import "../../../../styles/uitilities.scss";
.selected_image_item{
    display: flex;
    .selected_image{
        height: 58px;
        width: 58px;
        img{
            max-width: 100%;
            width: 100%;
            border-radius: 12px;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
    .selected_image_head{
        padding-left: 15px;
        margin-top: auto;
        margin-bottom: auto;
        .selected_img_title{
            margin-bottom: 6px;
            font-size: size(18);
            font-weight: 500;
            color: $text-color;
        }
        p{
            margin-bottom: 0px;
            font-size: size(14);
            color: $dashboard-add-btn-border;
        }
    }
    .lock_btn {
        cursor: pointer;
  
        svg {
          path {
            fill: #fff;
          }
        }
      }
}
.locked{
    filter: brightness(50%);
    width: 100%;
    .selected_image_head{
        display: flex;
    justify-content: space-between;
    width: 100%;
    }
}