@import "../../../../src/styles/uitilities.scss";

.loggedin_header {
  width: 100vw;
  padding: 0;
  background-color: $dashboard-header-bg;
  padding: 13px 0;
  position: fixed;
  top: 0;
  z-index: 999;
  animation: navbarAnim 300ms ease-out;
  box-shadow: 0 2px 9px #07145412;
  @include max(575.98) {
    z-index: 99;
  }
  :global(.dropdown-menu) {
    background-color: $white;
    border-radius: 10px;
    padding: 13px;
    position: relative;
    right: 0 !important;
    left: inherit !important;
    transition: 0.5s ease all;
    @include max(1279.98) {
      right: inherit !important;
      left: 0;
    }
    &::before {
      content: "";
      background: $white;
      position: absolute;
      right: 20px;
      width: 14px;
      height: 14px;
      @include max(1279.98) {
        right: inherit;
        left: 20px;
      }
    }
    &[x-placement="bottom-start"] {
      transform: translate3d(0px, 55px, 0px) !important;
      &::before {
        top: 0;
        transform: translateY(-50%) rotate(135deg);
        border: 1px solid transparent;
        border-radius: 0 0 0 5px;
        border-bottom: 1px solid $border-color;
        border-left: 1px solid $border-color;
      }
    }
    &[x-placement="top-start"] {
      transform: translate(0px, -64px) !important;
      &::before {
        bottom: 0;
        transform: translateY(50%) rotate(135deg);
        border: 1px solid transparent;
        border-radius: 0 5px 0 0;
        border-top: 1px solid $border-color;
        border-right: 1px solid $border-color;
      }
    }
    a {
      font-size: size(17);
      font-weight: $font-regular;
      padding: 6px 10px;
      border-radius: 6px;
      overflow: hidden;
      transition: 0.5s ease all;
      &:hover,
      &:focus {
        color: $text-alt;
        background-color: $dropdown-bg-hover;
      }
    }
  }
  &_left {
    display: flex;
    justify-content: space-between;
    max-width: 990px;
    width: 100%;
    @include max(1599.98) {
      max-width: 850px;
    }
    @include max(1350) {
      max-width: 700px;
    }
  }
  &_logo {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    user-select: none;
    &:first-of-type {
      display: block;
    }
    &:last-of-type {
      display: none;
    }
    img{
      max-width: 135px;
    }

  }
  &_container {
    @include min(576) {
      padding-left: 36px;
      padding-right: 36px;
    }
    @include max(575.98) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  &_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &_nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
    .btn_create_tree {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 13px;
      height: 50px;
      &:focus,
      &:active {
        color: white;
      }
      &:hover {
        background-color: $btn-primary-hover;
        border-color: $btn-primary-hover;
      }
      svg {
        margin-right: 15px;
        path {
          fill: #ffffff;
          transition: 0.5s ease all;
        }
      }
    }
    .bell_icon {
      padding: 0 0 0 30px;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        top: 5px;
        right: 1px;
        width: 10px;
        height: 10px;
        background: #f65543;
        border: 2px solid $dashboard-header-bg;
        border-radius: 50%;
        overflow: hidden;
        transform: scale(0);
        transition: 0.3s ease all;
      }
      &.active {
        svg {
          animation: bellShake 1.3s ease infinite forwards;
          transform-origin: top center;
        }
        &::after {
          transform: scale(1);
        }
      }
      svg {
        path {
          fill: $text-color-alt;
          transition: 0.5s ease all;
        }
      }
    }
    :global(.dropdown.show) {
      .profile_dropdown {
        background-color: transparent;
        border-color: transparent;
      }
    }
    :global(.dropdown-menu) {
      background-color: $white;
      border-radius: 10px;
      padding: 13px;
      position: relative;
      right: 0 !important;
      left: inherit !important;
      transition: 0.5s ease all;
      &::before {
        content: "";
        background: $white;
        position: absolute;
        right: 30px;
        transition: 0.5s ease all;
      }
      a {
        font-size: size(17);
        font-weight: $font-regular;
        padding: 6px 10px;
        border-radius: 6px;
        overflow: hidden;
        transition: 0.5s ease all;
        &:hover,
        &:focus {
          color: $text-alt;
          background-color: $dropdown-bg-hover;
        }
      }
    }
    .profile {
      &_dropdown {
        display: flex;
        align-items: center;
        background-color: transparent;
        border-color: transparent;
        padding: 0 25px 0 30px;
        transition: 0.5s ease all;
        &:active {
          background-color: transparent;
          border-color: transparent;
        }
        &::after {
          position: absolute;
          top: 50%;
          transform: translateY(-75%);
          right: 0;
          border-top: 7px solid $border-color-2;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          transition: 0.5s ease all;
        }
      }
      &_image {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        position: relative;
        overflow: hidden;
        margin-bottom: 0;
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: block;
          object-fit: cover;
          max-width: 100%;
          max-height: 100%;
          transition: 1s ease all;
        }
        &::after {
          content: "";
          display: block;
          height: 0;
          width: 100%;
          padding-bottom: 45%;
        }
      }
      &_details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 11px;
        .profile_name_shimmer {
          width: 75px;
          height: 15px;
          border-radius: 3px;
          background-color: $shimmer-bg;
          @extend .shimmer-styles;
        }
        .username {
          font-size: size(16);
          font-weight: $font-medium;
          padding-bottom: 3px;
          color: $profile-details-color;
        }
        .ownership {
          font-size: size(14);
          font-weight: $font-regular;
          color: $profile-details-color;
          opacity: 0.58;
        }
      }
    }
  }
  @include min-max(992, 1350) {
    .loggedin_header {
      &_logo {
        padding-right: 35px;
      }
      &_nav {
        max-width: calc(100% - 399px);
        padding-right: 15px;
        .bell_icon {
          padding-left: 15px;
        }
        .profile_dropdown {
          padding-left: 12px;
        }
        .btn_create_tree {
          min-width: 160px;
        }
      }
    }
  }
}

.menu_dark_theme_toggler {
  position: unset;
  transform: none;
  height: 50px;
  border-radius: 5px;
  min-width: inherit;
  margin-right: 16px;
  padding: 5px 13px;
  background-color: transparent;
  box-shadow: none;
  border: 1px solid $dark-toggler-border;
  @include max(1279.98) {
    width: 35px;
    height: 35px;
    padding: 5px;
    align-self: flex-end;
    justify-content: center;
    align-items: center;
    svg {
      width: 22px;
      position: relative;
      top: 0.5px;
      @include min(576) {
        width: 24px;
      }
    }
  }
  :global {
    .theme-mode {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  :global(.theme-dark) {
    svg {
      path {
        fill: $text-color-alt;
      }
    }
  }
}

.loggedin_header_wrap_mob {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 -5px;
  .profile_dropdown_mob {
    padding: 0px;
    min-width: 25px;
    background-color: transparent;
    border: none;
    border-radius: 30px;
    overflow: hidden;
    transition: 0.5s ease all;
    .profile_image {
      width: 36px;
      height: 36px;
      margin-bottom: 0px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    &:after {
      display: none;
    }
  }
  .bell_icon {
    display: inline-block;
    position: relative;
    &.active {
      &:after {
        content: "";
        position: absolute;
        top: 5px;
        right: 1px;
        width: 10px;
        height: 10px;
        background: #f65543;
        border: 2px solid $dashboard-header-bg;
        border-radius: 50%;
        transform: scale(1);
        transition: 0.3s ease all;
      }
      svg {
        animation: bellShake 1.3s ease infinite forwards;
        transform-origin: top center;
      }
    }
  }
  &_wrap {
    :global {
      .dropdown-toggle {
        margin-left: 15px;
      }
      .dropdown-menu[x-placement="bottom-end"],
      .dropdown-menu[x-placement="bottom-start"] {
        transform: translate3d(-105px, 55px, 0px) !important;
        &:before {
          transform: translateY(-130%) translateX(105px) rotate(135deg) !important;
        }
      }
    }
  }
}
.header_right_btn_wrap_mob {
  display: flex;
  align-items: center;
  .search_bar_search_icon_mob {
    padding-left: 10px;
    padding-right: 10px;
    background-color: transparent;
    border: none;
    svg {
      path {
        fill: $text-color;
        transition: 0.5s ease all;
      }
    }
  }
  .bell_icon {
    padding-left: 10px;
    svg {
      path {
        fill: $text-color;
        transition: 0.5s ease all;
      }
    }
  }
  .btn_create_tree_mob {
    background-color: transparent;
    padding: 0;
    min-width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 4px;
    border-color: $primary-color;
    @include max(1279.98) {
      width: 34px;
      height: 34px;
      @include min(576) {
        width: 33px;
        height: 33px;
      }
    }
    &:hover {
      background-color: $primary-color;
      border-color: $primary-color;
      svg {
        path {
          fill: #fff;
        }
      }
    }
    svg {
      path {
        fill: $dashboard-add-btn;
      }
    }
  }
}

.logoutSection {
  height: 200px;
  display: flex;
  justify-content: center;
  color: $text-color;
  flex-direction: column;
  .title {
    text-align: center;
    margin-bottom: 50px;
    font-size: 20px;
    font-weight: 500;
  }
  ul {
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  li {
    list-style: none;
    background-color: #1d5eff;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  li:nth-child(2) {
    background: black;
  }
}
@include max(1279.98) {
  :global(body) {
    padding-top: 54px;
  }
  .loggedin_header {
    padding: 8px 0;
    height: 52px;
  }
}

.logoutsection_btn_wrap {
  button {
    max-width: 100px;
    min-width: 74px;
    margin: 0 10px !important;
    display: inline-block !important;
    &:global(.btn-light) {
      background-color: transparent;
      border-color: var(--btnhover2);
      color: $text-color;
      &:hover {
        background-color: var(--btnhover2);
        border-color: var(--btnhover2);
      }
    }
  }
}
:root {
  --btnhover2: #e4e4e4;
}
:global(body.dark) {
  --btnhover2: #5b5b68;
}

:global(.dark) {
  .loggedin_header_logo {
    &:first-of-type {
      display: none;
    }
    &:last-of-type {
      display: block;
    }
  }
}
:global(.logout-modal .modal-dialog) {
  max-width: 400px;
}
:global(.logout-modal .modal-body .btn) {
  width: 100%;
  max-width: 135px;
}

.page_loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: block;
  background-color: white;
}

.prelogin_mobile_header {
  &_hamburger {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: none;
    background: none;
    @include min-max(576, 767.9) {
      margin-left: 11px;
    }
    @include max(575.9) {
      margin-left: 6px;
    }

    span {
      display: block;
      width: 35px;
      height: 3px;
      background: $text-color;
      margin-bottom: 6px;
      border-radius: 6px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &_menu_wrap {
    height: 100% !important;
    min-height: 200px;

    ul {
      padding-left: 10px !important;

      li {
        padding: 0 15px;
        &.active {
          background-color: #23cc2b;
          border-radius: 6px;
        }
      }
    }
  }
  &.menu_open {
    transform: translateX(0);
    .prelogin_mobile_header_menu_wrap {
      opacity: 1;
      ul {
        padding-left: 5px;
      }
    }
    + .menu_overlay {
      opacity: 1;
      visibility: visible;

      width: 100%;
      height: 100%;
      position: fixed;
      left: 0;
      top: 0;
      background-color: rgba(0, 0, 0, 0.462745098);
      z-index: 10000;
    }
  }

  &_menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0px 0 0;
    background: $mobile-menu-bg;
    max-width: 320px;
    width: 80%;
    min-width: 250px;
    z-index: 99990;
    transform: translateX(-100%);
    transition: 0.7s ease transform;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }
    :global(.container){
      height: calc(100% - 108px);
    }
    @include max(575.98) {
      max-width: 320px;
    }
    &.menu_open {
      transform: translateX(0);
      .prelogin_mobile_header_menu_wrap {
        opacity: 1;
      }
      + .menu_overlay {
        opacity: 1;
        visibility: visible;

        width: 100%;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.462745098);
        z-index: 10000;
      }
    }
    .close_menu {
      position: absolute;
      top: 16px;
      right: 16px;
      width: 42px;
      height: 42px;
      border-radius: 50%;
      border: none;
      background: none;
      transition: 0.3s ease all;
      &:hover {
        background: $close-menu-bg;
      }
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        path {
          fill: $text-color-alt;
        }
      }
    }
    ul {
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
      padding-top: 15px;
      li {
        margin-bottom: 1px;
        padding-top: 12px;
        padding-bottom: 12px;
        text-transform: capitalize;
        color: $text-color;
        &.active {
          a {
            color: $dashboard-menu-active !important;
          }
        }
        button {
          background-color: transparent;
          border: none;
        }
        a,
        button {
          font-size: size(14);
          font-weight: $font-regular;
          color: $text-color-alt !important;
          @include min(576) {
            font-size: size(16);
          }
          span {
            min-width: 40px;
            display: inline-block;
            svg {
              path {
                fill: currentColor;
              }
            }
          }
        }
        &.submenu {
          position: relative;
          cursor: pointer;
          &::after {
            content: "";
            width: 10px;
            height: 10px;
            border: 2px solid transparent;
            border-right: 2px solid $text-color-alt;
            border-bottom: 2px solid $text-color-alt;
            background: transparent;
            transform: translateY(-50%) rotate(45deg);
            position: absolute;
            right: 0;
            top: 50%;
          }
          button {
            width: 100%;
            text-align: left;
          }
        }
      }
    }
    &_wrap {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      opacity: 0;
      transition: 0.5s ease opacity;
      @include min(576) {
        padding-left: 15px;
      }
    }
  }

  .menu_overlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    background-color: #00000076;
    z-index: 88;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s ease all;
  }

  &_logo_wrap {
    padding: 18px 25px;
    border-bottom: 1px solid $input-border;
    margin-bottom: 15px;
  }

  &_wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    opacity: 0;
    transition: 0.5s ease opacity;
    @include min(576) {
      padding-left: 15px;
    }
  }
}
.DarkThemeToggler_menu {
  display: flex;
  align-items: center;
  color: $text-color;
  padding: 22px 25px 30px 25px;
  border-top: 1px solid $input-border;
  margin-left: calc(var(--bs-gutter-x) * -0.5);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  &_icon_wrap {
    width: 40px;
  }
  :global {
    .toggle-switch {
      margin-left: auto;
      width: 48px;
      height: 27px;
      .slider:before {
        height: 23px;
        width: 23px;
      }
      input:checked + .slider:before {
        transform: translateX(88%) translateY(-50%);
      }
    }
  }
}

.shimmer-styles {
  border-radius: 5px;
  width: 100%;
  background: $shimmer-bg;
  background-image: $shimmer-gradient;
  background-repeat: no-repeat;
  background-size: 800px 104px;
  display: inline-block;
  position: relative;

  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
}

@keyframes bodyfadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bellShake {
  0% {
    transform: rotate(0deg);
  }
  7.69% {
    transform: rotate(10deg);
  }
  15.38% {
    transform: rotate(0deg);
  }
  23.07% {
    transform: rotate(-10deg);
  }
  30.76% {
    transform: rotate(0deg);
  }
  38.45% {
    transform: rotate(10deg);
  }
  46.14% {
    transform: rotate(0deg);
  }
  52.83% {
    transform: rotate(-10deg);
  }
  61.59% {
    transform: rotate(0deg);
  }
  69.21% {
    transform: rotate(0deg);
  }
  76.9% {
    transform: rotate(0deg);
  }
  84.59% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }
  
  100% {
    background-position: 468px 0; 
  }
}