@import "../../../../styles/uitilities.scss";


.err_container {
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .message {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 5vh;
        img{
            width: 100px;
            margin: auto;
            display: block;
        }
        h6 {
            font-size: 26px;
            margin-top: 20px;
            color: $text-color;
        }

        .vertical_line {
            width: 2px;
            height: 80%;
            background-color: #ccc;
            margin: 0 20px;
        }
    }

    .err_btn {
        border: 1px solid gray;
        font-size: 16px;
        padding: 5px 14px;
        border-radius: 5px;
        color: $text-color;
        cursor: pointer;
        margin-top: 70px;
        transition: .3s ease-in;
        &:hover{
            background: #fff;
            color: #000;
        }

    }
}