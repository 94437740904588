@import "../../../styles/uitilities.scss";

.CheckoutPlanDetails {
    
    padding: 80px 80px 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include min(992){
        max-width: 550px;
    }
    @include max(575.9){
        padding: 20px 30px;
    }
    &__plan {
        color: #d9800b;
        font-size: size(18);
        font-weight: $font-medium;
        margin-bottom: 21px;
        line-height: 1.4;
    }

    &__price {
        color: $plan-month;
        font-family: $font-family-inter;
        font-size: size(14);
        font-weight: $font-regular;
        margin-bottom: 21px;
        line-height: 1;

        span {
            font-size: size(36);
            font-weight: 700;
            color: $text-color-alt;
        }
    }

    &__description {
        font-size: size(17.5);
        font-weight: $font-regular;
        line-height: 26px;
        color: $text-color;
        margin-bottom: 25px;
    }

    &__planFeatures {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;

        li {
            font-size: size(16);
            font-weight: $font-regular;
            line-height: 22px;
            color: $text-color-alt;
            position: relative;
            letter-spacing: 0.16px;

            &:not(:last-child) {
                margin-bottom: 13px;
            }

            &::before {
                content: "";
                width: 6px;
                height: 11px;
                border: 2px solid transparent;
                border-right: 2px solid #23cc2b;
                border-bottom: 2px solid #23cc2b;
                position: absolute;
                top: 0.65em;
                left: -22px;
                transform: translateY(-50%) rotate(45deg);
            }
        }
    }

    .price {
        margin-left: 10px;
    }

    ul {
        list-style: none;
    }
}
