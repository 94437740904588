@import "./uitilities.scss";
@import "~bootstrap/scss/bootstrap";

// deafult Color
:root {
  --body-bg: #ffffff;
  --header-bg: #ffffff;
  --text-color: #252533;
  --text-color-alt: #000000;
  --input-bg: #ffffff;
  --close-hover-bg: #efefef;
  --dark-bg-alt: #001631;
  --dark-bg-alt-2: #f6f6f6;
  --social-icon-color: #2b2b2b;
  --social-icon-hover: #ffffff;
  --social-icon-bg: #2b2b2b;
  --footer-bg: #f5f5f7;
  --post-footer-color: #777778;
  --post-footer-bg: #efefef;
  --card-bg: #ffffff;
  --text-alt-hover: #0a2cea;
  --dark-bg-alt-3: #f3f6fb;
  --modal-link: #085cfd;
  --modal-bg: #ffffff;
  --tab-bg: #f2f2f2;
  --tab-active-bg: #ffffff;
  --label-bg: #ffece2;
  --label-color: #f03b0f;
  --profile-details-color: #193b68;

  --gradient-bg: linear-gradient(90deg, #001832, #03393a);

  --card-box-shadow: #d7dae4;
  --card-box-shadow-alt: #cfdef746;

  --card-border: #e3e5f169;
  --input-border: #d3d5db;
  --input-border-alt: #d3d5db;
  --dashboard-item-border: #e0e2e8;

  --pagination-bullet: #c2c5d4;
  --pagination-bullet-active: #062cea;

  --dashboard-bg: #f6f6f6;
  --dashboard-bg-alt: #ffffff;

  --dashboard-header-bg: #ffffff;

  --dashboard-menu-item: #4c4c4c;
  --dashboard-menu-active: #ffffff;
  --dashboard-menu-active-bg: #23cc2b;
  --dashboard-menu-hover: #000000;
  --dashboard-menu-hover-bg: #f6f7f8;

  --dashboard-btn-hover: #ffffff;
  --dashboard-btn-gray: #a09ea6;
  --dashboard-btn-gray-hover: #000000;
  --dashboard-btn-gray2: #d9d9d9;
  --dashboard-btn-gray2-text: #707070;
  --dashboard-btn-gray2-hover: #bebebe;

  --dashboard-add-btn: #1d5eff;
  --dashboard-add-btn-border: #a2a4ac;
  --dashboard-add-btn-hover: #ffffff;
  --dashboard-add-btn-bg-hover: #1d5eff;

  --rearrange-close-bg: #00000012;
  --plan-month: #00000079;
  --menu-link: #c9c9c9;
  --mobile-menu-bg: #ffffff;
  --close-menu-bg: #00163116;
  --swiper-nav-bg: #000000;
  --banner-video-unload-bg: #e9e9e9;
  --file-upload-bg: #fbfffb;
  --file-upload-hover-bg: #eaf8ea;
  --file-preview-bg: #ffffff;
  --dark-toggle: #ffffff;
  --dark-toggle-bg: #000000;
  --dashboard-loader: #ffffff;

  --gallery-img-bg: #dbdbdb;

  --shimmer-bg: #f6f7f8;
  --shimmer-gradient: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);

  --header-menu-item: #252533;
  --header-menu-item-active: #0a2cea;
  --testimonial-swiper-arrow-bg: #747474;
  --otp-border: #e3e5f1;
  --shimmer-bg-alt: #EEEEEE;
  // --shimmer-gradient-alt: linear-gradient(to right, #e6e6e6 5%, #e1e1e1 25%, #e6e6e6 35%);

  --shimmer-gradient-alt-0 : #EEEEEE;
  --shimmer-gradient-alt-10 : #E8E8E8;
  --shimmer-gradient-alt-20 : #ECECEC;
  --shimmer-gradient-alt-30 : #E8E8E8;
  --shimmer-gradient-alt-50 : #EEEEEE;
  --shimmer-gradient-alt-100 : #EEEEEE;


  --shimmer-green-bg: #00968850;
  --shimmer-green-gradient: linear-gradient(to right, #00968830 0%, #00968860 30%, #00968830 40%, #00968830 100%);
  --contact-input: #595959;
  --contact-page-bg: #f3f6fb;
  --text-opacity: #00000049;
  --input-label: #8e9097;
  --placeholder-image: #d3d5db;
  --text-label-grey: #767678;
  --dashboard-header-border: #d3d5db;
  --button-dark-alt: #ffffff;
  --button-dark-border-alt: #000000;
  --slider-track: #dee2e6;
  --border-opacity-dark: #d3d5db8a;
  --blank-folder-outer: #e0e2e8;
  --blank-folder-bg: #c0c1c7;
  --dark-toggler-border: #1a1a1e28;
  --fu-scrollbar-thumb: #d2d4da;
  --fu-scrollbar-track: #07145407;
  --searchbar-placeholder: #7a8da7;
  --search-filter-input: #eff0f3;
  --demo-bg: #2e60c9;
  --dashboard-nav-btn-hover: #1d5eff;
  --dashboard-nav-btn-hover-bg: transparent;
  --dashboard-nav-btn-hover-text: #1d5eff;
  --dashboard-dropdown-border: #a09ea6;
  --edit-image-button-bg: #ecf3ff;
  --edit-image-button-text: #768184;
  --edit-image-button-text-hover: #4958bc;
  --tree-connection-line: #d3d5db;
  --tree-connection-dot: #ffffff;

  --checkout-modal-bg: #f6f6f6;
  --checkout-modal-bg-alt: #e9e9e9;
  --album-header-tab: #969696;
  --imageInvert: 0;
}
.dark {
  --body-bg: #070724;
  --header-bg: #0c0c2b;
  --text-color: #ffffff;
  --text-color-alt: #ffffff;
  --input-bg: #3d3d4a;
  --close-hover-bg: #3d3d4a;
  --dark-bg-alt: #12122e;
  --dark-bg-alt-2: #292940;
  --social-icon-color: #ffffff;
  --social-icon-hover: #2b2b2b;
  --social-icon-bg: #ffffff;
  --footer-bg: #050519;
  --post-footer-color: #ffffff65;
  --post-footer-bg: #0b0b1e;
  --card-bg: #323241;
  --text-alt-hover: #267ffd;
  --dark-bg-alt-3: #12122e;
  --modal-link: #23cc2b;
  --modal-bg: #323241;
  --tab-bg: #22223b;
  --tab-active-bg: #555565;
  --label-bg: #5b5b68;
  --label-color: #ffffff;
  --profile-details-color: #ffffff;

  --gradient-bg: #12122e;

  --card-box-shadow: #1a1a1e28;
  --card-box-shadow-alt: #1a1a1e28;

  --card-border: transparent;
  --input-border: #3d3d4a;
  --input-border-alt: #ffffff;
  --dashboard-item-border: #545454;

  --pagination-bullet: #ffffffb3;
  --pagination-bullet-active: #ffffff;

  --dashboard-bg: #1d1d29;
  --dashboard-bg-alt: #292936;
  --dashboard-loader: #323241;
  --dashboard-header-bg: #323241;

  --dashboard-menu-item: #bfbfc3;
  --dashboard-menu-active: #ffffff;
  --dashboard-menu-active-bg: #23cc2b;
  --dashboard-menu-hover: #ffffff;
  --dashboard-menu-hover-bg: #323241;

  --dashboard-btn-hover: #000000;

  --dashboard-btn-gray: #ffffff;
  --dashboard-btn-gray-hover: #bfbfc3;
  --dashboard-btn-gray2: #ffffff;
  --dashboard-btn-gray2-text: #000000;
  --dashboard-btn-gray2-hover: #d9d9d9;

  --dashboard-add-btn: #ffffff;
  --dashboard-add-btn-border: #ffffff;
  --dashboard-add-btn-hover: #000000;
  --dashboard-add-btn-bg-hover: #ffffff;

  --rearrange-close-bg: #ffffff;
  --plan-month: #ffffff;
  --menu-link: #ffffff;
  --mobile-menu-bg: #1d1d29;
  --close-menu-bg: #ffffff16;
  --swiper-nav-bg: #23cc2b;
  --banner-video-unload-bg: #292940;
  --file-upload-bg: #1c2c27;
  --file-upload-hover-bg: #314c44;
  --file-preview-bg: #1d1d29;
  --dark-toggle: #000000;
  --dark-toggle-bg: #ffffff;

  --gallery-img-bg: #323241;

  --shimmer-bg: #3a3a3a;
  --shimmer-gradient: linear-gradient(
    to right,
    #3a3a3a 0%,
    #3f3f3f 10%,
    #4a4a4a 20%,
    #3f3f3f 30%,
    #3a3a3a 50%,
    #3a3a3a 100%
  );

  --header-menu-item: #ffffff70;
  --header-menu-item-active: #ffffff;
  --testimonial-swiper-arrow-bg: #777;
  --otp-border: transparent;
  // --shimmer-bg-alt: #323241;
  --shimmer-bg-alt: #20202C;

  // --shimmer-gradient-alt-0 : #323241;
  // --shimmer-gradient-alt-10 : #343444;
  // --shimmer-gradient-alt-20 : #373748;
  // --shimmer-gradient-alt-30 : #3c3c4e;
  // --shimmer-gradient-alt-50 : #323241;
  // --shimmer-gradient-alt-100 : #323241;

  --shimmer-gradient-alt-0 : #20202C;
  --shimmer-gradient-alt-10 : #23232F;
  --shimmer-gradient-alt-20 : #252531;
  --shimmer-gradient-alt-30 : #23232F;
  --shimmer-gradient-alt-50 : #20202C;
  --shimmer-gradient-alt-100 : #20202C;



  --shimmer-green-bg: #00968850;
  --shimmer-green-gradient: linear-gradient(to right, #00968830 0%, #00968860 30%, #00968830 40%, #00968830 100%);
  --contact-input: #dddde5;
  --contact-page-bg: #070724;
  --text-opacity: #ffffff49;
  --input-label: #ffffff;
  --placeholder-image: #7b7b94;
  --text-label-grey: #ffffff68;
  --dashboard-header-border: #44444e;
  --button-dark-alt: #191e2b;
  --button-dark-border-alt: #191e2b;
  --slider-track: #5a5d68;
  --border-opacity-dark: #d3d5db56;
  --blank-folder-outer: #323241;
  --blank-folder-bg: #ffffff;
  --dark-toggler-border: #ffffff48;
  --fu-scrollbar-thumb: #d2d4da;
  --fu-scrollbar-track: #07145407;
  --searchbar-placeholder: #ffffff;
  --search-filter-input: #070724;
  --demo-bg: #12122e;
  --dashboard-nav-btn-hover: #ffffff;
  --dashboard-nav-btn-hover-bg: #ffffff;
  --dashboard-nav-btn-hover-text: #000000;
  --dashboard-dropdown-border: #000000;
  --edit-image-button-bg: #292936;
  --edit-image-button-text: #a7a7a7;
  --edit-image-button-text-hover: #ffffff;
  --tree-connection-line: #545454;
  --tree-connection-dot: #838383;

  --checkout-modal-bg: #323241;
  --checkout-modal-bg-alt: #292936;
  --album-header-tab: #323241;
  --imageInvert: 1;
}









* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
html,
body {
  height: inherit !important;
}

body {
  padding-top: 83px;
  background-color: $body-background !important;
  font-family: $font-family !important;
  scrollbar-width: none;
  counter-reset: count;

  &.small-header {
    padding-top: 69px;
    @include max(1279.98) {
      padding-top: 62px;
    }
  }
}
html {
  font-size: 16px;
  overflow: auto;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  scroll-behavior: auto !important;
  scroll-padding-top: 100px;
  font-family: "Noto Sans", sans-serif;
  @include max(1600) {
    font-size: 16px;
    overflow: auto;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    text-size-adjust: 100%;
    scroll-behavior: auto !important;
    scroll-padding-top: 100px;
    font-family: "Noto Sans", sans-serif;
    @include max(1600) {
      font-size: 16px;
    }
  }
  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 10px;
    display: none;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: $white;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $text-color-alt;
    border-radius: 10px;
  }
  &.modal-shownn,
  &.modal-shown{
    overflow: hidden;
    height: 100vh!important;
  }
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
}
.active {
  color: #243dcb !important;
}
body {
  font-family: $font-family;
  font-weight: $font-regular;
  background-color: $body-background;
  color: $text-color;
}

.home-page {
  @include min(1280) {
    padding-top: 14px;
  }
}

.btn {
  min-width: 176px;
  font-size: size(18);
  padding: size(15) size(35);
  border-radius: size(6);
  background-color: $btn-primary;
  border: 1px solid $btn-primary;
  &:not(.dropdown-toggle) {
    @include max(575.98) {
      max-width: 250px;
      width: 100%;
      display: block;
      margin: 0 auto;
    }
  }

  &[disabled] {
    background-color: $btn-disabled;
    border: 1px solid $btn-disabled;
    color: $white;
    cursor: not-allowed !important;
  }
  &:focus {
    outline: none !important;
    box-shadow: none !important;
    color: inherit;
    background-color: inherit;
  }
  &-primary {
    background-color: $btn-primary;
    border-color: $btn-primary;

    color: $white;
    &:focus {
      background-color: $btn-primary;
      border: 1px solid $btn-primary;
      color: $white;
    }
    &:hover {
      background-color: $btn-primary-hover;
      border-color: $btn-primary-hover;
    }
  }
  &-secondary {
    background-color: $btn-secondary;
    border-color: $btn-secondary;
    color: $white;
    &:focus {
      background-color: $btn-secondary;
      border: 1px solid $btn-secondary;
      color: $white;
    }
    &:hover {
      background-color: $btn-secondary-hover;
      border-color: $btn-secondary-hover;
    }
  }
  &-tertiary {
    background-color: $btn-tertiary;
    border-color: $btn-tertiary;
    color: $white;
    &:focus {
      background-color: $btn-tertiary;
      border: 1px solid $btn-tertiary;
    }
    &:hover {
      color: $white;
      background-color: $btn-tertiary-hover;
      border-color: $btn-tertiary-hover;
    }
  }
  &-white {
    background-color: $white;
    border-color: $white;

    color: $text-alt;
    &:focus {
      background-color: $white;
      border-color: $white;
    }
    &:hover {
      color: $white;
      background-color: $btn-primary-hover;
      border-color: $btn-primary-hover;
    }
  }
  &-border {
    background-color: $white;
    border-color: $black;

    color: $black;
    &:focus {
      background-color: $white;
      border-color: $black;
      color: $black;
    }
    &:hover {
      color: $white;
      background-color: $black;
      border-color: $black;
    }
    &-primary {
      background-color: $white;
      border-color: $primary-color;
      color: $primary-color;
      &:focus {
        background-color: $white;
        border-color: $primary-color;
        color: $primary-color;
      }
      &:hover {
        color: $white;
        background-color: $primary-color;
        border-color: $primary-color;
      }
    }
    &-secondary {
      background-color: transparent;
      border-color: $btn-secondary;
      color: $btn-secondary;
      &:focus {
        background-color: transparent;
        border-color: $btn-secondary;
        color: $btn-secondary;
      }
      &:hover {
        color: $white;
        background-color: $btn-secondary;
        border-color: $secondary-color;
      }
    }
    &-grey {
      background-color: $white;
      border-color: $border-color;
      &:hover {
        color: $white;
        background-color: $black;
        border-color: $black;
      }
    }
  }
  &-outline {
    &-grey {
      background-color: transparent;
      border-color: $button-grey;
      color: $button-grey;
    }
    &-black {
      background-color: transparent;
      border-color: $black;
      color: $black;
      &:hover {
        color: $white;
        background-color: $black;
        border-color: $black;
      }
    }
  }
  &-red {
    background-color: $delete-red;
    border: 1px solid $delete-red;
    color: $white;
    &:hover {
      color: $white;
      background-color: $orange-red-alt;
      border-color: $orange-red-alt;
    }
    &:focus {
      color: $delete-red;
      &:hover {
        color: $white;
      }
    }
  }
  &-green {
    background-color: $list-bullet-tick;
    border-color: $list-bullet-tick;
    color: $white;
    &:hover {
      background-color: $green-alt;
      border-color: $green-alt;
      color: $white;
    }
  }
  &-link {
    color: $text-color-alt;
    font-size: size(20);
    font-weight: $font-medium;
    line-height: 24px;
    &.blue {
      color: $btn-secondary;
    }
    &.green {
      color: $list-bullet-tick;
    }
    &:hover {
      color: $primary-color;
      text-decoration: underline;
    }
  }
  &-curved {
    border-radius: 23px;
  }
  &-no-border-white {
    border-color: #fff;
    background-color: #fff;
    padding: 13px 27px;
    min-width: inherit;
    box-shadow: 0 3px 5px rgba(#bfc4ce, 0.36);
  }
}

.dark {
  .btn {
    &-border-primary {
      background-color: $primary-color;
      border-color: $primary-color;
      color: $white !important;
      &:hover {
        background-color: transparent;
        border-color: $white !important;
      }
    }
  }
}

/* The switch - the box around the slider */
.toggle-switch {
  font-size: 17px;
  position: relative;
  display: inline-block;
  width: 3.5em;
  height: 2em;
  input {
    opacity: 0;
    width: 0;
    height: 0;
    &:checked {
      + .slider {
        background-color: $green;
        &:before {
          transform: translateY(-50%) translateX(1.5em);
        }
      }
    }
    &:focus {
      + .slider {
        box-shadow: 0 0 1px $green;
      }
    }
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $toggle-grey;
    transition: 0.4s;
    border-radius: 30px;
    &::before {
      position: absolute;
      content: "";
      height: 28px;
      width: 28px;
      border-radius: 50%;
      left: 3px;
      top: 50%;
      transform: translateY(-50%);
      background-color: white;
      transition: 0.4s;
    }
  }
}
.custom-checkbox {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-size: 20px;
  user-select: none;
  input {
    margin-right: 12px;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked {
      + .checkmark {
        background-color: transparent;
        border-color: $green;
        border-radius: 50%;
        transition: 0.15s;
        &::after {
          display: block;
        }
      }
      + span {
        color: $black;
      }
    }
  }
  span {
    padding-left: 12px;
    color: $border-color;
  }
  .checkmark {
    flex: 0 0 20;
    position: relative;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: $white;
    border: 1px solid $border-color;
    border-radius: 50%;
    transition: 0.15s;
    &::after {
      content: "";
      position: absolute;
      display: none;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 12px;
      width: 12px;
      background-color: $green;
      border-radius: 50%;
    }
  }
}

.otp-field {
  display: flex;
  input {
    width: 50px !important;
    height: 50px;
    border-radius: 10px;
    text-align: center;
    // border: 1px solid $border-color;
    background-color: var(--input-bg);
    caret-color: var(--text-color-alt);
    border: 1px solid var(--otp-border);
    color: var(--text-color-alt);
    &:not(:last-child) {
      margin-right: 17px;
    }
    &:focus {
      border: 1px solid $text-color-alt;
    }
  }
  &:not(:last-child) {
    margin-right: 17px;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
input[type="checkbox"],
input[type="radio"] {
  position: absolute;
  visibility: hidden;
}

.form {
  &-control {
    border: 1px solid $border-color;
    background-color: $input-background;
    border-radius: 10px;
    padding: size(15) size(16);
    line-height: 1;
    font-size: size(16);
    outline: none !important;
    box-shadow: none !important;
    &:focus {
      border-color: $primary-color;
    }
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: rgba($black, 0.43);
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: rgba($black, 0.43);
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: rgba($black, 0.43);
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: rgba($black, 0.43);
    }
  }
  &-group {
    width: 100%;
    position: relative;
    margin-bottom: size(20);
    transition: 0.3s ease margin-bottom;
    & > label {
      color: $text-color;
      font-size: size(15);
      font-weight: $font-regular;
      // padding-bottom: size(12);
      padding-bottom: size(6);
    }
    & > small {
      font-size: size(13);
      position: absolute;
      bottom: -23px;
      left: 0;
    }
    .field_error_message {
      font-size: size(13);
      font-weight: $font-regular;
      line-height: 14px;
      color: $error-message;
      padding-top: 9px;
      display: none;
    }
    .error-msg {
      position: absolute;
      bottom: -23px;
      font-size: 13px !important;
    }
    &.error {
      margin-bottom: 30px;
      .form-control {
        border-color: $red;
      }
      & > small {
        color: $red;
      }
      .field_error_message {
        display: block;
      }
    }
    .input-holder {
      position: relative;
      .form-control {
        padding-right: 32px;
      }
      .eye {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 19px;
        background-color: transparent;
        border: none;
      }
    }
  }
}
:not(.btn_theme_toggle) {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.form-control {
  font-size: size(16);
  font-weight: $font-regular;
  background: $input-background;
  color: $text-color-alt;
  border: 1px solid $input-border;
  caret-color: $text-color-alt;
  &::placeholder,
  &::-webkit-input-placeholder {
    color: $text-color-alt;
    opacity: 0.43;
  }
  &:focus {
    color: $text-color-alt;
    background: $input-background;
    outline: none !important;
    box-shadow: none !important;
    border-color: $input-border-alt;
  }
}

input:-internal-autofill-selected,
.input:-webkit-autofill {
  transition: background-color 5000s ease-out 0s, -webkit-text-fill-color 5000s ease-out 0s, color 5000s ease-out 0s;
}

small.error-note {
  font-size: size(13);
  padding: 5px 6px;
  background-color: rgba(#ff8888, 0.13);
  display: inline-block;
  color: $red;
  border-radius: 7px;
  position: static;
  margin-top: 10px;
}

h1 {
  font-size: size(62);
  margin-bottom: 10px;
  @include max(767.98) {
    font-size: 43px;
  }
}
h2 {
  font-size: size(36);
  margin-bottom: 10px;
  @include max(767.98) {
    font-size: size(28);
  }
}
h3 {
  font-size: size(28);
  margin-bottom: 10px;
}
h4 {
  font-size: size(26);
  margin-bottom: 10px;
}
h5 {
  font-size: size(24);
  margin-bottom: 10px;
}
h6 {
  font-size: size(22);
  margin-bottom: 10px;
}

.container {
  @include min(1600) {
    max-width: 1390px !important;
  }
}

.section_title_block {
  color: $text-color;
  max-width: 530px;
  transition: 0.5s ease all;
  &.center {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    p {
      @include max(991.98) {
        max-width: 370px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  &.title_block_white {
    color: $white;
    transition: 0.5s ease all;
    h2 {
      letter-spacing: -0.45px;
      line-height: 1.3;
      margin-bottom: 18px;
    }
    p {
      letter-spacing: 0.18px;
    }
  }
  h2 {
    font-size: size(36);
    font-weight: $font-bold;
    line-height: 41px;
    margin-bottom: 12px;
    color: inherit;
    letter-spacing: -0.1px;
    text-transform: capitalize;
    @include max(991.98) {
      font-size: size(30);
      line-height: 35px;
    }
    @include max(767.98) {
      font-size: size(28);
      line-height: 38px;
    }
    @include max(374.98) {
      font-size: size(24);
      line-height: 32px;
    }
  }
  p {
    font-size: size(18);
    font-weight: $font-regular;
    line-height: 1.55;
    margin-bottom: 0;
    color: inherit;
    @include max(991.98) {
      font-size: size(16);
      line-height: 26px;
    }
    @include max(767.98) {
      font-size: size(15);
      line-height: 26px;
    }
    @include max(374.98) {
      font-size: size(16);
      line-height: 24px;
    }
  }
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -10px; /* gutter size offset */
  margin-right: -10px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  background-clip: padding-box;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield !important;
}

select {
  -webkit-appearance: none !important;
}

.modal {
  scrollbar-width: none;
  z-index: 999999;
  @include max(575.98) {
    padding-left: 0 !important;
  }
  &.fade {
    &:not(.show) {
      visibility: hidden;
    }
  }
  &-backdrop {
    &.fade {
      &:not(.show) {
        visibility: hidden;
      }
    }
  }
  &::-webkit-scrollbar {
    display: none;
  }
  .modal-body {
    padding: 0;
  }
  .modal-dialog {
    max-width: 522px;
    .modal-header {
      border-bottom: none;
      padding: 0;
      height: 0;
      .btn-close {
        position: absolute;
        top: 19px;
        right: 19px;
        z-index: 9;
        cursor: pointer;
        font-size: 18px;
        color: $text-color-alt;
        opacity: 1;
        @include max(575.98) {
          font-size: size(14);
        }
        &:hover {
          opacity: 0.5;
        }
      }
    }
  }
  &-fullscreen {
    @include max(575.98) {
      .modal-dialog {
        max-width: 100%;
        margin: 0;
      }
      .modal-content {
        position: relative;
        border-radius: 10px;
        overflow: hidden;
        background-color: $modal-bg;
        transition: all 0.3s;
        @include max(575.98) {
          border-radius: 0;
          min-height: 100vh;
        }
      }
      .modal-body {
        padding: 0;
        @include max(575.98) {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  &-backdrop {
    z-index: 9999;
  }
}

.dark {
  .modal {
    .btn-close {
      filter: invert(1);
    }
  }
  .btn-border {
    &:hover {
      border: 1px solid #fff;
      background-color: transparent;
    }
  }
}

.modal-body {
  .btn-secondary {
    @include max(575.98) {
      max-width: 100%;
    }
  }
}

img {
  color: $text-color-alt;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  // -webkit-text-fill-color: $text-color-alt !important;
  // background-color: $input-background !important;
  // -webkit-box-shadow: 0 0 0 50px $input-background inset !important;
  transition: background-color 500000s ease-in-out 0s, color 500000s ease-in-out 0s;
}

.modal-width {
  .modal-dialog {
    @include max(575.98) {
      max-width: inherit;
      margin: 0 auto;
      padding: 15px;
    }
    .modal-content {
      @include max(575.98) {
        border-radius: 10px;
        min-height: inherit;
      }
      .modal-body {
        @include max(575.98) {
          display: block;
          .btn {
            padding: 15px 35px;
            min-width: 130px;
          }
        }
      }
    }
  }
}

// animations
.anim {
  &.load-anim {
    opacity: 0;
    -webkit-transform: matrix(1, 0, 0, 1, 0, 30);
    transform: matrix(1, 0, 0, 1, 0, 30);
  }
  &.visible {
    &.load-anim {
      animation: loadAnim 0.5s cubic-bezier(0.5, 0, 0, 1) 1 forwards;
    }
    &.banner-anim {
      animation: bannerAnim 0.5s cubic-bezier(0.5, 0, 0, 1) 1 forwards;
    }
    &.header-anim {
      animation: headerAnim 0.5s cubic-bezier(0.5, 0, 0, 1) 1 forwards;
    }
    &.dark-toggler-anim {
      @include min(992) {
        animation: darkAnim 1.5s ease-out 1 forwards;
      }
      @include max(991.98) {
        animation: darkAnimMob 0.8s ease-out 1 forwards;
      }
    }
    &.anim_element {
      &:nth-child(1) {
        img {
          animation: scaleInOut 1s ease-in 3 alternate;
        }
      }
      &:nth-child(2) {
        img {
          animation: scaleInOut 1s ease-in 3 alternate;
        }
      }
      &:nth-child(3) {
        img {
          animation: scaleInOut 1s ease-in 3 alternate;
        }
      }
      &:nth-child(4) {
        img {
          animation: scaleInOut 1s ease-in 3 alternate;
        }
      }
      &:nth-child(5) {
        img {
          animation: scaleInOut 1s ease-in 3 alternate;
        }
      }
    }
  }
}

@keyframes loadAnim {
  0% {
    opacity: 0;
    -webkit-transform: matrix(1, 0, 0, 1, 0, 30);
    transform: matrix(1, 0, 0, 1, 0, 30);
  }
  100% {
    opacity: 1;
    -webkit-transform: matrix(1, 0, 0, 1, 0, 0);
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
}

@keyframes headerAnim {
  0% {
    opacity: 0;
    -webkit-transform: matrix(1, 0, 0, 1, 0, 10);
    transform: matrix(1, 0, 0, 1, 0, 10);
  }
  100% {
    -webkit-transform: matrix(1, 0, 0, 1, 0, 0);
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
}
@keyframes bannerAnim {
  0% {
    opacity: 0;
    -webkit-transform: matrix(1, 0, 0, 1, -60, 0);
    transform: matrix(1, 0, 0, 1, -60, 0);
  }
  100% {
    -webkit-transform: matrix(1, 0, 0, 1, 0, 0);
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
}

@keyframes darkAnim {
  0% {
    transform: translate(126%, -50%) rotate(270deg);
  }
  100% {
    transform: translate(26%, -50%) rotate(270deg);
  }
}
@keyframes darkAnimMob {
  0% {
    transform: translateY(-50%) translateX(100%);
  }
  100% {
    transform: translateY(-50%) translateX(0);
  }
}

@keyframes scaleInOut {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.btn-loader-active {
  color: transparent;
  &::after {
    content: "";
    animation: dots 2s steps(5, end) infinite;
    font-size: 12px;
    line-height: 1px;
    vertical-align: top;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    margin: auto;
    text-align: left;
    width: 8px;
    height: 8px;
    border-radius: 10px;
    background-color: currentColor;
  }
}
@keyframes dots {
  0% {
    color: rgba(0, 0, 0, 0);
    box-shadow: -1em 0 0 white, 1em 0 0 rgba(0, 0, 0, 0);
  }
  20% {
    color: rgba(0, 0, 0, 0);
    box-shadow: -1em 0 0 white, 1em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: rgba(white, 0.5);
    box-shadow: -1em 0 0 rgba(white, 0.5), 1em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    color: white;
    box-shadow: -1em 0 0 rgba(0, 0, 0, 0), 1em 0 0 rgba(white, 0.5);
  }

  80%,
  100% {
    color: rgba(white, 0.5);
    box-shadow: -1em 0 0 rgba(0, 0, 0, 0), 1em 0 0 white;
  }
}

.inner-page {
  min-height: calc(100vh - 520.73px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0;
  @include max(1279.98) {
    min-height: calc(100vh - 670.73px);
  }
  .policies-title {
    font-size: size(48);
    font-weight: $font-bold;
    line-height: 1;
    margin-bottom: 30px;
    color: $text-color-alt;
  }
  .policies-date {
    font-size: size(18);
    font-weight: $font-regular;
    font-style: italic;
    line-height: 1;
    margin-bottom: 50px;
    color: $text-color-alt;
  }
}

.admin-content-area {
  /* =====     Unordered List Items     ===== */
  font-family: $font-family;
  color: $text-color-alt;
  h1 {
    color: $text-color-alt;
    font-size: size(28);
  }

  h2 {
    color: $text-color-alt;
    font-size: size(24);
    @include max(576) {
      font-size: size(26);
    }
  }
  h3 {
    color: $text-color-alt;
    font-size: size(26);
    @include max(576) {
      font-size: size(24);
    }
  }
  h4 {
    color: $text-color-alt;
    font-size: size(24);
    @include max(576) {
      font-size: size(20);
    }
  }
  h5 {
    color: $text-color-alt;
    font-size: size(20);
    @include max(576) {
      font-size: size(18);
    }
  }
  ul,
  ol,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  a {
    color: $text-color-alt;
    font-weight: 400;
    margin-bottom: 14px;
    &.a {
      text-decoration: underline !important;
    }
  }
  p {
    font-family: $font-family;
    font-size: 18px;
    font-weight: 400;
    color: $text-color-alt;
    line-height: 32px;
    &.p {
      font-family: $font-family;
      font-size: 16px;
      font-weight: 400;
      color: $text-color-alt;
      line-height: 30px;
    }
    @include max(575.98) {
      font-size: 16px !important;
      line-height: 28px !important;
    }
  }
  & + h1,
  & + h2,
  & + h3,
  & + h4,
  & + h5,
  & + h6,
  & + ul,
  & + ol {
    margin-top: 24px;
    margin-bottom: 0;
  }

  ul {
    display: block;
    margin: 24px 0 10px;
    padding-left: 20px;
    li {
      display: block;
      padding-left: 35px;
      font-family: $font-family;
      font-size: 18px;
      font-weight: 400;
      color: $text-color-alt;
      @include max(576) {
        font-size: 16px;
      }
      position: relative;
      & + li {
        margin-top: 10px;
      }
      &:before {
        background-color: $modal-link;
        border: none;
        border-radius: 2px 0;
        content: "";
        display: block;
        height: 12px;
        left: 0;
        position: absolute;
        top: 10px;
        width: 7px;
        transform: skewX(-35deg);
      }
      @include max(1199) {
        & + li {
          margin-top: 10px;
        }
        &:before {
          height: 12px;
          width: 8px;
        }
      }
      @include max(767) {
        &:before {
          height: 12px;
          width: 7px;
        }
      }
    }
    &.white {
      li {
        &:before {
          border-color: $white;
        }
      }
    }

    &.fpa-ul {
      padding: 0px;
      margin: 0px;
      padding-top: 15px;
      li {
        position: relative;
        list-style: none;
        padding-bottom: 30px;
        padding-left: 35px;
        font-family: $font-family;
        font-size: 18px;
        font-weight: 500;
        color: $text-color-alt;
        margin: 0px;
        @include max(576) {
          font-size: 16px;
        }
        &::before {
          content: "";
          position: absolute;
          padding-right: 23px;
          background-color: transparent;
          left: 0;
          top: 0;
        }
      }
    }
  }
  /* =====     Ordered List Items     ===== */
  ol {
    margin: 24px 0 10px;
    counter-reset: item;
    padding-left: 20px;
    li {
      display: block;
      font-size: 18px;
      line-height: 1.4;
      padding-left: 30px;
      position: relative;
      & + li {
        margin-top: 15px;
      }
      &:before {
        content: counters(item, ".") ".";
        counter-increment: item;
        display: inline-block;
        left: 0;
        position: absolute;
      }
    }
    &.lower-alpha {
      li {
        &:before {
          content: counters(item, "", lower-alpha) ".";
        }
      }
    }
    &.lower-roman {
      li {
        &:before {
          content: counters(item, "", lower-roman) ")";
        }
      }
    }
    &.roman-sub {
      li {
        padding-left: 30px;
        &:before {
          content: counters(item, ".", upper-roman) ".";
          counter-increment: item;
          font-weight: 500;
        }
        ol {
          li {
            padding-left: 32px;
          }
        }
      }
    }
    &.decimal-sub {
      li {
        padding-left: 30px;
        &:before {
          content: counters(item, ". ", decimal) ".";
          counter-increment: item;
          font-weight: 500;
        }
        ol {
          padding-top: 5px;
          li {
            padding-left: 32px;
          }
          ol {
            li {
              padding-left: 50px;
            }
          }
        }
      }
    }
  }
  ul,
  ol {
    & + h2,
    & + h3,
    & + h4,
    & + h5,
    & + h6 {
      margin-top: 30px;
    }
    & + p {
      margin-top: 20px !important;
    }
  }
  dl {
    dt {
      font-size: size(24);
      font-weight: $font-semibold;
      margin-bottom: 10px;
      position: relative;
      padding-left: 40px;
      color: $text-color-alt;
      .counter-span {
        &::before {
          counter-increment: count;
          content: counter(count) ".";
          position: absolute;
          top: 2px;
          left: 0;
          font-size: 22px;
          font-weight: 600;
        }
      }
    }
    dd {
      font-size: size(18);
      font-weight: $font-regular;
      margin-bottom: 20px;
      color: $text-color-alt;
    }
  }
  ul,
  ol,
  dl {
    &.default {
      list-style: none;
      padding-left: 0;
    }
  }
}

.lock-scroll-home,
.lock-scroll-home-modal {
  overflow: hidden;
  height: calc(100vh - 52px);
}
.lock-scroll-footer,
.lock-scroll-footer-modal {
  overflow: hidden;
  height: 0;
}

.modal {
  &.fade {
    @include max(575.98) {
      transition: none;
    }
  }
}

.show > .btn-primary.dropdown-toggle {
  color: var(--text-color);
  background-color: inherit;
  border-color: inherit;
}

.dropdown-lg {
  .btn-primary {
    &:active,
    &:focus,
    &:active:focus {
      background-color: transparent !important;
      border-color: transparent !important;
    }
  }
  .dropdown-menu {
    min-width: 312px;
    width: 100%;
    border-radius: 13px;
    box-shadow: 0 3px 3px #00000015;
    .scroll-dropdown {
      max-height: 268px;
      overflow: auto;
      scrollbar-width: thin;
      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        background-color: #e4e4e443;
      }

      &::-webkit-scrollbar-thumb {
        background: #d2d2d2;
        border-radius: 25px;
      }
    }
  }
}
.dropdown_title {
  font-size: size(18);
  font-weight: $font-medium;
  padding-bottom: 13px;
  margin-bottom: 10px;
  border-bottom: 1px solid #bfbfc3;
}

.modal-content {
  background-color: $modal-bg;
}

.dropdown-toggle {
  &.btn-primary {
    &:hover {
      background-color: transparent;
      border-color: transparent;
    }
  }
}

:not(.btn-check) + .btn.btn-border:hover,
.btn.btn-border:first-child:hover {
  color: $white;
  background-color: $black;
  border-color: $black;
}
:not(.btn-check) + .btn.btn-border-primary:hover,
.btn.btn-border-primary:first-child:hover {
  color: $white;
  background-color: $primary-color;
  border-color: $primary-color;
}
:not(.btn-check) + .btn.btn-border-secondary:hover,
.btn.btn-border-secondary:first-child:hover {
  color: $white;
  background-color: $btn-secondary;
  border-color: $btn-secondary;
}
:not(.btn-check) + .btn.btn-outline-black:hover,
.btn.btn-outline-black:first-child:hover {
  color: $white;
  background-color: $black;
  border-color: $black;
}
:not(.btn-check) + .btn.btn-green:hover,
.btn.btn-green:first-child:hover {
  color: $green;
  background-color: transparent;
  border-color: $green;
}
:not(.btn-check) + .btn.btn-outline-grey:hover,
.btn.btn-outline-grey:first-child:hover {
  color: $black;
  background-color: transparent;
  border-color: $black;
}
:not(.btn-check) + .btn.btn-red:hover,
.btn.btn-red:first-child:hover {
  color: $red;
  background-color: transparent;
  border-color: $red;
}
:not(.btn-check) + .btn.btn-tertiary:hover,
.btn.btn-tertiary:first-child:hover {
  color: $white;
  background-color: $primary-color;
  border-color: $primary-color;
}
:not(.btn-check) + .btn.btn-white:hover,
.btn.btn-white:first-child:hover {
  color: $white;
  background-color: $btn-secondary;
  border-color: $btn-secondary;
}

.card-item {
  filter: drop-shadow(1px 1px 10px rgba(0, 0, 0, 0.218));
  transition: 0.5s all ease;
  transform: translateX(4px);

  @include min(576){
      .text {
        transition: 0.5s all ease;
        opacity: 0;
        transform: translateY(-10px);
    }
  }
  &:hover {
    filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.218));
    .text {
      opacity: 1;
      transform: translateY(-3.5px);
    }
  }
}

.filter-wrap {
  max-width: 100%;
  overflow-x: hidden;
  height: calc(100vh - 85px) !important;
  padding-bottom: 8px;
  
}

// .FIE_tools-bar-wrapper {
//   overflow-x: auto;
// }

.no-interaction {
  pointer-events: none !important;
}

.customRightClick {
  z-index: 25;
  ul {
    margin-bottom: 0;
    li {
      border-radius: 6px;
      padding: 7px 10px;
    }
  }
}
.show{
  >.btn-primary{
    .dropdown-toggle{
      color: var(--text-color)!important;
    }
  }
}
button{
  transition: all 0.3s ease-in-out;
}


#base_up{
  overflow: hidden;
  &::after{
    content: 'a';
  }
}
.disabled{
  pointer-events: none;
}
.top-1{
  top: -1px!important;
}
.FIE_carousel-prev-button,
.FIE_carousel-next-button{
  svg{
    height: 23px;
    width: 23px;
  }
}
.search_results_wrap{
  transition: width 0.3s ease-in-out;
}
.dashboard_sidebar_collapse{
  @include min(1440){
    .search_results_wrap{
      width: calc(100vw - 100px);
    }
  }
}
#tooltip-bottom{
  z-index: 1000000;
  
  .tooltip-inner{
    background-color: white;
    padding: 8px 25px 8px 15px;
    span{
      font-size: 12px;
      display: block;
      text-align: left;
      padding: 2px 0;
      color: black;
    }
  }
  .tooltip-arrow{
    &:before{
      border-bottom-color: white;
    }
  }
}

.Toastify__toast-theme--bgsecondary{
  background-color: #1D5EFF;
  font-size: 14px;
  min-height: 40px!important;
  .Toastify__toast-body{
    margin-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    padding-top: 0px;
  }
  .Toastify__close-button{
    background-color: rgba(255,255,255,0.1);
    height: 20px;
    width: 20px;
    border-radius: 30px;
    margin-top: 2px;
    svg{
      margin-top: -5px;
      margin-left: 2px;
      height: 13px;
    }
  }
  @include min(1200){
    right: 573px;
    top: 90px;
    width: 360px;
  }
}