.tellustabs {
  margin-left: 440px;
  margin-bottom: 20px;
  margin-top: 30px;
  align-items: center;
  width: 522px;
  background-color: white;
  border-radius: 10px;
  ul {
    display: flex;
    justify-content: space-around;
    font-size: 21px;
    list-style-type: none;
    align-items: center;
    height: 76px;
    // li:hover{
    //   padding: 25px;
    //   background-color: aliceblue;
    // }
  }
}
