@import "../../../styles/uitilities.scss";

.FacesDrawer {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 475px;
    box-shadow: -2px 0px 8px rgba(7, 20, 84, 0.07);
    position: fixed;
    top: 83px;
    right: 0;
    z-index: 99;
    height: calc(100vh - 84px);
    padding: 0px 50px;
    background-color: $dashboard-bg-alt;
    justify-content: center;
    align-items: center;
    // animation: slideAnim 0.5s;
    padding-bottom: 0;
    transform: translateX(0);
    // transition: 0.5s ease-in-out all;
    @include max(1279.98) {
        top: 0;
        padding-top: 62px;
        padding-bottom: 61px;
        height: 100vh;
    }
    @include max(1199.98) {
        padding-top: 52px;
    }
    @include max(575.98) {
        max-width: 100%;
        padding-left: 16px;
        padding-right: 16px;
    }
    &__wrapper {
        width: 100%;
        overflow-y: auto;
        padding-top: 41px;
        padding-bottom: 30px;
        height: 100%;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    &__closeWrapper {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        right: 40px;
        font-size: 0px;
        border-radius: 50%;
        z-index: 999;
        overflow: hidden;
        position: absolute;
        top: 32px;
        right: 20px;
        @include max(1279.98) {
            top: 85px;
        }
        button {
            font-size: 0px;
            border: none;
            padding: 0px;
            margin: 0px;
            width: 30px;
            height: 30px;
            background-color: transparent;
            cursor: pointer;
            transition: 0.3s background-color ease-in-out;
            &::after {
                position: absolute;
                width: 16px;
                height: 2px;
                background-color: $text-color-alt;
                content: "";
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%) rotate(45deg);
                transition: 0.3s background-color ease-in-out;
            }
            &::before {
                position: absolute;
                width: 16px;
                height: 2px;
                background-color: $text-color-alt;
                content: "";
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%) rotate(-45deg);
                transition: 0.3s background-color ease-in-out;
            }
            &:hover {
                opacity: 0.5;
            }
        }
    }
    &__title {
        padding-bottom: 16px;
        margin-bottom: 23px;
        border-bottom: 1px solid $border-color;
        font-size: size(24);
        font-weight: $font-medium;
        color: $text-color-alt;
    }
    &__gallery {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -11px;
        &ImageWrap {
            padding: 0 11px;
            flex: 0 0 33.3333%;
            max-width: 33.3333%;
        }
        &Image {
            position: relative;
            overflow: hidden;
            border-radius: 6px;
            overflow: hidden;
            margin-bottom: 22px;
            cursor: grab;
            &.grabbing {
                cursor: grabbing;
            }
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: block;
                object-fit: cover;
                max-width: 100%;
                max-height: 100%;
            }
            &::after {
                content: "";
                display: block;
                height: 0;
                width: 100%;
                padding-bottom: calc(122 / 110 * 100%);
            }
        }
    }
}

// @keyframes slideAnim {
//     0% {
//         transform: translateX(100px);
//         opacity: 0;
//     }
//     100% {
//         transform: translateX(0);
//         opacity: 1;
//     }
// }

:global {
    .sideDrawer-enter {
        transform: translateX(100%);
    }
    .sideDrawer-enter-active {
        transform: translateX(0);
        transition: 0.5s ease-in-out transform;
    }
    .sideDrawer-exit {
        transform: translateX(0);
    }
    .sideDrawer-exit-active {
        transform: translateX(100%);
        transition: 0.5s ease-in-out transform;
    }
}
