@import "../../styles/uitilities.scss";

.keyboard_shortcuts {
    &_list {
        margin-bottom: 0;
        padding-left: 0;
        list-style: none;
        li {
            font-size: size(16);
            font-weight: $font-regular;
            margin-bottom: 8px;
            color: $text-color-alt;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 35px;
        }
    }
    &_description {
        font-size: size(16);
        font-weight: $font-regular;
        margin-bottom: 0;
        color: $text-color-alt;
    }
    &_keys {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        li {
            margin-bottom: 0;
            justify-content: flex-end;
            &:not(:first-child) {
                margin-left: 2px;
            }
            &:not(:last-child) {
                margin-right: 2px;
            }
        }
        .key {
            min-width: 25px;
            height: 25px;
            padding: 0 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid $input-border;
            background-color: $input-border;
            color: $text-color-alt;
            border-radius: 6px;
            font-size: size(14);
            font-weight: $font-regular;
            svg {
                path {
                    fill: $text-color-alt;
                }
            }
        }
    }
}
