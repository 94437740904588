@import '../../styles/uitilities.scss';

.get_started_choose_plan {
  background: $dark-bg-alt-2;
  padding: 60px 0 0px;
  min-height: calc(100vh - 76.3px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include max(1199.98) {
    min-height: calc(100vh - 52px);
  }
  @include max(767.98) {
    padding: 38px 0 0px;
  }
  .btn_wrap {
    text-align: center;
  }
  :global(.section_title_block) {
    @include max(767.98) {
      margin-bottom: 24px;
    }
    h2 {
      margin-bottom: 22px;
      @include max(767.98) {
        margin-bottom: 18px;
        line-height: 26px;
      }
    }
  }
  .anim_elements {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    .anim_elem {
      position: absolute;
      width: max-content;
      height: max-content;
      margin-bottom: 0;
      @include max(991.98) {
          display: none;
      }
      &.circle_green {
          top: 25%;
          transform: translateX(calc(-71.5vw / 2));
      }
      &.donut_grey {
          bottom: 13.8%;
          transform: translateX(calc(-62vw / 2));
          opacity: 0.15;
          filter: brightness(0.7);
      }
      &.donut_yellow {
          bottom: 23.2%;
          transform: translateX(calc(77.5vw / 2));
          @include max(1599.98) {
              transform: translateX(calc(88vw / 2));
          }
      }
    }
  }
  :global(.swiper) {
    @include max(767.98) {
      padding: 20px 17px 55px !important;
    }
  }
}

