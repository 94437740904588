@import "../../../../styles/uitilities.scss";

.create_slideshow {
  &_modal {
    :global(.modal-dialog) {
      max-width: 387px;
    }
    &_content {
      padding: 40px 38px 35px;
    }

    &_title {
      font-size: size(24);
      font-weight: $font-medium;
      color: $text-color-alt;
      margin-bottom: 22px;
      text-align: center;
    }
    :global(.form-group) {
      margin-bottom: 22px;
    }
    .slideshow_name {
      &_label {
        font-size: size(14);
        font-weight: $font-regular;
        color: $text-color-alt;
        margin-bottom: 6px;
        padding-bottom: 0;
      }
      &_input {
        font-size: size(14);
        font-weight: $font-regular;
        color: $text-color-alt;
        opacity: 0.52;
      }
    }
    &_actions {
      display: flex;
      justify-content: center;
      align-items: center;
      :global(.btn) {
        // max-width: 149px;
        width: 100%;
        flex: 0 0 47.5%;
        max-width: 47.5%;
        min-width: inherit;
        &:first-child {
          margin-right: 14px;
        }
        &:global(.btn-border-grey) {
          &:hover {
            border-color: #000;
            background-color: #000;
            color: #fff;
          }
        }
      }
    }
  }
}
.type {
  padding: 0px !important;
}
