
@import '../../styles/uitilities.scss';
.myinvitations {
  display: flex;
  align-items: center;
  border-bottom: inset;
  padding-top: 20px;
  padding-bottom: 20px;
  max-width: 1175px;
  border-bottom-width: 1px;
  border-color: $dashboard-item-border;
  &_p{
    font-size: size(18);
  }
  &_user_name{
    font-weight: 500;
  }
  &_time{
    color: $text-color;
    opacity: 0.7;
    
    @include min(768){
        font-size: size(14);
        padding: 4px 0;
    }
    @include max(767.98){
        font-size: size(12);
        padding: 1px 0;
    }
  }
  
  
  .myinvitations_figure {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    @include max(767.98){
      width: 45px;
      height: 45px;
    }
    img {
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
  }

  .myinvitations_buttoncontainer {
    display: flex;
    justify-content: end;
    width: 45%;
    margin-left: auto;
    .myinvitations_buttonaccept {
      padding-left: 25px;

      button{
        width: 100px;
        color: $body-background;
        border-radius: 5px;
        padding: 5px;
        min-width: 135px;
        height: 45px;
        color: white;
        font-weight: 400;
        &:focus:not(:hover){
          background-color: $green;
          border-color: $green;
          color: #fff;
        }
      }
    }
    .myinvitations_buttoncancel {
      padding-left: 25px;
      
      button{
        background-color: $dashboard-header-bg;
        width: 100px;
        min-width: 135px;
        height: 45px;
        font-weight: 400;
        border-radius: 5px;
        border-color: $dashboard-btn-gray;
        color: $dashboard-btn-gray;
        padding: 5px;

        &:focus:not(:hover){
          color: $dashboard-btn-gray;
        }
        &:hover{
          // color: $dashboard-btn-gray-hover;
          // border-color: $dashboard-btn-gray-hover;
          color: $label-color;
          border-color: $label-color;
        }

        


      }
      
    }
  }

  @include max(767.98){
    border-bottom: none;
    padding-top: 16px;
    padding-bottom: 16px;
    &_p{
      font-size: size(14);
    }
  }
}
.myinvitations_text{
  padding-left: 16px;
}
.myinvitations_buttoncontainer_mob{
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  padding-left: 15px;
  .myinvitations_buttoncancel,
  .myinvitations_buttonaccept{
    padding: 0 4px;
    button{
      width: 31px;
      height: 31px;
      min-width: unset;
      border-radius: 100%;
      padding: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .myinvitations_buttoncancel{
    button{
      background-color: $dashboard-btn-gray2;
      border: none;
      svg{
        path{
          fill: $dashboard-btn-gray2-text;
        }
      }
    }
  }

  .myinvitations_buttonaccept{
    button{
      svg{
        margin-top: 1px;
      }
    }
  }


}

