@import "../../styles/uitilities.scss";

.share_modal {
  :global(.modal-dialog) {
    max-width: 411px;
  }
  :global{
    .dropdown-menu.show{
      background-color: white;
    }
  }
  :global(.modal-content) {
    padding: 25px;
  }
  &_title {
    font-size: size(24);
    font-weight: $font-medium;
    color: $text-color;
    padding-bottom: 11px;
    margin-bottom: 22px;
    border-bottom: 1px solid #e0e2e8;
    
  }
  &_options {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    li {
      font-size: size(18);
      font-weight: $font-regular;
      color: $text-color;
      &:not(:last-child) {
        margin-bottom: 23px;
      }
      svg {
        margin-right: 21px;
      }
    }
    &_button {
      background-color: transparent;
      border: none;
      display: flex;
      align-items: center;
      &:hover {
        color: $modal-link;
      }
    }
  }
}

.clip_to_board {
    position: absolute;
    //   display: inline-block;
    //   bottom: -25px;
    //   color: $text-alt;
    //   left: 50%;
    //   transform: translateX(-50%);
    right: 10px;
    // bottom: 0px;
    font-size: size(12);
    // padding: 2px 3px;
    border: 1px solid rgba($green, 0.7);
    background-color: rgba($green, 0.3);
    border-radius: 4px;
    line-height: 0.8;
    bottom: -17px;
    padding: 1px 3px;
  }

.share_modal_option_duration{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  :global{
    .dropdown-toggle{
      padding-left: 15px;
      padding-right: 15px;
      min-width: 80px;
      padding-top: 11px;
      padding-bottom: 11px;
      font-size: 16px;
      font-weight: 400;
      color: $text-color;
      border: 1px solid $input-border;
      background-color: transparent;
      border-radius: 20px;
      &:after{
        border: none!important;
        width: 7px;
        height: 7px;
        border-bottom: 1.5px solid $text-color!important;
        border-left: 1.5px solid $text-color!important;
        transform: rotate(-45deg);
        margin-left: 8px;
      }
      &:hover{
        border: 1px solid $text-color;
      }
      &:active{
        color: $text-color;
      }
    }
    
  }
}
.Share_link_wrap{
  background-color: $tab-bg;
  padding: 10px 25px;
  border-radius: 10px;
  color: $text-color;
  margin-bottom: 18px!important;
  position: relative;
  a{
    color: currentColor;
  }
  svg{
    margin-right: 10px!important;
  }
  &_row{
    display: flex;
    width: 100%;
    white-space: nowrap;
    position: relative;
    svg{
      min-width: 16px;
      margin-top: auto;
      margin-bottom: auto;
    }
    
    :global(.btn){
      border-radius: 10px;
      font-size: 18px;
      padding: 14px;
      min-width: 60px;
      margin: -10px 0px -10px 0;
      position: relative;
      right: -25px;
      width: 100px;
    }
  }
  &_url{
    width: 100%;
    max-width: calc(100% - 100px);
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
  }
}
.Share_link{
  &_list{
    list-style: none;
    padding-left: 0px;
    display: flex;
    margin: 10px -5px 10px -5px;
    @include max(420){
      flex-wrap: wrap;
      li{
        margin: 3px 0;
      }
    }
    li{
      margin-bottom: 0px!important;
      width: 100%;
      padding: 0 5px;
      button{
        width: 100%;
        text-align: left;
        font-size: 14px;
        border: 1px solid $input-border;
        border-radius: 6px;
        height: 44px;
        padding: 10px 10px;
        display: flex;
        align-items: center;
        transition: background-color 0.3s ease-in-out;
        &:hover{
           background-color: var( --otp-border);
        }
      }
    }
  }
  &_iconwrap{
    height: 24px;
    width: 24px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 4px;
    svg{
      margin: auto!important;
    }
    &:global(.whatsapp){
      background-color: #00C84B;
    }
    &:global(.gmail){
      background-color: #E13E2F;
    }
  }
}

.alert_not_select_duration{
  position: absolute;
  width: 294px;
  font-size: 14px;
  font-weight: 400;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $primary-color;
  padding: 10px;
  border-radius: 10px;
  color: white;
  z-index: +2;
  margin-top: 9px;
  &:before{
    content: "";
    width: 10px;
    height: 10px;
    display: block;
    background-color: $primary-color;
    position: absolute;
    top: -5px;
    transform: rotate(45deg);
    right: 30px;
  }
  p{
    margin-bottom: 0px;
  }
  &_btn{
    border: none;
    width: 20px;
    height: 20px;
    // display: flex;
    background-color: #316BF8;
    color: white;
    padding: 0px;
    border-radius: 14px;
    
    svg{
      height: 7px;
      width: 7px;
      top: -2px;
      right: -1px;
      margin: 0 5px;
      position: relative;
    }
    &:hover{
      background-color: $secondary-color;
    }
  }
}
.shareLinks_notselect_Duration{
  >*{
    pointer-events: none!important;
  }
  :global{
    .btn.btn-primary{
      background-color: #D7D9DE;
      border-color: #D7D9DE;
      color: #9B9B9B;
    }
  }
}
