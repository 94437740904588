@import "../../styles/uitilities.scss";

.preference_inner_header {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    color: $text-color-alt;
    @include max(575.98) {
        border-bottom: 2px solid $border-color;
        margin-bottom: 20px;
    }
    .btn_back {
        font-size: size(30);
        background-color: transparent;
        border: none;
        svg {
            transition: 0.3s ease transform;
            will-change: transform;
            margin-top: -3px;
            @include max(575.98) {
                width: 14px !important;
                height: 14px !important;
                margin-bottom: 4px;
            }
            path {
                fill: $text-color-alt;
            }
        }
        &:hover {
            svg {
                transform: translateX(-5px);
            }
        }
    }
    &_title {
        font-size: size(30);
        font-weight: 500;
        padding-left: 18px;
        margin-bottom: 0;
        color: $text-color-alt;
        @include max(575.98) {
            font-size: size(18);
            padding-left: 5px;
        }
    }
}
