@import "../../../styles/uitilities.scss";

.AddressComponent {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  @include max(991.98) {
    flex-direction: column;
  }
  :global {
    .btn {
      @include max(575.98) {
        max-width: 100%;
      }
    }
  }

  // margin: 0 -15px;
  &__colLeft,
  &__colRight {
    padding: 80px;
    @include max(1199.98) {
      padding: 60px;
    }
    @include max(575.98) {
      padding: 30px;
    }
  }

  &__colLeft {
    flex: 0 0 50%;
    max-width: 50%;
    background-color: $dashboard-bg;
    @include max(991.98) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  &__colRight {
    justify-content: center;
    display: flex;
    flex-direction: column;
    flex: 0 0 50%;
    max-width: 50%;
    background-color: $checkout-modal-bg-alt;
    @include max(991.98) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  &_title {
    font-size: size(27);
    font-weight: $font-medium;
    margin-bottom: 30px;
    color: $text-color-alt;

    @include max(767.98) {
      font-size: size(22);
      text-align: left;
      margin-bottom: 15px;
    }
  }

  :global {
    .dropdown {
      button {
        height: 50px;
      }

      &-toggle {
        color: $text-color-alt;
        background-color: $input-background;
        border-color: $input-border !important;
        text-align: left;
        font-size: size(16);
        padding: 15px 16px !important;
        width: 100%;
        font-weight: $font-regular;

        &::after {
          position: absolute;
          top: 44%;
          right: 20px;
          width: 8px;
          height: 8px;
          border: 1px solid transparent;
          border-right: 1px solid $text-color-alt;
          border-bottom: 1px solid $text-color-alt;
          transform: translateY(-50%) rotate(45deg);
          margin-left: 0;
          transition: 0.5s ease all;
        }

        &::before {
          content: "";
          width: 1px;
          height: 75%;
          // background-color: $border-color;
          background-color: $text-color;
          position: absolute;
          top: 50%;
          right: 50px;
          transform: translateY(-50%);
          opacity: 0.2;
        }
      }

      &-menu {
        width: 100%;
        background-color: $white;
        border-radius: 10px;
        padding: 13px;
        position: relative;
        max-height: 250px;
        overflow: auto;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }
        a {
          font-size: size(17);
          font-weight: $font-regular;
          padding: 6px 10px;
          border-radius: 6px;
          overflow: hidden;
          transition: 0.5s ease all;

          &:hover,
          &:focus {
            color: $text-alt;
            background-color: $dropdown-bg-hover;
          }
        }
      }
    }

    .btn-wrap {
      text-align: right;
    }

    .date-input {
      position: relative;

      input {
        font-family: $font-family;
        font-size: size(16);
        font-weight: 400;
      }

      svg {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        pointer-events: none;

        path {
          fill: $text-color-alt;
        }
      }

      ::-webkit-calendar-picker-indicator {
        opacity: 0;
      }
    }
  }

  textarea {
    height: 130px;
    font-size: size(16);
    line-height: 1.2;
    color: $text-color-alt;
  }

  .error_message {
    color: $red;
    padding-top: 2px;
  }

  .CheckOutPoweredBy {
    font-size: size(14);
    font-weight: $font-regular;
    color: $text-color-alt;
    display: flex;
    align-items: center;
    padding: 45px 80px 0 30px;
    @include max(767.98) {
      padding: 30px 80px 0 30px;
    }
    img {
      margin-top: 1px;
      padding-left: 5px;
      height: 17px;
    }
  }
}
.Dropdown_select{
  :global{
    .select__control{
      background-color: $input-background;
      color: $text-color-alt;
      border: 1px solid $input-border;
      caret-color: $text-color-alt;
      height: 50px;
      font-size: 1rem;
      padding-left: 5px;
      border-radius: 10px;
      &:hover{
        border: 1px solid $input-border!important;
      }
      &:focus{
        border: 1px solid $text-color-alt!important;
      }
    }
    .select__single-value{
      color: $text-color-alt;
    }
    input{
      color: $text-color-alt!important;
      caret-color: $text-color-alt;
    }
    .select__indicator-separator{
      background-color: $text-color-alt;
      opacity: 0.2;
    }
    .select__menu{
      font-size: 1rem;
      ::-webkit-scrollbar {
        width: 3px;
      }
      ::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      ::-webkit-scrollbar-thumb {
        background: #888;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
  }
}

.logoWrap{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .logo1{
    filter: invert(var(--imageInvert));
  }
  .logo2{
    position: absolute;
    inset: 0;
  }
}