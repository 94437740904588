@import "../../../../../styles/uitilities.scss";

.file_upload {
  &_warning {
    max-width: 70px;
    img {
      width: 100%;
    }
  }
  &_modal {
    :global(.modal-dialog) {
      @include min(768) {
        max-width: 836px;
      }
    }

    :global(.modal-content) {
      padding: 48px 40px 40px;
    }
  }

  &_draggable_area {
    width: 100%;
    height: 356px;
    border-radius: 6px;
    border: 2px dashed #23b070;
    background: $file-upload-bg;
    margin-bottom: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    position: relative;
    padding: 20px;

    @include max(767.9) {
      text-align: center;
    }

    &.limit_exceded {
      border: 2px dashed #c21024c7;
      background-color: #b93a481a !important;
    }
  }

  &_image {
    margin-bottom: 23px;
    object-fit: cover;
  }

  &_input {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;

    &_label {
      color: #23cc2b;
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        filter: brightness(0.8);
      }
    }
  }

  &_info {
    font-size: size(20);
    font-weight: $font-medium;
    margin-bottom: 10px;
    color: $text-color-alt;

    &_desc {
      font-size: size(17);
      text-align: center;
      font-weight: $font-regular;
      line-height: 28px;
      color: $text-color-alt;
      margin-bottom: 0;
      width: 90%;
    }
  }

  &_preview {
    display: flex;
    justify-content: space-between;
    // align-items: center;

    :global(.swiper) {
      flex: 1;
    }

    :global(.swiper-slide) {
      width: 111.4px !important;
    }

    &_wrap {
      width: 100%;
      border-radius: 6px;
      overflow: hidden;
      padding-right: 10px;
      position: relative;

      // margin-right: 10px;
      @include min(576) {
        min-width: 114px;
      }

      @include max(575.9) {
        min-width: 100px;
      }

      &.blank_image {
        border: 1px dashed #bfc4ce7c;
      }

      svg {
        position: absolute;
        bottom: 7px;
        right: 15px;
        width: 20px;
        height: 20px;
        fill: transparent;
      }

      .success_indicator {
        width: 20px;
        height: 20px;
        position: absolute;
        bottom: 5px;
        right: 5px;
        background: #21c031;
        border-radius: 50%;

        .success_check {
          width: 12px;
          height: 6px;
          border: 1px solid transparent;
          border-bottom: 1px solid #fff;
          border-left: 1px solid #fff;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -70%) rotate(-45deg);
        }
      }
    }

    &_image {
      position: relative;
      margin-bottom: 0;
      background: $file-preview-bg;
      border-radius: 6px;
      overflow: hidden;
      height: 100%;
      margin-right: 0px !important;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        max-width: 100%;
        max-height: 100%;
        user-select: none;
      }

      &::after {
        content: "";
        display: block;
        // height: 0;
        // width: 100%;
        // padding-bottom: calc(83 / 112 * 100%);
        z-index: 100;
        // position: relative;

        position: relative;
        height: 100%;
        padding-left: 130%;
      }

      &.dupliaction::after {
        background-color: rgb(149 141 19 / 66%);
        box-shadow: inset 0px 0px 11px 5px #e73434;
      }

      &.uploadfail {
        &::after {
          box-shadow: inset 0px 0px 20px red;
          z-index: 1;
          pointer-events: none;
          position: relative;
        }
      }
    }

    .file_upload_input_2 {
      display: none;
    }

    .file_upload_status,
    .add_more_btn {
      flex: 0 0 112px;
      max-width: 112px;
      width: 100%;
      height: 100%;
      position: relative;
      margin-bottom: 0;
      border: 1px dashed #bfc4ce7c;
      border-radius: 6px;
      background: $file-preview-bg;
      margin-left: 17px;

      @include max(575.9) {
        max-width: 100px;
      }

      &_contents {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        max-width: 100%;
        max-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: size(15);
        font-weight: $font-regular;
        color: $text-color-alt;

        svg {
          margin-bottom: 8px;

          path {
            fill: $text-color-alt;
          }
        }

        span {
          line-height: 1;
        }

        @include max(575.98) {
          svg {
            display: block;
          }
        }
      }

      &::after {
        content: "";
        display: block;
        height: 0;
        width: 100%;
        padding-bottom: calc(83 / 112 * 100%);
        z-index: 100;
      }
    }

    .file_upload_status {
      // border: 1px solid $text-color;
      height: inherit;

      &:after {
        display: none !important;
      }

      strong {
        font-size: 0.8em;
        font-weight: 300;
      }

      span {
        font-weight: 500;
      }

      @include max(575.9) {
        height: 30px;
        top: 0;
        width: 100%;
        max-width: 100%;
        border: none;
        position: absolute;
      }
    }

    .add_more_btn {
      &:hover {
        background: #23cc2b;

        .add_more_btn_contents {
          color: #fff;

          svg {
            path {
              fill: #fff;
            }
          }
        }
      }
    }

    //   /* Chrome, Edge, and Safari */
    //   ::-webkit-scrollbar {
    //     width: 20px;
    //   }

    // *::-webkit-scrollbar-track {
    //   // background: #ffffff;
    // }

    // *::-webkit-scrollbar-thumb {
    //   background-color: #2f2c30;
    //   border-radius: 10px;
    //   // border: 3px solid #ffffff;
    //   width: 50px;
    //   height: 10px;
    // }
    @include max(575.9) {
      padding-top: 42px;
      position: relative;

      .add_more_btn_contents {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        strong {
          margin-bottom: 0px;
          padding: 5px 15px 5px 5px;
        }
      }
    }
  }

  &_btn_wrap {
    text-align: center;
    margin-top: 27px;

    :global(.btn) {
      min-width: 136px;
      line-height: 1;
    }
  }

  &_delete {
    position: absolute;
    top: 5px;
    right: 5px;
    background: #00000064;
    box-shadow: 0px 3px 4px #0000001c;
    border: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 8px;
      height: 8px;

      path {
        fill: #fff;
      }
    }

    &:hover {
      background: #ffffff64;

      svg {
        path {
          fill: $text-color-alt;
        }
      }
    }
  }

  &_list_wrap {
    height: inherit !important;
    // width: auto!important;
    // overflow: visible!important;
    min-height: 100px;

    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    > div {
      // min-width: 100%;
      // display: flex;
      // width: auto!important;
      .file_upload_preview_wrap {
        // margin-right: 10px;
        // inset: 0!important;
        // position: relative!important;
        padding-bottom: 4px;
      }
    }

    // &_inneritem{
    //   width: 100%;
    //   // scroll-snap-type: x mandatory;
    //   // -webkit-overflow-scrolling: touch;
    //   overflow: scroll;
    //   // scroll-behavior: smooth;
    //   // scroll-snap-destination: 10%;
    //   // &::-webkit-scrollbar:horizontal {
    //   //   height: 0px;
    //   // }
    // }

    // /* Track */
    // ::-webkit-scrollbar-track {
    //   background: #f1f1f1;
    // }

    // /* Handle */
    // ::-webkit-scrollbar-thumb {
    //   background: #888;
    // }

    // /* Handle on hover */
    // ::-webkit-scrollbar-thumb:hover {
    //   background: #555;
    // }
  }

  &_img_wrap {
    width: 100%;
    display: flex;
    overflow: auto;
    height: 100%;

    &::-webkit-scrollbar:horizontal {
      height: 0px;
    }
  }
}

.usage_limit_exeeded {
  font-weight: 400 !important;
  text-align: center;
  width: 100%;
  color: $red;
  line-height: 1.5em !important;
}

.duplicatedIcon {
  position: relative;
  z-index: 1000 !important;
}

.progress_message {
  display: flex;
  justify-content: center;
  width: 100%;

  p {
    margin: 0;
    font-size: 1.2rem;
    font-weight: 500;
    color: #fff;
  }
}

.file_upload_modal_form {
  @include max(768) {
    // max-width: 350px;
  }
}

.errorMessage {
  text-align: center;
  margin-top: 10px;
  color: #cd335c;
  font-weight: 600;
}

::-webkit-scrollbar {
  width: 5px !important;
  height: 10px;
}

/* Track */
// ::-webkit-scrollbar-track {
//   box-shadow: inset 0 0 5px grey;
//   border-radius: 10px;
// }

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(40, 37, 37);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  // background: #9a8585;
}

.add_more_btn_contents_add {
  flex-direction: column !important;
}

.file_upload_status + div {
  max-width: calc(100% - 257px);
  margin-bottom: -14px;
  width: 100%;
}

.file_upload_status {
  .add_more_btn_contents {
    will-change: background-position;

    &:global(.inprogress) {
      background-image: linear-gradient(
        90deg,
        rgba(66, 203, 51, 0.1) 0%,
        rgba(66, 203, 51, 0.3) 50%,
        rgba(255, 255, 255, 0) 50%,
        rgba(255, 255, 255, 0) 100%
      );
      background-size: 200% 100%;
      background-position: center right 0%;
      animation: uploadInprogress 3s ease-in-out infinite;
    }
  }
}

@keyframes uploadInprogress {
  0% {
    background-position: center right 0%;
  }

  100% {
    background-position: center right 100%;
  }
}

.upload_image_info_btn {
  padding: 0px;
  border: none;
  background-color: transparent;
  min-width: 10px;
  position: absolute;
  right: 6px;
  bottom: 6px;

  svg {
    margin-bottom: 0px !important;
  }
}
.successMsg {
  text-align: center;
  padding-top: 20px;
  p {
    color: #21c031;
    animation: fadeup 1s ease-in-out forwards;
  }
}
@keyframes fadeup {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
