@import "../../styles/uitilities.scss";

.home_banner {
  position: relative;
  background: $banner-video-unload-bg;
  transition: 0.5s ease all;
  height: min(calc(100vh - 73px), 715px);
  @media (max-height: 700px) {
    height: 715px;
  }
  @include max(1199.98) {
    height: 620px;
  }
  @include max(991.98) {
    height: 576px;
  }
  @include max(767.98) {
    padding-bottom: 43px;
    height: 425px;
  }
  @include max(575.98) {
    height: calc(100vh - 50px);
  }
  // &::before {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background: url('../../assets/banner_video_poster.png') center no-repeat;
  //   background-size: cover;
  //   opacity: 0;
  //   transition: 0.3s ease all;
  //   @include max(575.98) {
  //     background: url('../../assets/banner_video_poster_mob.png') center no-repeat;
  //     background-size: cover;
  //   }
  // }
  // &.bg_loaded {
  //   &::before {
  //     opacity: 1;
  //   }
  // }
  .banner_gradient {
    background: linear-gradient(to top, rgba(#000, 0.8) 5%, rgba(#000, 0) 60%);
    width: 100%;
    height: calc(100% - 50px);
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
    z-index: 0;
    opacity: 0;
    transition: 0.5s ease-out opacity;
    &.loaded {
      opacity: 1;
    }
  }
  &_content {
    max-width: 630px;
    width: 100%;
    height: 100%;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $white;
    position: relative;
    margin-right: 6%;
    margin-top: -11px;
    transition: 0.5s ease all;
    z-index: 2;
    @include max(1399.98) {
      max-width: 500px;
    }
    @include max(991.98) {
      margin-right: 0;
      max-width: 365px;
    }
    @include max(767.98) {
      margin: 0 auto;
      justify-content: flex-end;
      max-width: 344px;
    }
    @include max(575.98) {
    }
  }
  &_title {
    font-size: size(52);
    font-weight: $font-regular;
    line-height: 1.11;
    margin-bottom: 18px;
    position: relative;
    color: inherit;
    @include max(991.98) {
      font-size: size(50);
      margin-bottom: 25px;
    }
    @include max(767.98) {
      text-align: center;
      font-size: size(38);
      margin-bottom: 38px;
      line-height: 1.1;
    }
    @include max(374.98) {
      font-size: size(35);
    }
    &::after {
      content: url("../../assets/images/banner-title_stroke.svg");
      position: absolute;
      bottom: -30px;
      right: 77px;
      @include max(991.98) {
        right: 110px;
      }
      @include max(767.98) {
        right: 50%;
        transform: translateX(50%);
      }
    }
    &_bold {
      font-size: size(52);
      font-weight: $font-bold;
      display: block;
      @include max(1399.98) {
        font-size: size(48);
      }
      @include max(991.98) {
        font-size: size(50);
      }
      @include max(767.98) {
        font-size: size(38);
      }
      @include max(374.98) {
        font-size: size(34);
      }
    }
  }
  &_description {
    font-size: size(18);
    font-weight: $font-regular;
    line-height: 29px;
    margin-bottom: 44px;
    letter-spacing: 0.2px;
    @include max(767.98) {
      text-align: center;
      font-size: size(15);
      line-height: 26px;
      margin-bottom: 0;
    }
    @include max(374.98) {
      line-height: 24px;
    }
  }
  &_scrolldown {
    position: absolute;
    bottom: 6px;
    left: -12px;
    display: flex;
    align-items: center;
    margin-bottom: 21px;
    padding-left: 58px;
    font-size: size(16);
    font-weight: $font-medium;
    color: $white;
    background: none;
    border: none;
    transition: 0.5s ease all;
    @include max(767.98) {
      display: none;
    }
    &_anim {
      position: absolute;
      top: -25px;
      left: 0;
      .scroll_me {
        position: absolute;
        left: 15px;
        @include max(575.98) {
          display: none;
        }
        .chevron {
          position: absolute;
          width: 28px;
          height: 8px;
          opacity: 0;
          transform: scale3d(0.5, 0.5, 0.5);
          animation: move 3s ease-out infinite;
          &:first-child {
            animation: move 3s ease-out 1s infinite;
          }
          &:nth-child(2) {
            animation: move 3s ease-out 2s infinite;
          }
          &::before,
          &::after {
            content: " ";
            position: absolute;
            top: 0;
            height: 100%;
            width: 51%;
            background: #fff;
          }
          &::before {
            left: 0;
            transform: skew(0deg, 30deg);
          }

          &::after {
            right: 0;
            width: 50%;
            transform: skew(0deg, -30deg);
          }
        }
      }
    }
  }
  :global(.dropdown-menu) {
    background-color: $white;
    border-radius: 10px;
    padding: 13px;
    position: relative;
    &::before {
      content: "";
      background: $white;
      position: absolute;
      right: 45px;
      width: 14px;
      height: 14px;
    }
    &[x-placement="bottom-start"] {
      transform: translate(0px, 63px) !important;
      &::before {
        top: 0;
        transform: translateY(-50%) rotate(135deg);
        border: 1px solid transparent;
        border-radius: 0 0 0 5px;
        border-bottom: 1px solid $border-color;
        border-left: 1px solid $border-color;
      }
    }
    &[x-placement="top-start"] {
      transform: translate(0px, -64px) !important;
      &::before {
        bottom: 0;
        transform: translateY(50%) rotate(135deg);
        border: 1px solid transparent;
        border-radius: 0 5px 0 0;
        border-top: 1px solid $border-color;
        border-right: 1px solid $border-color;
      }
    }
    a {
      font-size: size(17);
      font-weight: $font-regular;
      padding: 6px 10px;
      border-radius: 6px;
      overflow: hidden;
      transition: 0.5s ease all;
      &:hover,
      &:focus {
        color: $text-alt;
        background-color: $dropdown-bg-hover;
      }
    }
  }
}

@keyframes move {
  25% {
    opacity: 1;
  }
  33% {
    opacity: 1;
    transform: translateY(30px);
  }
  67% {
    opacity: 1;
    transform: translateY(40px);
  }
  100% {
    opacity: 0;
    transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
  }
}

@keyframes pulse {
  to {
    opacity: 1;
  }
}
