@import "../../../../styles/uitilities.scss";

.slideshow{

    &_options {
    order: 3;
    flex: 0 0 100%;
    max-width: 343px;
    margin-top: 37px;
    margin-left: auto;
    margin-right: auto;
  }


&_options {
    background: #d3d5db50;
    border: 1px solid #d3d5db;
    border-radius: 20px;
    padding: 11px 26px;

    @include max(767.98) {
      padding: 9px 21px;
      border-radius: 13px;
    }
    :global(.dropdown-toggle) {
      > div {
        display: flex;
        align-items: center;
      }
    }
    :global(.dropdown-menu) {
      background-color: $white;
      border-radius: 10px;
      padding: 13px;
      position: relative;
      &::before {
        content: "";
        background: $white;
        position: absolute;
        left: 20px;
        width: 14px;
        height: 14px;
      }
      &[x-placement="bottom-start"] {
        transform: translate(-20px, 48px) !important;
        &::before {
          top: 0;
          transform: translateY(-50%) rotate(135deg);
          border: 1px solid transparent;
          border-radius: 0 0 0 5px;
          border-bottom: 1px solid $border-color;
          border-left: 1px solid $border-color;
        }
      }
      &[x-placement="top-start"] {
        transform: translate(-20px, -48px) !important;
        &::before {
          bottom: 0;
          transform: translateY(50%) rotate(135deg);
          border: 1px solid transparent;
          border-radius: 0 5px 0 0;
          border-top: 1px solid $border-color;
          border-right: 1px solid $border-color;
        }
      }
    }
    &_list {
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      @include max(767.98) {
        justify-content: space-around;
      }

      li {
        padding: 0 10px;
        position: relative;
        :global {
          .dropdown {
            max-width: 18px;
          }
        }
        svg {
          cursor: pointer;
        }

        > div {
          display: flex;
          align-items: center;
          span {
            display: flex;
            align-items: center;
          }
          &:not(.slideshow_options_play) {
            &::after {
              content: "";
              width: 36px;
              height: 36px;
              background-color: #cfd0d1;
              border-radius: 50%;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%) scale(0);
              z-index: -1;
              transition: 0.3s ease transform;
            }
            &:hover {
              &::after {
                transform: translate(-50%, -50%) scale(1);
              }
            }
            svg {
              path {
                fill: $text-color-alt;
              }
            }
            :global {
              .scroll-dropdown {
                svg {
                  path {
                    fill: $black;
                  }
                }
              }
            }
          }
        }
      }
    }

    &_filter {
      :global(.dropdown-menu a) {
        &:active {
          background-color: #e9ecef;
          color: $black;
        }
      }
      .filter_icon {
        :global(svg) {
          fill: #2c2c34;
          transform: rotate(270deg);
          transform-origin: center;
        }
      }

      .filter_dropdown {
        min-width: inherit;
        padding: 0;
        background-color: transparent;
        border: none;

        &::after {
          display: none;
        }
      }
    }

    &_music {
      svg {
        transform: rotate(180deg);
        transform-origin: center;
      }

      :global(.dropdown-toggle) {
        min-width: inherit;
        padding: 0;
        background-color: transparent;
        border: none;
        &::after {
          display: none;
        }
      }

      :global(.dropdown-menu a) {
        position: relative;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        span {
          display: none;
        }

        &:hover span {
          display: block;
        }

        &:hover img {
          display: none;
        }
        &:active {
          background-color: #e9ecef;
          color: $black;
        }

        svg {
          transform: rotate(1deg);
        }
      }
    }

    &_play {
      :global(svg) {
        fill: $btn-secondary;
        width: 44px;
        height: 44px;
        transition: 0.3s ease transform;
        will-change: transform;
        &:hover {
          transform: scale(1.15);
        }
        @include max(767.98) {
          width: 40px;
          height: 40px;
        }
      }
    }

    &_fullscreen {
      svg {
        path {
          fill: $text-color;
        }
      }
    }

    &_slide_duration {
      :global(.dropdown-toggle) {
        min-width: inherit;
        padding: 0;
        background-color: transparent;
        border: none;
        &::after {
          display: none;
        }
      }
      .duration_inputs {
        display: flex;
        align-items: center;
        margin-top: 5px;
        margin-bottom: 10px;
        .duration_input {
          margin: 0 8px;
          flex: 1;
          width: 100%;
          height: 32px;
          border-radius: 16px;
          border: 1px solid $black;
          padding: 0 18px;
          font-size: size(14);
          font-weight: $font-regular;
          color: $black;
          input {
            border: none;
            background-color: transparent;
            height: 100%;
          }
        }
      }
      .duration_modifier_btn {
        font-size: size(28);
        width: 32px;
        height: 32px;
        flex: 0 0 32px;
        background-color: transparent;
        border: 1px solid $black;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        &:hover {
          color: $white;
          background-color: $btn-primary-hover;
          border: 1px solid $btn-primary-hover;
          svg {
            path {
              fill: $white !important;
            }
          }
        }
      }
    }
  }



}

.musicVisualizer {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.listItems {
  height: 100px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  a {
    transition: 0.3s ease background-color;
    &:hover {
      background-color: #e9ecef !important;
    }
    &:focus {
      background-color: transparent;
    }
  }
  &_active {
    background-color: #e9ecef !important;
  }
}

.loopWrap {
  position: relative;
  &.loopOff {
    &::before {
      content: "";
      position: absolute;
      top: 99%;
      left: 40%;
      width: 20px;
      height: 1px;
      background-color: $text-color-alt;
      transform: rotate(45deg) translate(-50%, -50%);
    }
  }
}
.disabled_btn{
  opacity: 0.5;
  pointer-events: none;
}