@import "../../../styles/uitilities.scss";

.frame_item{
    padding: 1px 14px 20px 14px;
    cursor: pointer;
    @include max(575.98) {
        padding-left: 9px;
        padding-right: 9px;
    }
    &_container{
        max-width: 70px;
        width: 100%;
    }
    &_img{
        width: 100%;
        height: 70px;
        position: relative;
        img{
            width: 100%;
            height: 100%;
        }
    }
    &_name{
        width: 100%;
        text-align: center;
        margin-top: 8px;
        &_title{
            font-size: 14px;
            margin-bottom: 0px;
            color: $text-color;
        }
    }
    &.frame_selected{
        .frame_item_img{
            img{
                opacity: 0.6;
            }
            &:after{
                content: "";
                width: 100%;
                height: 100%;
                background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='23.003' height='17.368' viewBox='0 0 23.003 17.368'%3e%3cpath id='Path_76464' data-name='Path 76464' d='M10214.509,12650.339l6.7,6.7,14.186-14.187' transform='translate(-10213.448 -12641.788)' fill='none' stroke='%23000' stroke-width='3'/%3e%3c/svg%3e");
                background-position: center;
                background-size: 20px;
                background-size: 20px;
                position: absolute;
                background-repeat: no-repeat;
                left: 0;
                top: 0;
            }
        }
    }
}