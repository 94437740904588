@import "../../styles/uitilities.scss";

.slideshow_list {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    @include max(575.98) {
        margin: 0 -12px;
    }
    &_item {
        flex: 0 0 20%;
        max-width: 20%;
        padding: 0 10px;
        margin-bottom: 30px;
        @include max(1599.98) {
            flex: 0 0 25%;
            max-width: 25%;
        }
        @include max(991.98) {
            flex: 0 0 33.3333%;
            max-width: 33.3333%;
        }
        @include max(575.98) {
            flex: 0 0 50%;
            max-width: 50%;
            padding: 0 6px;
            margin-bottom: 12px;
        }
        @include max(319.98) {
            flex: 0 0 100%;
            max-width: 100%;
        }
        // @include max(424.98) {
        //     flex: 0 0 100%;
        //     max-width: 100%;
        // }
        &_card {
            background: $dashboard-bg-alt;
            border-radius: 12px;
            padding: 9px;
            height: 100%;
            position: relative;
            overflow: hidden;
        }
        &:hover {
            cursor: pointer;
        }
        .shimmer-styles {
            width: 100%;
            background: $shimmer-bg-alt;
            background-image: $shimmer-gradient-alt;
            background-repeat: no-repeat;
            background-size: 800px 100%;
            border-radius: 5px;

            -webkit-animation-duration: 1s;
            -webkit-animation-fill-mode: forwards;
            -webkit-animation-iteration-count: infinite;
            -webkit-animation-name: placeholderShimmer;
            -webkit-animation-timing-function: linear;
        }
        figure {
            img {
                opacity: 0;
                transform: scale(1.1);
                transform-origin: center;
                will-change: transform;
            }
        }
        svg {
            opacity: 1;
            visibility: visible;
            transition: 0.3s ease-in-out opacity;
            transition-delay: 0.4s;
        }
        &:global(.slideshow-loading) {
            figure {
                @extend .shimmer-styles;
            }
            svg {
                opacity: 0;
                visibility: hidden;
            }
            h4 {
                width: 50%;
                height: 18px;
                @extend .shimmer-styles;
                margin-bottom: 0;
            }
            span {
                display: block;
                width: 25%;
                height: 18px;
                @extend .shimmer-styles;
            }
        }
        &:global(.slideshow-loaded) {
            figure {
                img {
                    opacity: 1;
                    transform: scale(1);
                }
            }
        }
        &_data {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 7px;
            @include max(399.98) {
                flex-direction: column;
                align-items: flex-start;
            }
        }
        &_image {
            position: relative;
            overflow: hidden;
            max-width: 288px;
            border-radius: 6px;
            margin-bottom: 8px;
            flex: 1;
            background-color: $blank-folder-outer;
            border-radius: 6px;
            height: calc(100% - 37px);
            @include max(424.98) {
                max-width: 100%;
            }
            @include max(399.98) {
                margin-bottom: 6px;
            }
            &.no_data {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 50px 0;
                .slideshow_play_btn {
                    display: none;
                }
            }
            &:not(.no_data) {
                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: block;
                    object-fit: cover;
                    max-width: 100%;
                    max-height: 100%;
                }
                &::after {
                    content: "";
                    display: block;
                    height: 0;
                    width: 100%;
                    padding-bottom: calc(203 / 288 * 100%);
                }
            }
        }
        .slideshow {
            &_name {
                font-size: size(16);
                font-weight: $font-medium;
                color: $text-color-alt;
                margin-bottom: 0;
                @include max(399.98) {
                    font-size: size(14);
                }
            }
            &_duration {
                font-size: size(14);
                font-weight: $font-regular;
                color: $text-color-alt;
                color: $text-color-alt;
                opacity: 0.62;
                @include max(399.98) {
                    font-size: size(12);
                }
            }
            &_play_btn {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}

.slideshow_list_item {
    position: relative;
    .slideshow_list_item_image {
        img {
            transition: 0.3s ease-out transform;
        }
    }
    &:hover {
        .slideshow_list_item_image {
            &:not(.no_data) {
                img {
                    transform: scale(1.1);
                }
            }
        }
    }
}

// .preSelectAlbum {
//     .selectionItem {
//         // box-shadow: 0 0 100vmin 100vmin #ffffff99;
//         // box-shadow: 0 0 100vmin 100vmin #00000062;
//     }
// }

.preselection {
    opacity: 0.4;
}

.selectionItem {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    z-index: 10;
    bottom: 52px;
    right: 15px;
    border: 2px solid #bfb5b5;
}

.gallery_select_tick {
    position: absolute;
    bottom: 52px;
    right: 15px;
    z-index: 5;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 2px solid #fff;
    background: #1d5eff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
}

@keyframes placeholderShimmer {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}
