@import "../../../styles/uitilities.scss";

.language_menu {
    position: fixed;
    top: 0;
    right: 0;
    max-width: 440px;
    width: 100%;
    height: 100vh;
    z-index: 100;
    background: $body-background;
    @include max(767.98) {
        top: 51px;
    }
    &_title {
        padding: 20px 0 20px;
        border-bottom: 1px solid #e0e2e8;
        margin-bottom: 0;
        position: relative;
        cursor: pointer;
        color: $text-color-alt;
        &::after {
            content: "";
            width: 13px;
            height: 14px;
            border: 2px solid transparent;
            border-right: 2px solid var(--text-color-alt);
            border-bottom: 2px solid var(--text-color-alt);
            background: transparent;
            transform: translateY(50%) rotate(225deg);
            position: absolute;
            right: 19px;
            top: 38%;
            pointer-events: none;
        }
        button {
            border: none;
            background: transparent;
            width: 100%;
            text-align: left;
        }
    }
    &_list {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        padding: 20px 0;
        li {
            font-size: size(16);
            font-weight: $font-medium;
            color: $text-color-alt;
            &:not(:last-child) {
                margin-bottom: 30px;
                @include max(575.98){
                    margin-bottom: 18px;
                }
            }
            input {
                appearance: none;
                cursor: pointer;
                &:checked {
                    + label {
                        &::before {
                            border: 1px solid #23cc2b;
                        }
                        &::after {
                            background: #23cc2b;
                            border: 1px solid #23cc2b;
                        }
                    }
                }
            }
            label {
                position: relative;
                padding-left: 32px;
                cursor: pointer;
                &::before {
                    content: "";
                    width: 20px;
                    height: 20px;
                    background: transparent;
                    border: 1px solid #a2a4ac;
                    border-radius: 50%;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    transition: 0.3s ease all;
                }
                &::after {
                    content: "";
                    width: 10px;
                    height: 10px;
                    background-color: #a2a4ac;
                    border: 1px solid #a2a4ac;
                    border-radius: 50%;
                    position: absolute;
                    top: 50%;
                    left: 5px;
                    transform: translateY(-50%);
                    transition: 0.3s ease all;
                }
            }
        }
    }
}

header {
    .language_menu {
        top: 0;
    }
}
