@import "../../../styles/uitilities.scss";

.grid_album {
  cursor: pointer;
  &_row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
    @include min(768) {
      margin: 0 -9px;
    }
  }
  &_col {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 5px;
    @include min(375) {
      flex: 0 0 50%;
      max-width: 50%;
    }
    @include min(768) {
      flex: 0 0 33.3333%;
      max-width: 33.3333%;
      padding: 0 9px;
    }
    @include min(992) {
      flex: 0 0 25%;
      max-width: 25%;
    }
    @include min(1200) {
      flex: 0 0 20%;
      max-width: 20%;
    }
    @include min(1600) {
      flex: 0 0 16.6667%;
      max-width: 16.6667%;
    }
    &.preselection {
      opacity: 0.4;
    }
    &.shake_active {
      animation: rearrangeShake 1.2s linear infinite forwards;
    }
  }
  &_card {
    background: $dashboard-bg-alt;
    border-radius: 12px;
    padding: 9px;
    margin-bottom: 20px;
    height: calc(100% - 20px);
    display: flex;
    flex-direction: column;
    color: $text-color;
    &_image {
      margin-bottom: 0;
      position: relative;
      overflow: hidden;
      flex: 1;
      background-color: $blank-folder-outer;
      border-radius: 6px;
      max-height: 170px;
      cursor: pointer;
      @include min(1920) {
        max-height: 235px;
      }
      &:not(.no_data) {
        background-color: $white;
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: block;
          object-fit: cover;
          max-width: 100%;
          max-height: 100%;
        }
        &::after {
          content: "";
          display: block;
          height: 0;
          width: 100%;
          padding-bottom: calc(155 / 216 * 100%);
        }
      }
      &:not(.no_frame) {
        border: 4px solid transparent;
        padding: 18px 20px;
        border-image-repeat: round;
        border-image-slice: 30;
        border-image-width: 25px;
        @include min(768) {
          padding: 22px;
          border: 22px solid transparent;
          border-image-repeat: round;
          border-image-slice: 30;
          border-image-width: 15px;
        }
        @include max(575.98) {
          padding: 18px 22px;
          border: 20px solid transparent;
          border-image-repeat: round;
          border-image-slice: 30;
          border-image-width: 15px;
        }
      }
      &.no_frame {
        border-radius: 6px;
      }
      &.no_data {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 45px 0;
        border: none!important;
        @include max(575.98) {
          padding: 25px 0;
          img {
            transform: scale(0.7);
          }
        }
      }
    }
    &_data {
      margin-top: 17px;
      position: relative;
      padding-right: 30px;
      max-height: 46.19px;
      height: 100%;
    }
    &_title {
      font-size: size(16);
      font-weight: $font-medium;
      margin-bottom: 5px;
      color: $text-color-alt;
      @include line-clamp(1);
    }
    &_description {
      font-size: size(14);
      font-weight: $font-regular;
      line-height: 22px;
      color: $text-color-alt;
      opacity: 0.75;
      margin-bottom: 0;
      @include line-clamp(1);
    }
    &_options {
      background-color: transparent;
      border: none;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      position: absolute;
      top: -8px;
      right: 0;
      &:global(.show > .btn-primary.dropdown-toggle) {
        border: none;
      }
      :global{
        .dropdown-toggle{
          border-radius: 56%;
          &[aria-expanded="false"]:focus{
            background-color: transparent!important;
            border-color: transparent!important;
          }
        }
        
      }
      &_btn {
        background-color: transparent;
        border: none;
        width: 32px;
        height: 32px;
        padding: 0;
        min-width: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        &::after {
          display: none;
        }
        &:hover {
          background-color: transparent;
        }
        &:global(:has(& +.dropdown-menu.show)) {
          background-color: $placeholder-image;
          border: none;
          border-radius: 50%;
        }
      }
      :global(.dropdown-menu) {
        background-color: $white;
        border-radius: 10px;
        padding: 13px;
        position: relative;
        &::before {
          content: "";
          background: $white;
          position: absolute;
          right: 45px;
          width: 14px;
          height: 14px;
        }
        &[x-placement="bottom-start"] {
          transform: translate(-90px, 40px) !important;
          &::before {
            top: 0;
            transform: translateY(-50%) rotate(135deg);
            border: 1px solid transparent;
            border-radius: 0 0 0 5px;
            border-bottom: 1px solid $border-color;
            border-left: 1px solid $border-color;
          }
        }
        &[x-placement="bottom-end"] {
          transform: translate(35px, 40px) !important;
          &::before {
            top: 0;
            transform: translateY(-50%) rotate(135deg);
            border: 1px solid transparent;
            border-radius: 0 0 0 5px;
            border-bottom: 1px solid $border-color;
            border-left: 1px solid $border-color;
          }
        }
        &[x-placement="top-start"] {
          transform: translate(-90px, -40px) !important;
          &::before {
            bottom: 0;
            transform: translateY(50%) rotate(135deg);
            border: 1px solid transparent;
            border-radius: 0 5px 0 0;
            border-top: 1px solid $border-color;
            border-right: 1px solid $border-color;
          }
        }
        &[x-placement="top-end"] {
          transform: translate(35px, -40px) !important;
          &::before {
            bottom: 0;
            transform: translateY(50%) rotate(135deg);
            border: 1px solid transparent;
            border-radius: 0 5px 0 0;
            border-top: 1px solid $border-color;
            border-right: 1px solid $border-color;
          }
        }
        a {
          font-size: size(17);
          font-weight: $font-regular;
          padding: 6px 10px;
          border-radius: 6px;
          overflow: hidden;
          transition: 0.5s ease all;
          &:hover,
          &:focus {
            color: $text-alt;
            background-color: $dropdown-bg-hover;
          }
        }
      }
      svg {
        path {
          fill: $text-color-alt;
        }
      }
      &:hover {
        background-color: $placeholder-image;
      }
    }
  }

  .selection {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    z-index: 10;
    bottom: 0;
    right: 0;
    // bottom: 15px;
    // right: 15px;
    border: 2px solid #bbafaf;
    //box-shadow: 0 0 100vmin 100vmin #00000062;
    pointer-events: none;
  }

  .gallery_select_tick {
    position: absolute;
    z-index: 5;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid #fff;
    background: #1d5eff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    width: 25px;
    height: 25px;
    bottom: 0;
    right: 0;
    // top: 0;
    // bottom: 15px;
    // right: 15px;
  }
}
.delete_this_photo_modal {
  color: $text-color;
  :global(.modal-dialog) {
    max-width: 474px;
  }
  :global(.modal-content) {
    padding: 50px 45px;
    text-align: center;
  }
  :global(.modal-header) {
    height: auto;
  }
  :global {
    .custom-checkbox {
      align-items: flex-start;
      span {
        font-size: size(14);
        line-height: 19px;
        color: $text-color-alt;
        opacity: 0.49;
        padding-left: 3px;
      }
      .checkmark {
        width: 16px;
        height: 16px;
        flex: 0 0 16px;
        margin-top: 2px;
        border-radius: 2px;
        background-color: $modal-bg;
        &::after {
          position: absolute;
          top: 3px;
          left: 2px;
          border-radius: 0;
          width: 10px;
          height: 5px;
          background: transparent;
          border: 1px solid transparent;
          border-left: 1px solid #fc4343;
          border-bottom: 1px solid #fc4343;
          transform: rotate(-45deg);
        }
      }
      input {
        &:checked {
          + .checkmark {
            border-radius: 2px;
            border: 1px solid #fc4343;
          }
        }
      }
    }
  }
  .delete_btn_wrap {
    display: flex;
    margin-top: 25px;
    button {
      width: 100%;
      max-width: 185px;
      border: none;
      border-radius: 6px;
      font-weight: 500;
      font-size: size(15);
      padding: 13px;
      margin: 0 7px;
      transition: background-color 0.3s ease-in;
    }
    :global(.btn-outline-gray) {
      background-color: transparent;
      border: 1px solid $input-border;
      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
    :global(.btn-danger) {
      color: white;
      background-color: $delete-red;
      &:hover {
        background-color: $orange-red-alt;
      }
    }
  }
  .delete_title {
    font-size: size(24);
    font-weight: 700;
    margin-bottom: 10px;
    color: $text-color;
  }
  .delete_content {
    font-size: size(16);
    font-weight: 400;
  }
  :global(.btn-close) {
    top: 18px !important;
    right: 19px !important;
    font-size: 15px !important;
  }
}

@keyframes rearrangeShake {
  0% {
    transform: rotate(0);
  }
  7.69% {
    transform: rotate(1deg);
  }
  15.38% {
    transform: rotate(0);
  }
  23.07% {
    transform: rotate(-1deg);
  }
  30.76% {
    transform: rotate(0);
  }
  38.45% {
    transform: rotate(1deg);
  }
  46.14% {
    transform: rotate(0);
  }
  53.83% {
    transform: rotate(-1deg);
  }
  61.52% {
    transform: rotate(0);
  }
  69.21% {
    transform: rotate(0);
  }
  76.9% {
    transform: rotate(0);
  }
  84.59% {
    transform: rotate(0);
  }
  92.28% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}
.disabled {
  filter: brightness(0.7);
  // pointer-events: none;
}

.lock_btn {
  cursor: pointer;
  background-color: transparent;
  border: none;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: absolute;
  top: -8px;
  right: 0;
  display: flex;
  svg {
    margin: auto;
    path {
      fill: #fff;
    }
  }
}

.spinner {
  width: 35px;
  height: 35px;
  // margin-left: 50px;
}

body{
  .grid_album_card_image.no_frame:not(.no_data){
    background-color: var(--dashboard-bg-alt);
    img{
      z-index: 1;
    }
  }
  &:global(.dark){
    .grid_album_card_image.no_frame:not(.no_data){
      background-color: var(--dashboard-bg-alt);
    }
  }
}