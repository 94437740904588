@import "../../../styles/uitilities.scss";

.ProfileImageCrop {
  &__Modal {
    :global {
      .modal-dialog {
        max-width: 350px;
      }
      .modal-content {
        padding: 40px 35px;
      }
    }
  }
  &__previewImage {
    position: relative;
    overflow: hidden;
    img {
      width: 100%;
      height: 160px;
      border-radius: 8px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      object-fit: contain;
      max-width: 100%;
      max-height: 100%;
    }
    &::after {
      content: "";
      display: block;
      height: 0;
      width: 100%;
      padding-bottom: calc(180 / 261 * 100%);
    }
  }
  &__controller {
    :global {
      .ant-slider-track {
        background-color: $btn-primary-hover;
        transition: none;
      }
      .ant-slider {
        &-handle {
          border: 2px solid $btn-primary-hover;
          background-color: $btn-primary-hover;
          transition: none;
        }
        &-rail {
          border-radius: 12px;
          transition: none;
        }
        &:hover {
          .ant-slider-track {
            background-color: $primary-color;
          }
          .ant-slider-handle {
            border: 2px solid $primary-color;
            background-color: $primary-color;
          }
        }
      }
    }
  }
  &__btnWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    column-gap: 10px;
    :global {
      .btn {
        min-width: 120px;
        flex: 1;
        padding: 12px 15px;
        &-tertiary {
          color: #fff;
        }
      }
    }
  }
  &__upload {
    &Box {
      position: relative;
      overflow: hidden;
      background-color: $file-upload-bg;
      &:hover {
        background-color: $file-upload-bg;
      }
      label {
        width: 100%;
        height: 160px;
        border: 1px dashed $border-color;
        border-radius: 8px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        object-fit: cover;
        max-width: 100%;
        max-height: 100%;
        cursor: pointer;
        figure {
          margin-bottom: 13px;
          max-width: 60px;
          img {
            width: 100%;
          }
        }
        p {
          font-size: size(14);
          font-weight: $font-medium;
          line-height: 16px;
          text-align: center;
          color: $text-color-alt;
          span {
            color: $green;
            text-decoration: underline;
            cursor: pointer;
            font-size: size(15);
          }
        }
      }
      &::after {
        content: "";
        display: block;
        height: 0;
        width: 100%;
        padding-bottom: calc(190 / 270 * 100%);
      }
    }
    &FileLimit {
      font-size: size(14);
      font-weight: $font-regular;
      line-height: 16px;
      margin-bottom: 15px;
    }
  }
}
.Profile_loader {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
