@import "../../styles/uitilities.scss";

.my_gallery {
  margin: 0 auto;
  position: relative;
  height: 100%;
  padding-bottom: 44px;
  @include max(767.98) {
    width: 100%;
    padding: 0 16px;
  }
  &_header {
    position: sticky;
    top: 50px;
    z-index: 99;
    background: $dashboard-bg;
    margin-bottom: 40px;
    padding: 0 50px;
    @include min(1440) {
      top: 78px;
      padding-top: 44px;
    }
    @include max(1279.98) {
      padding-top: 15px;
    }
    @include max(575.98) {
      margin-bottom: 25px;
      padding: 0 16px;
      width: calc(100vw - 32px);
      padding: 0;
      padding-top: 15px;
      overflow: hidden;
    }
    + div {
      padding: 0 50px;
      margin: 0 !important;
      @include max(575.98) {
        padding: 0;
      }
      > div {
        margin-left: -10px;
        margin-right: -10px;
        width: inherit;
      }
    }
  }
  &_title {
    font-size: size(30);
    font-weight: $font-bold;
    line-height: 40px;
    color: $text-color-alt;
    margin-bottom: 0;
    @include max(767.98) {
      font-size: size(24);
      line-height: 28px;
      margin-bottom: 5px;
    }
  }
  :global {
    .my-masonry-grid_column {
      figure[class*="img-load"] {
        padding: 0;
        border-image-width: 0;
        border-radius: 20px !important;
      }
    }
  }
}
