@import "../../../styles/uitilities.scss";

.HistoryItem {
  display: flex;
  padding: 14px 25px 20px 25px;
  transition: background-color 0.3s ease-in-out;

  &:global(.active) {
    background-color: rgba(#7BA5FF, 0.19);
  }

  &:global(.selected),
  &:focus,
  &:focus-within,
  &:hover {
    background-color: rgba(#bdbec0, 0.19);
  }

  &_content {
    width: 100%;

    &_name {
      margin-bottom: 0px;
      font-size: 16px;
      font-weight: 500 !important;
      margin-bottom: 3px;
      color: currentColor;
      background-color: transparent;
      border: 1px solid transparent;
      border-radius: 6px;
      padding: 6px 14px;
      margin: 0 -14px 0px -14px !important;
      line-height: 1em;
      pointer-events: none;

      &:nth-last-child(1) {
        margin-top: 7px;
      }

      &_editActive {
        border-color: #1D5EFF;
        pointer-events: all;
      }
    }

    &_date {
      margin-bottom: 0px;
      font-size: 14px;
      opacity: 0.7;

      &:nth-child(1) {
        margin-bottom: 0px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 3px;
        color: currentColor;
        background-color: transparent;
        border: 1px solid transparent;
        border-radius: 6px;
        padding: 6px 14px;
        margin: 0 -14px 0px -14px;
        line-height: 1em;
        opacity: 1;
      }
    }

    &_current_version {
      margin-bottom: 0px;
      font-size: 14px;
      opacity: 0.7;
    }

    &_name {
      overflow: hidden;
      white-space: nowrap;
      max-width: 100%;
      text-overflow: ellipsis;
    }

    &_date,
    &_current_versions {
      overflow: hidden;
      white-space: nowrap;
      max-width: calc(100% - 5px);
      text-overflow: ellipsis;
    }

    * {
      transition: all 0.3s ease-in-out;
    }

    span {
      cursor: pointer;
    }


  }

  &:global(.active) {
    color: #1D5EFF !important;

    * {
      opacity: 1;
    } 
  }

  &_dropdown {
    opacity: 0 !important;
    transition: opacity 0.3s ease-in-out;

    :global {
      .dropdown-item{
        &:hover{
          background-color: $primary-color;
          color: white!important;
          transition: none;
        }
      }
      .dropdown-toggle {
        padding-left: 0px;
        padding-right: 0px;
        border: none;
        min-width: 2px;
        background-color: transparent;
        width: 32px;
        height: 32px;
        padding: 0 !important;
        border-radius: 50px;

        svg {
          margin-top: -3px;
        }

        &::after {
          display: none;
        }

        &:focus,
        &:hover {
          background-color: rgba(196, 200, 210, 0.6);
        }
      }

      .dropdown-menu {
        background-color: $body-background;
      }
    }
  }

  &:hover {
    .HistoryItem_dropdown {
      opacity: 1 !important;
    }
  }

  .loader {
    margin-left: 20px;
  }
}

.btn_show {
  display: none;
}

.close_btn {
  width: 28px;
  height: 28px;
  border-radius: 50px;
  text-align: center;

  &:focus,
  &:hover {
    background-color: rgba(196, 200, 210, 0.6);
  }
}

.version_status {
  color: #2db75f !important;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px
}