
.loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    border: 4px solid #D3D5DB;
    border-radius: 10px;
    figure {
        position: absolute;
        margin-bottom: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(2);
        .logo_dots {
        img {
            position: absolute;
            top: -11px;
            left: 1px;
            animation: loaderAnim 1s ease infinite forwards;
            animation-delay: 3600ms;
            &:nth-child(1) {
            animation-delay: 0ms;
            }
            &:nth-child(2) {
            top: -3px;
            left: 17px;
            animation-delay: 600ms;
            }
            &:nth-child(3) {
            top: 5px;
            left: 32px;
            animation-delay: 1200ms;
            }
        }
        }
    }
}

body {
    &:not(:global(.homepage)) {
        .loader {
            border: 11px solid #fff;
            border-radius: 20px;
        }
    }
}

@keyframes loaderAnim {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0);
    }
}