@import "../../styles/uitilities.scss";


$brand-success: #396f3a;
$brand-danger: #c92a2a;
$brand-loading: #228ae6;
$loader-size: 8em;
$check-height: $loader-size/2;
$check-width: $check-height/2;
$check-left: ($loader-size/6 + $loader-size/12);
$check-thickness: 2px;
$check-color: $brand-success;

.circle_loader {
  margin: 0 0 30px 10px;
  border: $check-thickness solid rgba(0, 0, 0, 0.2);
  border-left-color: $brand-loading;
  animation-name: loader-spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
}

.circle_loader,
.circle_loader:after {
  border-radius: 50%;
  width: $loader-size;
  height: $loader-size;
}

.circle_loader_checkmark {
  display: none;
  &_draw{
    display: block;
  }
  &_draw:after {
    animation-duration: 1.2s;
    animation-timing-function: ease;
    animation-name: checkmark;
    transform: scaleX(-1) rotate(135deg);
  }
  
  &:after {
    opacity: 1;
    height: $check-height;
    width: $check-width;
    transform-origin: left top;
    border-right: $check-thickness solid $check-color;
    border-top: $check-thickness solid $check-color;
    content: '';
    left: $check-left;
    top: $check-height;
    position: absolute;
  }
}

.load_failure {
  
  &:before,
  &:after {
    display: block;
    content: "";
    height: 2px;
    background: $brand-danger;
    width: 50%;
    position: absolute;
    top: 4em;
    left: 2em;
  }
  
  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}
.load_failure,
.load_success {
  -webkit-animation: none;
  animation: none;
  transition: border 500ms ease-out;
  
  
}

.load_success {
  border-color: $brand-success;
}

.load_failure {
  border-color: $brand-danger;
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: $check-width;
    opacity: 1;
  }
  40% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
  100% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
}
