.tellusform {
  margin-left: 440px;
  width: 522px;
  height: 712px;
  background-color: white;
  border-radius: 10px;
  .formcontent {
    padding: 48px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    .skipperson {
      display: flex;
      justify-content: center;
      text-decoration: underline;
    }
    label {
      font-size: 14px;
    }
    input {
      width: 100%;
      padding: 5px;
      border-radius: 10px;
      opacity: 1;
      font-size: 16px;
      color: #000000;
      border-color: #d3d5db;
    }
    form {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    button {
      width: 100%;
    }
  }
}
