@import "../../../../../styles/uitilities.scss";

.delete_title {
    font-size: size(24);
    font-weight: 700;
    margin-bottom: 10px;
    color: $text-color;
  }
  .delete_content {
    font-size: size(16);
    font-weight: 400;
  }

  .delete_btn_wrap {
    display: flex;
    margin-top: 25px;
    justify-content: center;
    button {
      width: 100%;
      max-width: 185px;
      border: none;
      border-radius: 6px;
      font-weight: 500;
      font-size: size(15);
      padding: 11px 13px;
      margin: 0 7px;
      transition: background-color 0.3s ease-in;
    }
    :global(.btn-outline-gray) {
      background-color: transparent;
      border: 1px solid $input-border;
      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
    :global(.btn-danger) {
      color: white;
      background-color: $delete-red;
      &:hover {
        background-color: $orange-red-alt;
      }
    }
  }

  .delete_this_photo_modal {
    color: $text-color;
    :global(.modal-dialog) {
      max-width: 474px;
    }
    :global(.modal-content) {
      padding: 50px 45px;
      text-align: center;
    }
    :global(.modal-header) {
      height: auto;
    }
    :global {
      .custom-checkbox {
        align-items: flex-start;
        span {
          font-size: size(14);
          line-height: 19px;
          color: $text-color-alt;
          opacity: 0.49;
          padding-left: 3px;
        }
        .checkmark {
          width: 16px;
          height: 16px;
          flex: 0 0 16px;
          margin-top: 2px;
          border-radius: 2px;
          background-color: $modal-bg;
          &::after {
            position: absolute;
            top: 3px;
            left: 2px;
            border-radius: 0;
            width: 10px;
            height: 5px;
            background: transparent;
            border: 1px solid transparent;
            border-left: 1px solid #fc4343;
            border-bottom: 1px solid #fc4343;
            transform: rotate(-45deg);
          }
        }
        input {
          &:checked {
            + .checkmark {
              border-radius: 2px;
              border: 1px solid #fc4343;
            }
          }
        }
      }
    }
    .delete_btn_wrap {
      display: flex;
      margin-top: 25px;
      justify-content: center;
      button {
        width: 100%;
        max-width: 185px;
        border: none;
        border-radius: 6px;
        font-weight: 500;
        font-size: size(15);
        padding: 11px 13px;
        margin: 0 7px;
        transition: background-color 0.3s ease-in;
      }
      :global(.btn-outline-gray) {
        background-color: transparent;
        border: 1px solid $input-border;
        &:hover {
          background-color: rgba(0, 0, 0, 0.05);
        }
      }
      :global(.btn-danger) {
        color: white;
        background-color: $delete-red;
        &:hover {
          background-color: $orange-red-alt;
        }
      }
    }
    .delete_title {
      font-size: size(24);
      font-weight: 700;
      margin-bottom: 10px;
      color: $text-color;
    }
    .delete_content {
      font-size: size(16);
      font-weight: 400;
    }
    :global(.btn-close) {
      top: 18px !important;
      right: 19px !important;
      font-size: 15px !important;
    }
  }