@import "../../../styles/uitilities.scss";

.slideshow {
  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 22px 55px 12px;
    position: sticky;
    border-bottom: 1px solid $dashboard-header-border;
    top: 83px;
    z-index: 2;
    background-color: $dashboard-bg;
    @include max(1199.98) {
      padding: 12px 16px;
    }

    :global(.btn) {
      min-width: inherit;
      height: 35px;
      font-size: size(17);
      padding: 8px 25px;
      @include max(575.98) {
        font-size: size(15);
        padding: 6px 18px;
      }
    }

    .back_button {
      border: none;
      background-color: transparent;
      font-size: size(17);
      font-weight: $font-regular;
      display: flex;
      align-items: center;
      color: $text-color-alt;
      &:hover {
        svg {
          transform: translateX(-5px);
        }
      }
      
      svg {
        margin-right: 10px;
        transition: 0.3s ease transform;
        path {
          fill: $text-color-alt;
        }
      }
    }

    .slideshow_name {
      font-size: size(18);
      font-weight: $font-medium;
      color: $text-color-alt;
      display: flex;
      justify-content: center;
      align-items: center;

      @include min(767.98) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 200px;
      }
      .slideshow_rename_btn {
        background-color: transparent;
        border: none;
        margin-left: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
      }
      input {
        border-color: transparent;
        background: inherit;
        text-align: left;
        max-width: 151px;
        margin-bottom: 2px;
        @include max(767.98) {
          width: 100%;
        }
      }
      svg {
        flex: 0 0 30px;
        max-width: 30px;
        path {
          fill: $text-color-alt;
        }
      }
    }

    .slideshow_actions {
      display: flex;

      .slideshow_save {
        margin-right: 10px;
      }
    }
  }

  &_display {
    max-width: 1100px;
    margin: 0 auto;
    margin-top: 15px;
    @include min(1440) {
      max-width: 1084px;
      padding: 0 16px;
    }
    @include min(1280) {
      padding: 0 30px;
    }
    @include max(1279.98) {
      max-width: 80vw;
    }
    @include max(767.98) {
      margin-top: 40px;
    }
    img {
      transform-origin: center;
      will-change: transform;
    }
    :global(.fade-effect) {
      img {
        animation: fade 1s ease 0s 1 normal forwards;
      }
    }

    :global(.zoom-in) {
      img {
        animation: zoom-in 0.74s ease 0s 1 normal forwards;
      }
    }

    :global(.zoom-out) {
      img {
        opacity: 1;
        animation: zoom-out 6s linear 0s 1 normal forwards;
      }
    }

    :global(.swirl) {
      img {
        animation: swirl 0.74s ease 0s 1 normal forwards;
      }
    }

    :global(.slide-in) {
      img {
        animation: slide-in 0.74s ease 0s 1 normal forwards;
      }
    }

    :global(.swing) {
      img {
        animation: swing 0.74s ease 0s 1 normal forwards;
      }
    }

    :global(.stretch) {
      img {
        animation: stretch 0.74s ease 0s 1 normal forwards;
      }
    }

    :global(.blink) {
      img {
        animation: blink 0.74s ease 0s 1 normal forwards;
      }
    }

    :global(.blur) {
      img {
        animation: blur 1.1s ease 0s 1 normal forwards;
      }
    }

    :global(.non) {
      img {
        transition: 0.8s ease all;
      }
    }

    :global(.mySwiper2 .swiper-wrapper) {
      transition-duration: 0.01s !important;
    }
  }

  &_image {
    position: relative;
    overflow: hidden;
    margin-bottom: 0;
    background: $black;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      object-fit: contain;
      max-width: 100%;
      max-height: 100%;
    }

    &::after {
      content: "";
      display: block;
      height: 0;
      width: 100%;
      padding-bottom: calc(621 / 1084 * 100%);
    }
  }

  @include max(767.98) {
    &_duration {
      order: 1;
      text-align: left;
    }

    &_options {
      order: 3;
      flex: 0 0 100%;
      max-width: 343px;
      margin-top: 37px;
      margin-left: auto;
      margin-right: auto;
    }

    &_slides_count {
      order: 2;
      text-align: right;
    }
  }

  &_duration,
  &_slides_count {
    font-size: size(14);
    font-weight: $font-regular;
    color: $text-color-alt;

    @include max(767.98) {
      flex: 0 0 50%;
    }
  }

  &_controls {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 15px 0;

    @include max(767.98) {
      flex-wrap: wrap;
    }
  }

  &_options {
    background: #d3d5db50;
    border: 1px solid #d3d5db;
    border-radius: 20px;
    padding: 11px 26px;

    @include max(767.98) {
      padding: 9px 21px;
      border-radius: 13px;
    }
    :global(.dropdown-toggle) {
      > div {
        display: flex;
        align-items: center;
      }
    }
    :global(.dropdown-menu) {
      background-color: $white;
      border-radius: 10px;
      padding: 13px;
      position: relative;
      &::before {
        content: "";
        background: $white;
        position: absolute;
        left: 20px;
        width: 14px;
        height: 14px;
      }
      &[x-placement="bottom-start"] {
        transform: translate(-20px, 48px) !important;
        &::before {
          top: 0;
          transform: translateY(-50%) rotate(135deg);
          border: 1px solid transparent;
          border-radius: 0 0 0 5px;
          border-bottom: 1px solid $border-color;
          border-left: 1px solid $border-color;
        }
      }
      &[x-placement="top-start"] {
        transform: translate(-20px, -48px) !important;
        &::before {
          bottom: 0;
          transform: translateY(50%) rotate(135deg);
          border: 1px solid transparent;
          border-radius: 0 5px 0 0;
          border-top: 1px solid $border-color;
          border-right: 1px solid $border-color;
        }
      }
    }
    &_list {
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      @include max(767.98) {
        justify-content: space-around;
      }

      li {
        padding: 0 10px;
        position: relative;
        :global {
          .dropdown {
            max-width: 18px;
          }
        }
        svg {
          cursor: pointer;
        }

        > div {
          display: flex;
          align-items: center;
          span {
            display: flex;
            align-items: center;
          }
          &:not(.slideshow_options_play) {
            &::after {
              content: "";
              width: 36px;
              height: 36px;
              background-color: #cfd0d1;
              border-radius: 50%;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%) scale(0);
              z-index: -1;
              transition: 0.3s ease transform;
            }
            &:hover {
              &::after {
                transform: translate(-50%, -50%) scale(1);
              }
            }
            svg {
              path {
                fill: $text-color-alt;
              }
            }
            :global {
              .scroll-dropdown {
                svg {
                  path {
                    fill: $black;
                  }
                }
              }
            }
          }
        }
      }
    }

    &_filter {
      :global(.dropdown-menu a) {
        &:active {
          background-color: #e9ecef;
          color: $black;
        }
      }
      .filter_icon {
        :global(svg) {
          fill: #2c2c34;
          transform: rotate(270deg);
          transform-origin: center;
        }
      }

      .filter_dropdown {
        min-width: inherit;
        padding: 0;
        background-color: transparent;
        border: none;

        &::after {
          display: none;
        }
      }
    }

    &_music {
      svg {
        transform: rotate(180deg);
        transform-origin: center;
      }

      :global(.dropdown-toggle) {
        min-width: inherit;
        padding: 0;
        background-color: transparent;
        border: none;
        &::after {
          display: none;
        }
      }

      :global(.dropdown-menu a) {
        position: relative;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        span {
          display: none;
        }

        &:hover span {
          display: block;
        }

        &:hover img {
          display: none;
        }
        &:active {
          background-color: #e9ecef;
          color: $black;
        }

        svg {
          transform: rotate(1deg);
        }
      }
    }

    &_play {
      :global(svg) {
        fill: $btn-secondary;
        width: 44px;
        height: 44px;
        transition: 0.3s ease transform;
        will-change: transform;
        &:hover {
          transform: scale(1.15);
        }
        @include max(767.98) {
          width: 40px;
          height: 40px;
        }
      }
    }

    &_fullscreen {
      svg {
        path {
          fill: $text-color;
        }
      }
    }

    &_slide_duration {
      :global(.dropdown-toggle) {
        min-width: inherit;
        padding: 0;
        background-color: transparent;
        border: none;
        &::after {
          display: none;
        }
      }
      .duration_inputs {
        display: flex;
        align-items: center;
        margin-top: 5px;
        margin-bottom: 10px;
        .duration_input {
          margin: 0 8px;
          flex: 1;
          width: 100%;
          height: 32px;
          border-radius: 16px;
          border: 1px solid $black;
          padding: 0 18px;
          font-size: size(14);
          font-weight: $font-regular;
          color: $black;
          input {
            border: none;
            background-color: transparent;
            height: 100%;
          }
        }
      }
      .duration_modifier_btn {
        font-size: size(28);
        width: 32px;
        height: 32px;
        flex: 0 0 32px;
        background-color: transparent;
        border: 1px solid $black;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        &:hover {
          color: $white;
          background-color: $btn-primary-hover;
          border: 1px solid $btn-primary-hover;
          svg {
            path {
              fill: $white !important;
            }
          }
        }
      }
    }
  }

  &_slides_nav {
    border-top: 1px solid $dashboard-header-border;
    padding: 18px 0;
    width: calc(100vw - 307px);
    @include max(1439.98) {
      width: calc(100vw - 250px);
    }
    @include max(1279.98) {
      width: 100vw;
    }
    @include max(575.98) {
      padding-left: 16px;
      padding-right: 16px;
    }
    &_wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-right: 31px;
      @include max(575.98) {
        margin-right: 0;
      }
      :global(.swiper-slide-thumb-active) {
        .slides_nav_image {
          border: 4px solid #23cc2b;
        }
      }
    }
  }

  &_nav_swiper {
    flex: 0 0 90%;
    margin-right: 30px;
    padding: 20px 0;
    position: relative;
    @include max(575.98) {
      margin-right: 0;
      flex: 0 0 87%;
    }
    &::after {
      content: "";
      width: 50px;
      height: 100%;
      background: linear-gradient(to left, $dashboard-bg, transparent);
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
      pointer-events: none;
    }

    :global(.swiper-slide) {
      width: 84px;
      height: 84px;
      @include max(575.98) {
        width: 54px;
        height: 54px;
      }
    }

    .delete_img {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: $white;
      border: 1px solid #d7dae4;
      &:hover {
        svg {
          animation: trashAnim 1s linear infinite forwards;
        }
      }
    }

    .slides_nav_image {
      position: relative;
      overflow: hidden;
      border-radius: 50%;
      margin-bottom: 0;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        max-width: 100%;
        max-height: 100%;
      }

      &::after {
        content: "";
        display: block;
        height: 0;
        width: 100%;
        padding-bottom: 100%;
      }
    }
  }

  &_add_file {
    flex: 0 0 36px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    border: 2px solid $text-color;
    cursor: pointer;
    transition: 0.3s ease all;
    @include max(575.98) {
      margin-left: 10px;
    }
    &:hover {
      transform: rotate(90deg);
      transform-origin: center;
    }

    label {
      padding: 0 !important;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    svg {
      transform: rotate(45deg);
      transform-origin: center;

      path {
        fill: $text-color-alt;
      }
    }
  }

  @keyframes fade {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes zoom-out {
    0% {
      transform: scale(1.5, 1.5);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  @keyframes zoom-in {
    0% {
      transform: scale(0.5, 0.5);
    }
    100% {
      transform: scale(1, 1);
    }
  }
}

@keyframes swirl {
  0% {
    opacity: 0;
    transform: rotate(-540deg) scale(0);
  }

  100% {
    opacity: 1;
    transform: rotate(0) scale(1);
  }
}

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateX(-250px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes swing {
  0% {
    opacity: 0;
    transform: rotateX(-100deg);
    transform-origin: top;
  }

  100% {
    opacity: 1;
    transform: rotateX(0deg);
    transform-origin: top;
  }
}

@keyframes stretch {
  0% {
    transform: scaleX(0.4);
  }

  100% {
    transform: scaleX(1);
  }
}

@keyframes blink {
  0%,
  50%,
  100% {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

@keyframes blur {
  0% {
    opacity: 0;
    filter: blur(10px);
  }
  10% {
    opacity: 1;
  }
  100% {
    filter: blur(0px);
  }
}

.playerProgress {
  font-size: 10px;
  display: flex;
  align-items: center;

  p {
    margin: 0px;
  }
}

.listItems {
  height: 100px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  a {
    transition: 0.3s ease background-color;
    &:hover {
      background-color: #e9ecef !important;
    }
    &:focus {
      background-color: transparent;
    }
  }
  &_active {
    background-color: #e9ecef !important;
  }
}

.playerOptions {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5px;

  svg {
    transform: rotate(1deg);
    margin-bottom: 5px;
  }
}

.playerControls {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 50px;
  border-top: 1px solid #bfbfc3;

  .audioName {
    font-size: 12px;
    margin: 0px;
  }
}

.musicVisualizer {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.progress {
  cursor: pointer;
}

.noDataSection {
  position: relative;
  overflow: hidden;
  margin-bottom: 0;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
  }

  &::after {
    content: "";
    display: block;
    height: 0;
    width: 100%;
    padding-bottom: calc(621 / 1084 * 100%);
  }
}

@keyframes trashAnim {
  0% {
    transform: rotate(0);
  }
  7.69% {
    transform: rotate(20deg);
  }
  15.38% {
    transform: rotate(0);
  }
  23.07% {
    transform: rotate(-20deg);
  }
  30.76% {
    transform: rotate(0);
  }
  38.45% {
    transform: rotate(20deg);
  }
  46.14% {
    transform: rotate(0);
  }
  53.83% {
    transform: rotate(-20deg);
  }
  61.52% {
    transform: rotate(0);
  }
  69.21% {
    transform: rotate(0);
  }
  76.9% {
    transform: rotate(0);
  }
  84.59% {
    transform: rotate(0);
  }
  92.28% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}

.delete_this_photo_modal {
  color: $text-color;
  :global(.modal-dialog) {
    max-width: 474px;
  }
  :global(.modal-content) {
    padding: 50px 45px;
    text-align: center;
    @include max(575.98) {
      padding: 22px;
    }
  }
  :global(.modal-header) {
    height: auto;
  }
  .delete_btn_wrap {
    display: flex;
    margin-top: 25px;
    @include max(575.98) {
      justify-content: center;
      margin-top: 18px;
    }
    button {
      width: 100%;
      max-width: 185px;
      border: none;
      border-radius: 6px;
      font-weight: 500;
      font-size: size(17);
      padding: 13px;
      margin: 0 7px;
      transition: background-color 0.3s ease-in;
      @include max(575.98) {
        height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 4px;
        font-size: size(14);
        max-width: 108px;
      }
    }
    :global(.btn-outline-gray) {
      background-color: transparent;
      border: 1px solid $input-border;
      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
    :global(.btn-danger) {
      color: white;
      background-color: $delete-red;
      &:hover {
        background-color: $orange-red-alt;
      }
    }
  }
  .delete_title {
    font-size: size(24);
    font-weight: 700;
    margin-bottom: 10px;
    color: $text-color;
    @include max(575.98) {
      font-size: size(20);
    }
  }
  .delete_content {
    font-size: size(16);
    font-weight: 400;
    @include max(575.98) {
      font-size: size(14);
    }
  }
  :global(.btn-close) {
    top: 18px !important;
    right: 19px !important;
    font-size: 15px !important;
  }
}

.slideshow_options_slide_duration {
  &:global(.dropdown-lg .dropdown-menu) {
    min-width: 200px;
  }
  &:global(.dropdown-lg) {
    width: 15px !important;
  }
}
