@import "../../../../styles/uitilities.scss";

.drop_container {
  padding: 0px 10px;
  margin-bottom: 20px;
  font-size: 0;

  .gallery_image_wrap {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    background-color: $input-border;
    margin-bottom: 20px;
    width: 100%;
    background-color: white;
    min-height: 60px;

    @include max(575.98) {
      margin-bottom: 12px;
    }

    &.no_tray {
      img {
        transition: 0.3s ease-out transform;
        will-change: transform;
      }

      &:hover {
        img {
          transform: scale(1.05);
        }
      }
    }

    :global(.progress) {
      position: absolute;
      bottom: 23px;
      left: 50%;
      transform: translateX(-50%);
      max-width: 238px;
      width: 100%;
      height: 8px;
      margin: 0 auto;
      background-color: #fff;
      border-radius: 10px;
      padding: 1px;
    }

    :global(.progress-bar) {
      background-color: #23cc2b;
      border-radius: 10px;
    }

    &.image_error {
      img {
        box-shadow: inset 0 0 0 1px $error-message;
        // border-radius: 21px;
      }

      :global(.progress-bar) {
        background-color: $error-message;
      }
    }
  }

  .onHover_figure {
    .gallery_image_tray {
      border-radius: 0;
      opacity: 1;
      visibility: visible;

      @include max(1279.98) {
        opacity: 0;
      }
    }
  }

  .uploadError {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;

    span {
      display: block;
    }

    .errorButton {
      background: grey;
      margin: 0 auto;
      display: inline-block;
      margin-top: 150px;
      border-radius: 3px;
      padding: 4px;
    }
  }

  .shimmer-styles {
    border-radius: 20px;
    width: 100%;
    background: $shimmer-bg-alt;
    background-image: $shimmer-gradient-alt;
    background-repeat: no-repeat;
    background-size: 800px 100%;
    display: inline-block;
    position: relative;

    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: placeholderShimmer;
    -webkit-animation-timing-function: linear;

    @include max(991.98) {
      border-radius: 11px;
    }
  }

  :global(.img-loading) {
    @extend .shimmer-styles;
    height: 300px;
    min-height: 300px;
    max-height: 300px;

    img {
      opacity: 0;
    }
  }

  :global(.img-loaded) {
    // @extend .shimmer-styles;
    background-image: none !important;
    min-height: 1px !important;
    max-height: 800px !important;

    img {
      opacity: 1 !important;
    }
  }

  :global(.img-loading .image_tray) {
    display: none !important;
  }

  .gallery_image_cont {
    position: relative;
    width: 100%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    min-height: 190px;

    @include max(575.98) {
      padding: 0 6px;
    }

    .gallery_image_wrap {
      // transition: 1s ease min-height, 1s ease max-height;
      overflow: hidden !important;
      // background-color: white;
      border-radius: 14px;
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);

      img {
        transition-delay: 0.2s;
        opacity: 0;
        transition: 0.3s opacity ease-in-out;
        margin: -5px;
        width: calc(100% + 10px) !important;
        height: calc(100% + 10px) !important;
      }

      &:global(.img-loaded) {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        margin-bottom: 0;

        // &:before{
        //   content: "";
        //   width: 100%;
        //   height: 100%;
        //   background-color: white;
        //   position: absolute;
        //   top: 0;
        //   left: 0;
        //   right: 0;
        //   bottom: 0;
        // }
        img {
          width: calc(100%);
          height: calc(100%);
          // margin: 6px;
          // position: absolute;
          left: 0;
          top: 0;
          object-fit: cover;
          opacity: 1;
          transition: 0.3s ease-in-out;
        }
      }
      &:hover {
        img {
          transform: scale(1.012);
        }
      }
    }

    img {
      width: 100%;
      height: 100%;
      // -webkit-border-radius: 20px;
      // -khtml-border-radius: 20px;
      // -moz-border-radius: 20px;
      // border-radius: 20px;
      overflow: hidden;

      @include max(991.98) {
        -webkit-border-radius: 6px;
        -khtml-border-radius: 6px;
        -moz-border-radius: 6px;
        border-radius: 6px;
      }

      &:global(.onHover) {
        + .gallery_image_tray {
          opacity: 1;
        }
      }
    }

    .delete_img {
      position: absolute;
      right: 25px;
      top: 15px;
      width: 30px;
      height: 30px;
      border: 1px solid #d7dae4;
      border-radius: 50%;
      background-color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $black;
      font-size: 35px;
      font-weight: 400;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        animation: trashAnim 1s linear infinite forwards;
      }

      span {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &_edit {
      img {
        object-fit: cover;
        cursor: move;
      }
    }

    &.favorite_active {
      .favorite_btn {
        svg {
          path {
            &:first-child {
              fill: red;
            }

            &:last-child {
              fill: red;
            }
          }
        }
      }
    }
  }

  .DocImage {
    height: 200px !important;
    img {
      object-position: top;
    }
  }

  :global(.favorite-active .favorite-btn) {
    svg {
      animation: box-shadow-active 0.5s ease-in-out !important;
      opacity: 1 !important;
    }
  }

  :global(.favorite-btn) {
    svg {
      border-radius: 100px;
      height: 14px;
      width: 14px;
    }
  }

  .gallery_select_tick {
    position: absolute;
    bottom: 15px;
    right: 15px;
    z-index: 5;
    width: 25px;
    height: 25px;
    margin-bottom: 0;
    border-radius: 50%;
    border: 2px solid #fff;
    background: #1d5eff;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;

    svg {
      width: 13px;
      height: 13px;
      margin-top: 1px;
    }
  }

  :global(.selection) {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    z-index: 10;
    bottom: 15px;
    right: 15px;
    border: 2px solid #bbafaf;
    box-shadow: 0 0 100vmin 100vmin #00000062;
    pointer-events: none;
  }

  &:not(.album_contianer) {
    .gallery_image_wrap {
      border-image-source: none !important;
      border: none !important;
    }
  }
}

.gallery_image {
  &_tray {
    -webkit-border-radius: 20px;
    -khtml-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 71px 12px 17px 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    background-image: linear-gradient(
      180deg,
      #00000057 0,
      rgba(0, 0, 0, 0.338) 3.5%,
      rgba(0, 0, 0, 0.324) 7%,
      rgba(0, 0, 0, 0.306) 10.35%,
      rgba(0, 0, 0, 0.285) 13.85%,
      rgba(0, 0, 0, 0.262) 17.35%,
      rgba(0, 0, 0, 0.237) 20.85%,
      rgba(0, 0, 0, 0.213) 24.35%,
      rgba(0, 0, 0, 0.188) 27.85%,
      rgba(0, 0, 0, 0.165) 31.35%,
      rgba(0, 0, 0, 0.144) 34.85%,
      rgba(0, 0, 0, 0.126) 38.35%,
      rgba(0, 0, 0, 0.112) 41.85%,
      rgba(0, 0, 0, 0.103) 45.35%,
      #0000001a 48.85%,
      rgba(0, 0, 0, 0.103) 52.35%,
      rgba(0, 0, 0, 0.112) 55.85%,
      rgba(0, 0, 0, 0.126) 59.35%,
      rgba(0, 0, 0, 0.144) 62.85%,
      rgba(0, 0, 0, 0.165) 66.35%,
      rgba(0, 0, 0, 0.188) 69.85%,
      rgba(0, 0, 0, 0.213) 73.35%,
      rgba(0, 0, 0, 0.237) 76.85%,
      rgba(0, 0, 0, 0.262) 80.35%,
      rgba(0, 0, 0, 0.285) 83.85%,
      rgba(0, 0, 0, 0.306) 87.35%,
      rgba(0, 0, 0, 0.324) 90.85%,
      rgba(0, 0, 0, 0.338) 94.35%,
      rgba(0, 0, 0, 0.347) 97.85%,
      #00000059
    );
    height: 100%;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: 0.2s ease-in-out opacity;

    @media only screen and (max-width: 1366px) and (hover: none) {
      opacity: 1;
      visibility: visible;
      background-image: none !important;

      button {
        border: 1px solid rgba(0, 0, 0, 0.1);
      }
    }

    @include max(991.98) {
      -webkit-border-radius: 11px;
      -khtml-border-radius: 11px;
      -moz-border-radius: 11px;
      border-radius: 11px;
    }

    &_author {
      font-size: size(16);
      font-weight: $font-medium;
      line-height: 1;
      margin-bottom: 6px;
      color: #fff;
    }

    &_post_date {
      font-size: size(13);
      font-weight: $font-regular;
      line-height: 1;
      color: #fff;
      display: flex;
      flex-direction: column;

      :global(.post-date-label) {
        padding-right: 5px;
      }

      span {
        width: max-content !important;
      }
    }

    .tray_actions {
      display: flex;
      align-items: center;

      &_btn {
        border: none;
        background: #fff;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        margin: 0 3.5px;
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: all;
        position: relative;
        h6 {
          display: block !important;
          position: absolute;
          font-size: 14px;
          top: -28px;
          color: #fff;
          right: 0;
          background-color: #ffffff42;
          padding: 4px 12px;
          border-radius: 16px;
          backdrop-filter: blur(5px);
          -webkit-backdrop-filter: blur(5px);
          transform: translateY(8px);
          opacity: 0;
          transition: 0.2s ease-in;
        }
        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }

        svg {
          transition: 0.2s ease all;
        }

        &:hover {
          svg {
            opacity: 0.5;
          }
          h6 {
            transform: translateY(0);
            opacity: 1;
          }
        }

        &.favorite_btn {
          svg {
            cursor: pointer;
            overflow: visible;
            width: 32px;

            :global(#heart) {
              transform-origin: center;
              animation: animateHeartOut 0.3s linear forwards;
            }

            :global(#main-circ) {
              transform-origin: 29.5px 29.5px;
            }
          }
        }
      }

      .favorite_btn {
        svg {
          path {
            fill: $black;

            &:nth-of-type(2) {
              fill: $white;
              transform: scale(0.87) !important;
              transform-origin: center;
            }

            &:last-child {
              fill: $black;
            }
          }
        }
      }
    }
  }
}

@keyframes box-shadow-active {
  0% {
    box-shadow: 0 0 0px 0px white;
  }

  25% {
    box-shadow: 0 0 0px 15px white;
  }

  100% {
    box-shadow: 0 0 0px 0px white;
  }
}

.tray_heart_btn {
  svg {
    path {
      fill: currentColor;
      display: none;

      &:nth-child(2) {
        display: block;
      }
    }
  }

  &.heart_checked {
    svg {
      path {
        display: block;
      }
    }

    color: red;
  }
}

.tray_heart_btn,
.tray_actions_trash_btn,
.tray_actions_dwd_btn {
  overflow: hidden;
  position: relative;

  svg {
    path {
      fill: currentColor;
    }
  }
}

.tray_actions_dwd_btn {
  &:hover {
    color: $green-alt !important;

    svg {
      opacity: 1 !important;

      path {
        color: currentColor !important;
      }
    }
  }
}

.tray_heart_btn {
  &:hover {
    color: $orange-red-alt !important;

    svg {
      opacity: 1 !important;

      path {
        color: currentColor !important;
      }
    }
  }
}

.tray_actions_trash_btn {
  &:hover {
    color: $orange-red-alt !important;

    svg {
      opacity: 1 !important;

      path {
        color: $orange-red-alt !important;
      }
    }
  }
}

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.placeholder_image_active {
  background-color: $gallery-img-bg;
}

// favorite toggle animation
.favorite_active .favorite_btn svg {
  :global(#heart) {
    transform-origin: center;
    transform: scale(0.2);
    fill: #e2264d !important;
    stroke: #e2264d !important;
    animation: animateHeart 0.3s linear forwards 0.25s;
  }

  :global(#main-circ) {
    pointer-events: none;
    transition: all 2s;
    animation: animateCircle 0.3s linear forwards;
    opacity: 1;
  }

  :global(#grp1) {
    opacity: 1;
    transition: 0.1s all 0.3s;

    :global(#oval1) {
      transform: scale(0) translate(0, -30px);
      transform-origin: 0 0 0;
      transition: 0.5s transform 0.3s;
    }

    :global(#oval2) {
      transform: scale(0) translate(10px, -50px);
      transform-origin: 0 0 0;
      transition: 1.5s transform 0.3s;
    }
  }

  :global(#grp2) {
    opacity: 1;
    transition: 0.1s all 0.3s;

    :global(#oval1) {
      transform: scale(0) translate(30px, -15px);
      transform-origin: 0 0 0;
      transition: 0.5s transform 0.3s;
    }

    :global(#oval2) {
      transform: scale(0) translate(60px, -15px);
      transform-origin: 0 0 0;
      transition: 1.5s transform 0.3s;
    }
  }

  :global(#grp3) {
    opacity: 1;
    transition: 0.1s all 0.3s;

    :global(#oval1) {
      transform: scale(0) translate(30px, 0px);
      transform-origin: 0 0 0;
      transition: 0.5s transform 0.3s;
    }

    :global(#oval2) {
      transform: scale(0) translate(60px, 10px);
      transform-origin: 0 0 0;
      transition: 1.5s transform 0.3s;
    }
  }

  :global(#grp4) {
    opacity: 1;
    transition: 0.1s all 0.3s;

    :global(#oval1) {
      transform: scale(0) translate(30px, 15px);
      transform-origin: 0 0 0;
      transition: 0.5s transform 0.3s;
    }

    :global(#oval2) {
      transform: scale(0) translate(40px, 50px);
      transform-origin: 0 0 0;
      transition: 1.5s transform 0.3s;
    }
  }

  :global(#grp5) {
    opacity: 1;
    transition: 0.1s all 0.3s;

    :global(#oval1) {
      transform: scale(0) translate(-10px, 20px);
      transform-origin: 0 0 0;
      transition: 0.5s transform 0.3s;
    }

    :global(#oval2) {
      transform: scale(0) translate(-60px, 30px);
      transform-origin: 0 0 0;
      transition: 1.5s transform 0.3s;
    }
  }

  :global(#grp6) {
    opacity: 1;
    transition: 0.1s all 0.3s;

    :global(#oval1) {
      transform: scale(0) translate(-30px, 0px);
      transform-origin: 0 0 0;
      transition: 0.5s transform 0.3s;
    }

    :global(#oval2) {
      transform: scale(0) translate(-60px, -5px);
      transform-origin: 0 0 0;
      transition: 1.5s transform 0.3s;
    }
  }

  :global(#grp7) {
    opacity: 1;
    transition: 0.1s all 0.3s;

    :global(#oval1) {
      transform: scale(0) translate(-30px, -15px);
      transform-origin: 0 0 0;
      transition: 0.5s transform 0.3s;
    }

    :global(#oval2) {
      transform: scale(0) translate(-55px, -30px);
      transform-origin: 0 0 0;
      transition: 1.5s transform 0.3s;
    }
  }

  :global(#grp2) {
    opacity: 1;
    transition: 0.1s opacity 0.3s;
  }

  :global(#grp3) {
    opacity: 1;
    transition: 0.1s opacity 0.3s;
  }

  :global(#grp4) {
    opacity: 1;
    transition: 0.1s opacity 0.3s;
  }

  :global(#grp5) {
    opacity: 1;
    transition: 0.1s opacity 0.3s;
  }

  :global(#grp6) {
    opacity: 1;
    transition: 0.1s opacity 0.3s;
  }

  :global(#grp7) {
    opacity: 1;
    transition: 0.1s opacity 0.3s;
  }
}

@keyframes animateCircle {
  40% {
    transform: scale(10);
    opacity: 1;
    fill: #dd4688;
  }

  55% {
    transform: scale(11);
    opacity: 1;
    fill: #d46abf;
  }

  65% {
    transform: scale(12);
    opacity: 1;
    fill: #cc8ef5;
  }

  75% {
    transform: scale(13);
    opacity: 1;
    fill: transparent;
    stroke: #cc8ef5;
    stroke-width: 0.5;
  }

  85% {
    transform: scale(17);
    opacity: 1;
    fill: transparent;
    stroke: #cc8ef5;
    stroke-width: 0.2;
  }

  95% {
    transform: scale(18);
    opacity: 1;
    fill: transparent;
    stroke: #cc8ef5;
    stroke-width: 0.1;
  }

  100% {
    transform: scale(19);
    opacity: 1;
    fill: transparent;
    stroke: #cc8ef5;
    stroke-width: 0;
  }
}

@keyframes animateHeart {
  0% {
    transform: scale(0.2);
  }

  40% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes animateHeartOut {
  0% {
    transform: scale(1.4);
  }

  100% {
    transform: scale(1);
  }
}

.frameFit {
  width: inherit;
  height: inherit;
  border-width: 20px;
  border-radius: 12px;
  border-style: inset;
  display: block;
  margin: 0 auto;
  display: grid;
  place-items: center;
  transition: all 0.4s;
  // border-image-slice: 13%;
  border-image-slice: 25;
  border-image-repeat: round;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
}

.delete_this_photo_modal {
  position: absolute;
  color: $text-color;

  :global(.modal-dialog) {
    max-width: 474px;
  }

  :global(.modal-content) {
    padding: 50px 45px;
    text-align: center;
  }

  :global(.modal-header) {
    height: auto;
  }

  .delete_btn_wrap {
    display: flex;
    margin-top: 25px;

    button {
      width: 100%;
      max-width: 185px;
      border: none;
      border-radius: 6px;
      font-weight: 500;
      font-size: size(17);
      padding: 13px;
      margin: 0 7px;
      transition: background-color 0.3s ease-in;
    }

    :global(.btn-outline-gray) {
      background-color: transparent;
      border: 1px solid $input-border;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }

    :global(.btn-danger) {
      color: white;
      background-color: $delete-red;

      &:hover {
        background-color: $orange-red-alt;
      }
    }
  }

  .delete_title {
    font-size: size(24);
    font-weight: 700;
    margin-bottom: 10px;
    color: $text-color;
  }

  .delete_content {
    font-size: size(16);
    font-weight: 400;
  }

  :global(.btn-close) {
    top: 18px !important;
    right: 19px !important;
    font-size: 15px !important;
  }
}

@keyframes trashAnim {
  0% {
    transform: rotate(0);
  }

  7.69% {
    transform: rotate(20deg);
  }

  15.38% {
    transform: rotate(0);
  }

  23.07% {
    transform: rotate(-20deg);
  }

  30.76% {
    transform: rotate(0);
  }

  38.45% {
    transform: rotate(20deg);
  }

  46.14% {
    transform: rotate(0);
  }

  53.83% {
    transform: rotate(-20deg);
  }

  61.52% {
    transform: rotate(0);
  }

  69.21% {
    transform: rotate(0);
  }

  76.9% {
    transform: rotate(0);
  }

  84.59% {
    transform: rotate(0);
  }

  92.28% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(0);
  }
}

.album_contianer {
  figure {
    background-color: white;
    border-radius: 0px !important;

    img {
      margin: 6px;
      width: calc(100% - 12px) !important;
      height: calc(100% - 12px) !important;
    }
  }
}

body {
  .gallery_image_wrap:not(.frameFit) {
    background-color: white;
  }

  &:global(.dark) {
    .gallery_image_wrap:not(.frameFit) {
      background-color: black;
    }
  }
}
