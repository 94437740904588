@import '../../styles/uitilities.scss';


.PaymentSuccess {
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__image {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 100%;
        }
    }

    &__title {
        font-size: size(28);
        font-weight: $font-semibold;
    }

    &__description {
        font-size: size(15);
        text-align: center;
        margin-bottom: 0;
    }
}

.btn_container {
    display: flex;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    &:hover {
        color: rgb(73, 131, 237);
        svg {
            color: inherit;
            transform: rotate(180deg) translateX(-5px);
        }
    }

    svg {
        margin-right: 10px;
        transform-origin: center;
        transform: rotate(180deg);
        margin-left: 6px;
        transition: 0.3s ease-in-out transform;
        will-change: transform;
        path {
            fill: currentColor;
        }
    }

    .btnText {
        font-size: 1.3em;
        font-weight: 700;
        margin: 0px;

    }


    .btnText:hover {
        color: rgb(73, 131, 237);
    }



}
