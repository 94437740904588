@import "../../styles/uitilities.scss";

.thankyoupopup{
    --bs-modal-width:444px;
}
.popup_warp{
    min-height: 340px;
    text-align: center ;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    

    img{
        margin-bottom: 21px;
        width: 90px;
        height: 90px;
    }

    h2{
        font-size: 30px;
        font-weight: 500;

        @include min(992){
            font-size: size(40);
        }
    }
}
.popup_content{
    font-size: 17px;
}