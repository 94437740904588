@import "../../styles/uitilities.scss";

.viewer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(33, 33, 36, 0.95);
  // height: 70rem;
  width: 100%;

  .pdf_header {
    padding-top: 20px;
    position: relative;
    z-index: 10;
    width: 100%;
    // display: flex;
    // justify-content: space-between;
    // left: 0;
    // opacity: 1;
    // transition: 0.3s ease 1;

    &_left {
      margin-left: 50px;
      display: flex;
      justify-content: space-between;

      svg {
        path {
          fill: currentColor;
        }
      }
    }

    &_left {
      .pdf_header_btn {
        background-color: transparent;
        border-color: transparent;
        font-weight: 400;
        font-size: size(17);
        text-decoration: none;
        color: #ffff;

        &:focus {
          border: none !important;
        }

        &:hover {
          color: $white;
          filter: brightness(0.6);
        }

        @include min(992) {
          padding: 3px 0;
        }

        &:focus {
          color: $primary-color;
        }

        svg {
          transform: translateY(-1px);
          margin-right: 12px;
        }
      }
    }
  }
}

.shareBtn {
  border-style: solid;
  border-color: #bbbbbb !important;

  background-color: transparent;
  border-color: transparent;
  font-weight: 400;
  font-size: size(17);
  text-decoration: none;

  &:focus {
    border: none !important;
  }

  &:hover {
    color: $white;
    filter: brightness(0.6);
  }

  @include min(992) {
    padding: 3px 0;
  }

  &:focus {
    color: $primary-color;
  }

  svg {
    transform: translateY(-1px);
    margin-right: 12px;
  }
}
.rpv-core__viewer > div {
  page-break-inside: avoid !important;
}
@media print {
  body {
      margin: 0;
      padding: 0;
  }
}
@media print {
  body {
      zoom: 0.6; /* Adjust scale as necessary */
  }
}
