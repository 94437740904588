@import "../../styles/uitilities.scss";

.my_gallery {
  margin: 0 auto;
  position: relative;
  height: 100%;
  padding-bottom: 44px;
  @include max(767.98) {
    width: 100%;
    padding: 0 16px;
  }
  &_header {
    position: sticky;
    top: 83px;
    z-index: 99;
    background: $dashboard-bg;
    margin-bottom: 26px;
    padding: 0 52px;
    @include min(1280) {
      padding-top: 25px;
    }
    @include min-max(1200,1499.9){
      padding: 25px 40px 0 40px;
    }
    @include min(1600) {
      padding-top: 44px;
    }
    @include max(1279.98) {
      top: 52px;
      padding-top: 15px;
    }
    @include max(575.98) {
      margin-bottom: 25px;
      padding: 0 16px;
      width: calc(100vw - 32px);
      padding: 0;
      padding-top: 15px;
    }
    + div {
      padding: 0 50px;
      margin: 0 !important;
      @include min-max(1200,1499.9){
        padding: 0 30px;
      }
      @include max(1279.98) {
        padding-top: 25px;
      }
      @include max(575.98) {
        padding: 0;
      }
      > div {
        margin-left: -10px;
        margin-right: -10px;
        width: inherit;
        @include max(575.98) {
          margin-left: -6px;
          margin-right: -6px;
        }
      }
    }
  }
  &_title {
    font-size: size(30);
    font-weight: $font-bold;
    line-height: 40px;
    color: $text-color-alt;
    margin-bottom: 0;
    @include max(767.98) {
      font-size: size(24);
      line-height: 28px;
      margin-bottom: 5px;
    }
  }
}
