@import "../../../styles/uitilities.scss";

.backtoPage {
  cursor: pointer;
  display: flex;
  align-items: center;

  p {
    font-size: size(17);
    font-weight: $font-regular;
    margin: 0;
    margin-left: 10px;
    color: $text-color-alt;
    
  }

  svg {
    path {
      fill: $text-color-alt;
    }
  }
}

.albumDetails {
  background: $album-details-tab;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 15px 20px;
  // height: 52px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 0 !important;
  margin-right: 0 !important;

  + :global(.masonry-wrap) {
    margin-top: 38px;

    @include max(575.98) {
      margin-top: 20px;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    height: 100%;

    h2 {
      display: flex;
      align-items: center;
      line-height: 1;
      font-size: size(22);
      font-weight: $font-medium;
      color: #fff;
      margin-bottom: 0;

      @include max(575.98) {
        font-size: size(18);
      }
    }

    p {
      font-size: size(14);
      font-weight: $font-regular;
      line-height: 22px;
      max-width: 1200px;
      margin-bottom: 0;
      color: #fff;
      padding-right: 60px;
      margin-top: 7px;
      small{
        cursor: pointer;
        font-size: 14px;
        color: $modal-link;
        margin-left: 6px;
      }
    }

    span {
      display: block;
      padding: 4px 8px;
      font-size: size(14);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
      // margin-left: 5px;
      // background-color: $blank-folder-outer;
      color: $text-color-alt;
    }
  }

  .album_share {
    padding: 12px 14px;
    min-width: inherit;
    flex: 0 0 140px;
    max-width: 140px;

    &:hover {
      background-color: $black;
      border-color: $black;
      color: $white;
      

    }
  }
}

.album_gallery {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  :global(.my-masonry-grid_column) {
    flex: 0 0 20%;
    max-width: 20%;
    width: 100% !important;

    @include max(1599.98) {
      flex: 0 0 25%;
      max-width: 25%;
    }

    @include max(991.98) {
      flex: 0 0 33.3333%;
      max-width: 33.3333%;
    }

    @include max(575.98) {
      flex: 0 0 50%;
      max-width: 50%;
    }

    @include max(424.98) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  :global(.img-loading) {
    border-radius: 0;
  }

  figure {
    border-radius: 0 !important;

    img {
      border-radius: 0 !important;
    }

    figure {
      border-radius: 50% !important;
    }
  }

  :global {
    .my-masonry-grid {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.delete_this_photo_modal {
  color: $text-color;

  :global(.modal-dialog) {
    max-width: 474px;
  }

  :global(.modal-content) {
    padding: 50px 45px;
    text-align: center;
  }

  :global(.modal-header) {
    height: auto;
  }

  :global {
    .custom-checkbox {
      align-items: flex-start;

      span {
        font-size: size(14);
        line-height: 19px;
        color: $text-color-alt;
        opacity: 0.49;
        padding-left: 3px;
      }

      .checkmark {
        width: 16px;
        height: 16px;
        flex: 0 0 16px;
        margin-top: 2px;
        border-radius: 2px;
        background-color: $modal-bg;

        &::after {
          position: absolute;
          top: 3px;
          left: 2px;
          border-radius: 0;
          width: 10px;
          height: 5px;
          background: transparent;
          border: 1px solid transparent;
          border-left: 1px solid #fc4343;
          border-bottom: 1px solid #fc4343;
          transform: rotate(-45deg);
        }
      }

      input {
        &:checked {
          +.checkmark {
            border-radius: 2px;
            border: 1px solid #fc4343;
          }
        }
      }
    }
  }

  .delete_btn_wrap {
    display: flex;
    margin-top: 25px;
    justify-content: center;

    button {
      width: 100%;
      max-width: 185px;
      border: none;
      border-radius: 6px;
      font-weight: 500;
      font-size: size(15);
      padding: 11px 13px;
      margin: 0 7px;
      transition: background-color 0.3s ease-in;
    }

    :global(.btn-outline-gray) {
      background-color: transparent;
      border: 1px solid $input-border;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }

    :global(.btn-danger) {
      color: white;
      background-color: $delete-red;

      &:hover {
        background-color: $orange-red-alt;
      }
    }
  }

  .delete_title {
    font-size: size(24);
    font-weight: 700;
    margin-bottom: 10px;
    color: $text-color;
  }

  .delete_content {
    font-size: size(16);
    font-weight: 400;
  }

  :global(.btn-close) {
    top: 18px !important;
    right: 19px !important;
    font-size: 15px !important;
    display: none;
  }
}

.albumFullviewBlank {
  padding: 50px 0;
}

.albumShare {
  color: $text-color-alt;
  //background-color: $blank-folder-outer;
  min-width: 95px;
  // color: var(--text-color-alt);
  background: #fff;
  color: #000;
  border:none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;
  transition: .3s ease-in;
  border-radius: 10px;
  overflow: hidden;
  span,svg{
    color: currentColor!important;
  }
  svg{
    min-width: 14px;
    path{
      fill: currentColor;
    }
  }
   
  &:hover{
    background: #000;
    color: #fff;
    // svg,span{

    //   -webkit-animation: wobble-ver-right 0.8s both;
    //         animation: wobble-ver-right 0.8s both;
    // }
  }
  span {
    margin-left: 10px;
    color: black;
  }
}

 @-webkit-keyframes wobble-ver-right {
  0%,
  100% {
    -webkit-transform: translateY(0) rotate(0);
            transform: translateY(0) rotate(0);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateY(-30px) rotate(6deg);
            transform: translateY(-30px) rotate(6deg);
  }
  30% {
    -webkit-transform: translateY(15px) rotate(-6deg);
            transform: translateY(15px) rotate(-6deg);
  }
  45% {
    -webkit-transform: translateY(-15px) rotate(3.6deg);
            transform: translateY(-15px) rotate(3.6deg);
  }
  60% {
    -webkit-transform: translateY(9px) rotate(-2.4deg);
            transform: translateY(9px) rotate(-2.4deg);
  }
  75% {
    -webkit-transform: translateY(-6px) rotate(1.2deg);
            transform: translateY(-6px) rotate(1.2deg);
  }
}
@keyframes wobble-ver-right {
  0%,
  100% {
    -webkit-transform: translateY(0) rotate(0);
            transform: translateY(0) rotate(0);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateY(-30px) rotate(6deg);
            transform: translateY(-30px) rotate(6deg);
  }
  30% {
    -webkit-transform: translateY(15px) rotate(-6deg);
            transform: translateY(15px) rotate(-6deg);
  }
  45% {
    -webkit-transform: translateY(-15px) rotate(3.6deg);
            transform: translateY(-15px) rotate(3.6deg);
  }
  60% {
    -webkit-transform: translateY(9px) rotate(-2.4deg);
            transform: translateY(9px) rotate(-2.4deg);
  }
  75% {
    -webkit-transform: translateY(-6px) rotate(1.2deg);
            transform: translateY(-6px) rotate(1.2deg);
  }
}
