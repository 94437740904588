@import "../../styles/uitilities.scss";

@keyframes loader_spinner {
    0% {
        transform: translate(6px, 40px) scale(0);
    }

    25% {
        transform: translate(6px, 40px) scale(0);
    }

    50% {
        transform: translate(6px, 40px) scale(1);
    }

    75% {
        transform: translate(40px, 40px) scale(1);
    }

    100% {
        transform: translate(74px, 40px) scale(1);
    }
}

@keyframes loader_spinner-r {
    0% {
        transform: translate(74px, 40px) scale(1);
    }

    100% {
        transform: translate(74px, 40px) scale(0);
    }
}

@keyframes loader_spinner-c {
    0% {
        background: #f9d028;
    }

    25% {
        background: #42cb33;
    }

    50% {
        background: #5562fc;
    }

    75% {
        background: #f9d028;
    }

    100% {
        background: #42cb33;
    }
}

.loader_spinner div {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    transform: translate(40px, 40px) scale(1);
    background: #f9d028;
    animation: loader_spinner 2s infinite cubic-bezier(0, 0.5, 0.5, 1);
}

.loader_spinner div:nth-child(1) {
    background: #42cb33;
    transform: translate(74px, 40px) scale(1);
    animation: loader_spinner-r 0.5s infinite cubic-bezier(0, 0.5, 0.5, 1),
        loader_spinner-c 1s infinite step-start;
}

.loader_spinner div:nth-child(2) {
    animation-delay: -0.5s;
    background: #5562fc;
}

.loader_spinner div:nth-child(3) {
    animation-delay: -1s;
    background: #f9d028;
}

.loader_spinner div:nth-child(4) {
    animation-delay: -1.5s;
    background: #42cb33;
}

.loader_spinner div:nth-child(5) {
    animation-delay: -2s;
    background: #5562fc;
}

.loader_spinner_wrap {
    height: 65px;
    display: flex;
    overflow: hidden;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    justify-content: center;
    margin-top: 50px;

    &.noData_loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &.Data_loader {
        position: absolute;
        bottom: -5px;
        left: 50%;
        width: 100%;
        height: 50px;
        // background: $dashboard-loader;
        transform: translate(-50%, -50%);
        z-index: 1000;
    }
}

.loader_spinner {
    width: 100%;
    height: 100%;
    max-width: 97px;
    position: relative;
    transform: translateZ(0) scale(0.7);
    backface-visibility: hidden;
    transform-origin: 0 0;
    /* see note above */
}

.loader_spinner div {
    box-sizing: content-box;
}