@import "../../styles/uitilities.scss";

.family_users {
  padding: 22px 20px;
  // width: 100%;
  border: none;
  border-radius: 10px;
  width: 235px;
  position: relative;
  overflow: hidden;
  @include max(1599.98) {
    width: 178px;
  }
  background: linear-gradient(
    -45deg,
    rgb(182 215 86) 0%,
    rgb(92, 196, 127) 50%
  );
  &_TopContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      padding: 1px 40px;
      background: #edba09;
      color: #fff;
      font-size: 14px;
      border-radius: 8px;
      transform: rotate(45deg);
      position: absolute;
      right: -30px;
      top: 12px;
    }
  }
  hr {
    margin-top: 0px;
    color: currentColor;
    border-bottom-color: white !important;
    border-color: white;
    border-width: 2px;
  }
  &:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-image: var(--bgimg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    pointer-events: none;
  }
  &_count{
    color: var(--text-color-alt);

  }
  &_usage {
    font-size: 16px;
    color: white;

    &_title {
      font-size: 16px;
      line-height: 1em;
      margin-bottom: 0px;
      color: #fff;
    }
  }
  &_title {
    font-size: size(12);
    font-weight: $font-medium;
    line-height: 17px;
    color: #fff;
    // margin-bottom: 6px;
    margin-bottom: 10px;
    overflow: hidden;
    max-width: 100%;
    display: block;
    text-overflow: ellipsis;
    span {
      display: block;
      overflow: hidden;
      width: 100%;
      position: relative;
      text-overflow: ellipsis;
    }
  }
  &_price {
    font-size: size(14);
    font-weight: $font-regular;
    line-height: 17px;
    margin-bottom: 9px;
    color: #fff;
    span {
      font-size: size(31);
      font-weight: $font-bold;
      line-height: 41px;
    }
  }
  &_expiry {
    font-size: size(12);
    font-weight: $font-regular;
    line-height: 15px;
    // margin-bottom: 18px;
    margin-bottom: 10px;
    color: #fff;
  }
  &_text {
    font-size: size(14);
    font-weight: $font-regular;
    line-height: 21px;
    margin-bottom: 13px;
    color: #fff;
  }
  :global(.btn) {
    min-width: 100%;
    background-color: $white;
    border-color: $white;
    font-size: size(16);
    padding: 12px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    &:hover {
      background-color: $btn-primary-hover;
      border-color: $btn-primary-hover;
      color: $white;
    }
  }
}
