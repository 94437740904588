@import "../../../styles/uitilities.scss";

.gallery_filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: max(356px, 100%);
  padding-top: 14px;
  // padding-bottom: 14px;
  position: relative;
  border-bottom: 2px solid $input-border;
  height: 72px;
  @include max(1599.98) {
    padding-top: 10px;
    min-height: 68px;
  }
  @include max(1279.98) {
    min-height: 50px;
  }
  &_wrap {
    @include max(575.98) {
      overflow: auto;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
