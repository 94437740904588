@import "../../styles/uitilities.scss";

.file_upload_minimized {
  position: fixed;
  bottom: 0;
  right: 67px;
  width: 432px;
  z-index: 1000;
  padding: 23px 20px 0;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  box-shadow: 0 0 3px $box-shadow-light;
  background-color: $card-bg;
  transition: 0.3s ease;

  @include max(1279.98) {
    bottom: 47px;
    right: 0;
    width: 100%;
  }

  @include max(1199.98) {
  }

  &.file_upload_collapsed {
    .fu_head_actions {
      .collapse_upload {
        svg {
          transform: rotate(-90deg);
        }
      }
    }

    .fu_body_list {
      height: 0;
      min-height: 0;
      max-height: 0;
    }
  }

  &_head {
    padding-bottom: 16px;
    border-bottom: 1px solid $border-opacity-dark;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 20px;

    .fu_head_title {
      font-size: size(18);
      font-weight: $font-medium;
      color: $text-color-alt;
      margin-bottom: 0;
      display: flex;
    }

    .fu_head_actions {
      margin-right: -20px;

      .collapse_upload {
        svg {
          transform: rotate(90deg);
          transition: 0.3s ease transform;
        }
      }

      svg {
        path {
          fill: $text-color-alt;
        }
      }

      button {
        background-color: transparent;
        border: none;
        padding: 0 8px;

        &:last-child {
          padding-right: 0;
        }
      }
    }
  }

  &_body {
    background-color: $card-bg;
    div {
      transition: 0.3s ease-in;
    }
    .fu_body_list {
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
      overflow: auto;
      // scrollbar-width: thin;
      scrollbar-width: none;
      height: 100%;
      min-height: 0px;
      max-height: 291px;
      transition: 0.3s ease-out all;

      &::-webkit-scrollbar {
        display: none;
      }

      // &::-webkit-scrollbar {
      //     width: 5px;
      // }

      // &::-webkit-scrollbar-track {
      //     background-color: $fu-scrollbar-track;
      // }

      // &::-webkit-scrollbar-thumb {
      //     background: $fu-scrollbar-thumb;
      //     border-radius: 25px;
      // }
      li {
        padding: 12px 3px;
        margin-right: 15px;
        display: flex;
        background-color: $card-bg;
        filter: opacity(0.5);

        &:not(:last-child) {
          border-bottom: 1px solid $border-opacity-dark;
        }

        &.image_uploaded {
          filter: opacity(1);
        }

        .fu_thumbnail {
          margin: auto;
          margin-right: 11px;
          border: 1px solid $border-color;
          // padding: 9px;
          border-radius: 6px;
          width: 48px;
          height: 42px;
          position: relative;
          overflow: hidden;

          img {
            // width: 28px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
            max-width: 100%;
            max-height: 100%;
          }

          &::after {
            content: "";
            display: block;
            height: 0;
            width: 100%;
            padding-bottom: 100%;
          }
        }

        .fu_item_data_right {
          flex: 1;

          &_top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;
          }

          :global(.progress) {
            height: 5px;
            margin-top: 5px;
            width: 100%;
          }

          :global(.progress-bar) {
            background-color: $modal-link;
          }

          .file_name {
            font-size: size(16);
            font-weight: $font-regular;
            line-height: 1.1;
            color: $text-color-alt;
            max-width: max(100% , 250px);
            @include line-clamp(1);
          }

          .file_upload_progress {
            font-size: size(12);
            font-weight: 400;
            line-height: 1;
            color: $text-color-alt;
          }

          .file_size {
            font-size: size(10);
            line-height: 1;
            color: $text-color-alt;
          }
        }

        .list_item_actions {
          margin-left: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 3px;

          svg {
            path {
              fill: $text-color-alt;
            }
          }

          > span {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 30px;
            position: relative;
            top: -3px;
          }

          button {
            background-color: transparent;
            border: none;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            transition: 0.2s ease-out all;

            &:hover {
              background-color: $close-hover-bg;
            }
          }

          &.upload_completed {
            span {
              display: block;
              width: 24px;
              height: 24px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;
              background-color: $primary-color;
            }

            svg {
              path {
                fill: $white;
              }
            }
          }
        }
      }
    }

    &.limit_exceded {
      opacity: 0.3;
    }
  }

  .success_indicator {
    width: 15px;
    height: 15px;
    position: relative;

    background: #21c031;
    border-radius: 50%;

    .success_check {
      width: 12px;
      height: 6px;
      border: 1px solid transparent;
      border-bottom: 1px solid #fff;
      border-left: 1px solid #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -70%) rotate(-45deg);
    }
  }

  li {
    display: flex;

    justify-content: space-between;
    align-items: center;
  }
}

.fu_item_data_right_top {
  max-width: 230px;
  display: inline-flex !important;
  text-overflow: ellipsis;
  overflow: hidden;

  .file_name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: block !important;
    white-space: nowrap;
  }
}

.duplication {
  // box-shadow: inset 0px 0px 11px rgb(149 141 19 / 66%);
  // border-radius: 5px;
  .fu_item_data_right {
    &:after {
      content: "";
      display: inline-block;
      background-image: url("../../assets/images/warning-icon.svg");
      width: 25px;
      height: 15px;
      background-size: contain;
      background-position: center right;
      background-repeat: no-repeat;
      margin-bottom: -3px;
    }
  }

  .fu_item_data_right_top {
    span.file_name {
      color: rgb(216, 87, 58) !important;
    }
  }
}

.duplicatedIcon {
  position: relative;
  z-index: 1000 !important;
}

.limitmini {
  margin: 0px 0px 0px 10px;
  img {
    width: 20px;
  }
}
