@import "../../styles/uitilities.scss";

.home_testimonials {
  padding: 105px 0 91px;
  overflow: hidden;
  position: relative;
  @include max(991.98) {
    padding: 70px 0;
  }
  @include max(767.98) {
    padding: 46px 0 40px;
  }
  &_wrap {
    position: relative;
  }
  &_swiper {
    padding: 0 20px 60px !important;
    margin: 0 -20px;
    @include max(767.98) {
      padding: 0 0 45px !important;
      margin: 0;
    }
    :global(.swiper-slide) {
      height: auto;
    }
    :global(.swiper-pagination) {
      bottom: 5px !important;
    }
    :global(.swiper-pagination-bullet) {
      background: $pagination-bullet;
      width: 10px;
      height: 10px;
      opacity: 0.6;
      transition: 0.5s ease all;
    }
    :global(.swiper-pagination-bullet-active) {
      background: $pagination-bullet-active;
      opacity: 1;
      transition: 0.5s ease all;
    }
  }
  :global(.swiper-navigation-button) {
    width: 54px;
    height: 54px;
    border-radius: 50%;
    background: $testimonial-swiper-arrow-bg;
    border: none;
    box-shadow: 0 3px 6px rgba($box-shadow-light-alt, 0.36);
    color: $pagination-nav;
    z-index: 1;
    transition: 0.5s ease all;
    @include max(767.98) {
      display: none;
    }
    &::after {
      font-size: size(18);
      font-weight: 900;
    }
  }
  :global(.swiper-navigation-button) {
    transition: 0.5s ease all;
    top: calc(50% + 27px);
    &::after {
      // filter: invert(1);
      color: #fff;
      transition: 0.3s ease all;
    }
    &:hover {
      background: $swiper-nav-bg;
      &::after {
        filter: invert(0) brightness(2);
      }
    }
  }
  :global(.swiper-navigation-prev) {
    left: -87px;
    @include max(1599.98) {
      left: -60px;
    }
    @include max(1439.98) {
      left: -25px;
    }
  }
  :global(.swiper-navigation-next) {
    right: -87px;
    @include max(1599.98) {
      right: -60px;
    }
    @include max(1439.98) {
      right: -25px;
    }
  }
  &_title {
    margin-bottom: 45px;
    @include max(767.98) {
      margin-bottom: 42px;
    }
  }
  &_testimonialcard {
    border-radius: 13px;
    overflow: hidden;
    box-shadow: 2px 13px 16px $card-box-shadow-alt;
    border: 1px solid $card-border;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: $card-bg;
    transition: 0.5s ease all;
    .testimonial_image {
      position: relative;
      overflow: hidden;
      margin-bottom: 0;
      background: $shimmer-bg;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        max-width: 100%;
        max-height: 100%;
      }
      &::after {
        content: "";
        display: block;
        height: 0;
        width: 100%;
        padding-bottom: calc(173 / 426 * 100%);
      }
    }
    .testimonial_content {
      padding: 37px 41px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      @include max(767.98) {
        padding: 19px 20px 29px;
      }
    }
  }
  &_cardtext {
    font-size: size(18);
    font-weight: $font-regular;
    line-height: 28px;
    color: $text-color-alt;
    opacity: 0.87;
    margin-bottom: 41px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    letter-spacing: 0.18px;
    transition: 0.5s ease all;
    @include max(767.98) {
      font-size: size(15);
      line-height: 24px;
      margin-bottom: 31px;
    }
  }
  &_author {
    font-size: size(18);
    font-weight: $font-medium;
    color: $text-color-alt;
    margin-bottom: 9px;
    line-height: 20px;
    transition: 0.5s ease all;
    @include max(767.98) {
      font-size: size(16);
      margin-bottom: 8px;
    }
  }
  &_date {
    font-size: size(16);
    font-weight: $font-regular;
    color: $text-color-alt;
    opacity: 0.66;
    line-height: 1.16;
    margin-bottom: 0;
    transition: 0.5s ease all;
    @include max(767.98) {
      font-size: size(14);
      line-height: 21px;
    }
  }
  .anim_elements {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    .anim_elem {
      position: absolute;
      width: max-content;
      height: max-content;
      margin-bottom: 0;
      z-index: -1;
      @include max(991.98) {
        display: none;
      }
      &.donut_blue {
        top: 9%;
        transform: translateX(calc(70vw / 2));
      }
    }
  }
}

// shimmer effects
:global(.data-loading) {
  &.home_testimonials_testimonialcard {
    .shimmer-styles {
      border-radius: 5px;
      width: 100%;
      background: $shimmer-bg;
      background-image: $shimmer-gradient;
      background-repeat: no-repeat;
      background-size: 800px 104px;
      display: inline-block;
      position: relative;

      -webkit-animation-duration: 1s;
      -webkit-animation-fill-mode: forwards;
      -webkit-animation-iteration-count: infinite;
      -webkit-animation-name: placeholderShimmer;
      -webkit-animation-timing-function: linear;
    }
    .testimonial_image {
      @extend .shimmer-styles;
      background-size: 800px 100%;
      border-radius: 0;
    }
    .home_testimonials {
      &_cardtext {
        .lines {
          margin-bottom: 10px;
          display: block;
          height: 15px;
          @extend .shimmer-styles;
        }
      }
      &_author {
        height: 15px;
        max-width: 200px;
        @extend .shimmer-styles;
      }
      &_date {
        height: 15px;
        max-width: 150px;
        @extend .shimmer-styles;
      }
    }
  }
}

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}
