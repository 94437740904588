@import "../../../styles/uitilities.scss";

.remove_person {
  padding: 50px 42px;
  @include max(575.98) {
    padding: 35px;
  }
  &_title {
    font-size: size(24);
    font-weight: $font-bold;
    color: $text-color-alt;
    text-align: center;
    max-width: 350px;
    margin: 0 auto;
    margin-bottom: 10px;
    @include max(575.98) {
      font-size: size(22);
    }
  }
  &_description {
    font-size: size(16);
    font-weight: $font-regular;
    color: $text-color-alt;
    opacity: 0.61;
    line-height: 24px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 26px;
    @include max(575.98) {
      font-size: size(14);
      line-height: 18px;
    }
  }
  &_btn_wrap {
    display: flex;
    @include max(424.98) {
      flex-direction: column;
    }
    button {
      flex: 0 0 47.5%;
      max-width: 47.5%;
      margin: 0 10px;
      @include max(424.98) {
        max-width: 100% !important;
      }
      &:first-child {
        margin-left: 0;
        @include max(424.98) {
          margin-bottom: 15px;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

:global(.remove_person_modal .modal-dialog) {
  max-width: 474px;
}
:global(.remove_person_modal .modal-body .btn) {
  width: 100%;
  max-width: 184px;
}
