@import "../../styles/uitilities.scss";

.search_bar {
  &_search {
    display: flex;
    position: relative;
    border-radius: 6px;
    z-index: 2;
    transition: 0.5s ease all;
    input {
      max-width: calc(100vw - 150px);
    }
    @include max(1279.98) {
      border: 1px solid $searchbar-border;
      transition: none;
      margin: 0 auto;
      max-width: 513px;
      width: 100%;

      &:not(.menu_search) {
        + .search_bar_search_suggestions {
          height: calc(100vh - 64px);
        }
      }
    }

    &.no_mobile {
      @include max(1279.98) {
        display: none;
      }
    }

    &.no_dark {
      .search_bar_search_input {
        background: $white;
        transition: 0.5s ease all;
        caret-color: $black;
        color: $black;

        &::placeholder,
        &::-webkit-input-placeholder {
          color: $black;
        }
      }

      .search_bar_search_icon {
        svg {
          path {
            fill: $black;
          }
        }
      }

      .search_bar_search_dropdown {
        .search_bar_toggle {
          background-color: $white;
          color: $black;
          border-left: 1px solid $alt-light !important;

          svg {
            path {
              fill: $black;
            }
          }

          &:hover {
            color: $white;
            background-color: $btn-primary-hover;
            border: 1px solid $btn-primary-hover;
            border-left: 1px solid $btn-primary-hover;

            svg {
              path {
                fill: $white;
              }
            }
          }

          &:focus {
            color: $white;
            background-color: $btn-primary-hover;
            border: 1px solid $btn-primary-hover;
            border-left: 1px solid $btn-primary-hover;

            svg {
              path {
                fill: $white;
              }
            }
          }
        }
      }
    }

    &_input {
      font-size: size(18);
      font-weight: $font-regular;
      flex: 1 1 auto;
      border: none;
      padding-left: 50px;
      border-radius: 6px 0 0 6px;
      background: $input-background;
      color: $text-color-alt;
      caret-color: $text-color-alt;
      transition: 0.5s ease all;

      &::placeholder,
      &::-webkit-input-placeholder {
        font-size: size(18);
        font-weight: $font-regular;
        color: $searchbar-placeholder;
        opacity: 0.47;
      }

      &:focus {
        outline: none !important;
        box-shadow: none !important;
      }
    }

    &_dropdown {
      width: 135px;

      @include max(1279.98) {
        :global(.dropdown-menu) {
          background-color: $white;
          border-radius: 10px;
          padding: 13px;
          position: relative;
          right: inherit !important;

          &::before {
            content: "";
            background: $white;
            position: absolute;
            right: 20px !important;
            left: inherit !important;
            width: 14px;
            height: 14px;
          }

          &[x-placement="bottom-end"] {
            transform: translate(-85px, 45px) !important;

            &::before {
              top: 0;
              transform: translateY(-50%) rotate(135deg);
              border: 1px solid transparent;
              border-radius: 0 0 0 5px;
              border-bottom: 1px solid $border-color;
              border-left: 1px solid $border-color;
            }
          }

          a {
            font-size: size(17);
            font-weight: $font-regular;
            padding: 6px 10px;
            border-radius: 6px;
            overflow: hidden;
            transition: 0.5s ease all;

            &:hover,
            &:focus {
              color: $text-alt;
              background-color: $dropdown-bg-hover;
            }
          }
        }
      }

      button {
        height: 55px;
        min-width: 100%;
        border-radius: 0 6px 6px 0;
        border: none !important;
        background-color: $input-background;
        font-size: size(18);
        font-weight: $font-regular;
        color: $profile-details-color;
        text-align: left;
        margin-bottom: 0;
        padding-left: 23px;
        line-height: 1.1;
        transition: 0.3s ease background-color, 0.5s ease color;

        &::after {
          display: none;
        }

        svg {
          position: absolute;
          top: 42%;
          right: 23px;
          transform: rotate(90deg);

          path {
            fill: $profile-details-color;
            transition: 0.3s ease all;
          }
        }
      }
    }

    &_icon {
      position: absolute;
      top: 47%;
      left: 20px;
      transform: translateY(-50%);

      svg {
        path {
          fill: $text-color-alt;
        }
      }
    }

    &_suggestions {
      position: absolute;
      bottom: -13px;
      left: 0;
      transform: translateY(100%);
      width: 100%;
      background: $dashboard-bg-alt;
      padding: 34px;
      border-radius: 10px;
      box-shadow: 2px 2px 8px rgba(#434343, 0.09);

      &::before {
        content: "";
        background: $dashboard-bg-alt;
        position: absolute;
        left: 45px;
        top: 0;
        width: 14px;
        height: 14px;
        transform: translateY(-50%) rotate(135deg);
        border: 1px solid transparent;
        border-radius: 0 0 0 5px;
        border-bottom: 1px solid $dashboard-bg-alt;
        border-left: 1px solid $dashboard-bg-alt;
      }

      &_list {
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
        max-height: 280px;
        height: 100%;
        overflow: auto;
        scrollbar-width: thin;
        scrollbar-color: $fu-scrollbar-thumb $fu-scrollbar-track;

        @include max(1279.98) {
          max-height: 100%;
        }

        &::-webkit-scrollbar {
          width: 6px;
          border-radius: 10px;
        }

        &::-webkit-scrollbar-track {
          background: $fu-scrollbar-track;
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
          background: $fu-scrollbar-thumb;
          border-radius: 10px;
        }

        .search_result_not_found {
          font-size: size(18);
          font-weight: $font-bold;
          color: $text-color-alt;
          text-align: center;
        }
      }

      &_tags {
        display: flex;
        flex-wrap: wrap;
        padding-left: 0;
        list-style: none;
        margin: 20px -5px 0;

        li {
          padding: 0 5px;

          button {
            cursor: pointer;
            font-size: size(16);
            font-weight: $font-regular;
            line-height: 1.5;
            color: $text-color-alt;
            padding: 6px 10px;
            margin-bottom: 10px;
            background: $search-filter-input;
            border-radius: 6px;
            border: none;
            transition: 0.3s ease filter;

            &:hover {
              filter: brightness(0.9);
            }
          }
        }
      }

      .recent_search {
        &_header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 11px;
          border-bottom: 1px solid $border-opacity-dark;
          margin-right: 20px;
        }

        &_title {
          font-size: size(18);
          font-weight: $font-medium;
          margin-bottom: 0;
          color: $text-color-alt;
        }

        &_clear {
          font-size: size(14);
          font-weight: $font-regular;
          color: #a2a4ac;
          border: none;
          background-color: transparent;
          transition: 0.3s ease filter;

          &:hover {
            filter: brightness(0.8);
          }
        }
      }

      .search_profile {
        padding: 14px 0;
        margin-right: 20px;
        position: relative;

        &:not(:last-child) {
          border-bottom: 1px solid $border-opacity-dark;
        }

        &_image {
          margin-right: 13px;
          width: 44px;
          height: 44px;
          border-radius: 50%;
          overflow: hidden;
          margin-bottom: 0;

          img {
            width: 100%;
            object-fit: cover;
            height: 100%;
            object-position: center;
          }
        }

        a {
          display: flex;
          align-items: center;
        }

        &_name {
          font-size: size(16);
          font-weight: $font-medium;
          color: $text-color;
          line-height: 1.1;
          margin-bottom: 7px;
        }

        &_address {
          font-size: size(14);
          font-weight: $font-regular;
          color: $text-color;
          line-height: 1.1;
          opacity: 0.7;
        }

        &_remove_btn {
          background-color: transparent;
          border: none;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          transition: 0.3s ease opacity;

          svg {
            path {
              fill: $text-color-alt;
            }
          }

          &:hover {
            opacity: 0.5;
          }
        }
      }
    }
  }
}

.menu_search {
  border: 1px solid $searchbar-border;
  transition: none;
  margin: 0 auto;
  max-width: 513px;
  width: 100%;

  .search_bar_search_suggestions {
    bottom: -29px;
  }

  .search_bar_search_input {
    transition: none;
    user-select: none;
  }

  .search_bar_search_dropdown button {
    transition: none;

    &:focus {
      color: #fff;
      background-color: $btn-primary-hover;
      border-color: $btn-primary-hover;
    }

    &:hover,
    &:focus {
      background-color: transparent;
      color: $text-color-alt;

      &::after {
        border-right: 2px solid #707070;
        border-bottom: 2px solid #707070;
      }
    }
  }

  .search_bar_search_dropdown .show button {
    color: $text-color-alt;
  }

  :global(.show > .btn-primary.dropdown-toggle) {
    color: $text-color-alt;
  }
}

:global(.dark) {
  .search_bar_search {
    &:not(.no_dark) {
      border: 1px solid transparent;

      .search_bar_toggle {
        background-color: $input-background !important;

        &::after {
          border-right: 2px solid $white;
          border-bottom: 2px solid $white;
        }

        &:hover,
        &:active,
        &:focus {
          background-color: $input-background;
        }
      }
    }
  }
}

:global {
  .menu-search {
    max-width: 100%;
    margin: 0 5px;

    > div {
      &:first-child {
        left: 10px;
      }
    }

    ~ .search-close-btn {
      background-color: transparent;
      border: none;
      font-size: size(14);
      font-weight: $font-regular;
      color: $profile-details-color;
      margin: 0 5px;
    }

    .dropdown-toggle {
      @include max(1279.98) {
        height: 36px;
        padding: 0;
        color: $profile-details-color !important;
      }
    }

    input {
      @include max(1279.98) {
        font-size: size(16);
        padding-left: 34px;
      }

      &::placeholder,
      &::-webkit-input-placeholder {
        @include max(1279.98) {
          font-size: size(14);
        }
      }

      + div {
        @include max(1279.98) {
          width: 73px;
        }

        button {
          @include max(1279.98) {
            font-size: size(14);
            padding-left: 6px !important;
            border-left: 1px solid rgba(#dde8f8, 0.1) !important;
          }

          svg {
            @include max(1279.98) {
              top: 40%;
              right: 8px;
              width: 8px;
              height: 8px;
            }
          }
        }
      }
    }
  }
}

:global(.menu-search) {
  + .search_bar_search_suggestions {
    @include max(1279.98) {
      bottom: 0;
      border-radius: 0;
      width: 100%;
      height: calc(100vh - 99px);
      padding: 10px 36px;
      display: flex;
      flex-direction: column-reverse;

      &::before {
        display: none;
      }
    }

    @include max(575.98) {
      padding: 10px 16px;
    }

    .search_bar_search_suggestions {
      &_tags {
        @include max(1279.98) {
          margin-top: 0;
        }

        &_title {
          @include max(1279.98) {
            font-size: size(14);
            font-weight: $font-regular;
            color: $text-color-alt;
            margin-bottom: 10px;
          }
        }

        li {
          button {
            @include max(1279.98) {
              font-size: size(13);
            }
          }
        }
      }
    }
  }
}

.view_all_btn {
  color: $text-color-alt;
  font-size: size(16);
  font-weight: $font-medium;
  background-color: transparent;
  border: none;

  @include max(575.98) {
    font-size: size(14);
  }

  &:hover {
    svg {
      transform: rotate(180deg) translateX(-5px);
    }
  }

  &_wrap {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      position: relative;
      top: -2px;
      margin-left: 8px;
      transform: rotate(180deg);
      transform-origin: center;
      transition: 0.3s ease transform;

      @include max(575.98) {
        width: 14px;
        height: 14px;
      }

      path {
        fill: $profile-details-color;
      }
    }
  }
}

.view_all_btn:disabled,
.view_all_btn[disabled] {
  filter: brightness(0.5);
}
