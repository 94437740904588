@import "../../../styles/uitilities.scss";

.slideshow_fullscreen {
  background: #212124;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;

  &_image {
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    position: relative;

    img {
      max-height: 100%;
      max-width: 100%;
      object-fit: contain;
    }
  }

  &_controls {
    background-color: #2c2c34;
    color: white;
    padding: 15px 10px;
    border-radius: 7px;
    position: absolute;
    bottom: 39px;
    left: 46px;
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s ease all;

    @include max(767.98) {
      bottom: 24px;
      left: 17px;
    }

    &:global(.controls-visible) {
      opacity: 1;
      visibility: visible;
    }

    .control_btn {
      padding: 0 18px;
      background-color: transparent;
      border: none;
      position: relative;

      &::after {
        content: "";
        width: 44px;
        height: 44px;
        background: #ffffff12;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: 0.3s ease all;
      }

      &:hover {
        &::after {
          transform: translate(-50%, -50%) scale(1);
        }
      }
    }

  }

  :global {
    .swiper-button-next {
      display: none;
    }

    .swiper-button-prev {
      display: none;
    }
  }

  // :global(.fade) {
  //   animation: fade 4s ease 1 forwards;
  //   transition: 5s ease all;
  // }
  // // :global(.swiper-slide-active) {
  // //   opacity: 1 !important;
  // // }
  // :global(.bounce) {
  //   opacity: 1;
  //   animation: bounce 3s ease 1 forwards;
  //   transition: 2s ease all;
  // }
  // :global(.zoom-out) {
  //   animation: zoom-out 3s ease 1 forwards;
  //   transition: 2s ease all;
  // }
  // :global(.fade) {
  //   animation: fade 2s ease-in-out 1 forwards;
  //   transition: 5s ease all;
  // }
  // :global(.bounce) {
  //   opacity: 1;
  //   animation: bounce 3s ease-in-out 1 forwards;
  //   transition: 2s ease all;
  // }
  // :global(.zoom-out) {
  //   animation: zoom-out 1s ease-in-out 1 forwards;
  //   transition: 2s ease all;
  // }
  // :global(.non) {
  //   transition: 1s ease-in-out all;
  // }
  // :global(.mySwiper2 .swiper-wrapper) {
  //   transition-duration: 0.01s !important;
  // }
  :global(.fade-effect) {
    img {
      animation: fade 0.54s ease-out 1 forwards;
    }
  }

  :global(.bounce) {
    img {
      opacity: 1;
      animation: bounce 3s ease-out 1 forwards;
    }
  }

  :global(.zoom-out) {
    img {
      opacity: 1;
      animation: zoom-out 6s linear 0s 1 normal forwards;
    }
  }

  :global(.non) {
    img {
      transition: 1s ease-out all;
    }
  }

  :global(.mySwiper2 .swiper-wrapper) {
    transition-duration: 0.01s !important;
  }

  :global {
    .swiper-slide {
      opacity: 0;

      &.swiper-slide-active {
        opacity: 1;
        transition: opacity 0.3s ease-in-out;
        transition-delay: 0.15s;
      }
    }
  }
}

.back_btn {
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 10000;
  cursor: pointer;
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0);
  }

  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }

  30% {
    transform: scale(0.9, 1.1) translateY(-100px);
  }

  50% {
    transform: scale(1, 1) translateY(0);
  }

  57% {
    transform: scale(1, 1) translateY(-7px);
  }

  64% {
    transform: scale(1, 1) translateY(0);
  }

  100% {
    transform: scale(1, 1) translateY(0);
  }
}

@keyframes zoom-out {
  0% {
    transform: scale(1.5, 1.5);
  }

  100% {
    transform: scale(1, 1);
  }
}