@import "../../styles/uitilities.scss";

:global {
    .SfxModalTitle-root {
        .SfxModalTitle-LabelPrimary {
            width: 100%;
            margin-bottom: 25px !important;
            text-align: center;
        }
    }
    .SfxModalContent-root.fjFruI {
        padding: 0;
        margin-bottom: 20px;
        .SfxInput-root {
            height: 30px;
            line-height: 1;
        }
    }
    .jIgSWS {
        border: 1px solid #6879eb !important;
    }
    [data-phone='true'] .sc-dwg14p-1 {
        background: transparent !important;
    }
    .FIE_topbar-reset-button {
        width: 80px !important;
        height: 30px !important;
        margin-top: -3px !important;
        color: $white !important;
        background-color: $error-message-alt !important;
        &::after {
            content: "Revert";
        }
        svg {
            display: none;
        }
    }
    .FIE_text-tool-button {
        display: flex;
        align-items: center;
    }
    .FIE_topbar {
        @include max(620) {
            flex-direction: column;
        }
        &-buttons-wrapper {
            @include max(620) {
                margin-bottom: 5px;
            }
        }
        button {
            width: 30px;
        }
        svg {
            width: 22px;
            height: 18px;
            path {
                fill: $edit-image-button-text;
            }
        }
        .XwIXy {
            background: $edit-image-button-text;
        }
        &-history-buttons {
            align-items: center;
            button {
                &:first-child {
                    position: relative;
                    top: 2px;
                }
            }
        }
        .SfxLabel-root {
            font-size: size(15);
            color: $edit-image-button-text;
        }
    }
    .FIE_root {
        background: $dashboard-bg !important;
    }
    .FIE_editor-content {
        height: calc(100vh - 155px) !important;
        padding: 0 35px 0 25px;
        @include max(1279.98) {
            height: calc(100vh - 175px) !important;
        }
        @include max(767.98) {
            width: 100% !important;
        }
    }
    .FIE_tools-bar-wrapper {
        min-height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: calc(100vw - 500px) !important;
        @include max(1279.98) {
            max-width: calc(100vw - 140px) !important;
        }
        @include max(767.98) {
            padding: 0 20px !important;
            min-height: 168px;
            max-width: max(calc(100vw - 100px), 320px) !important;
            @include max(424.98) {
                padding: 0 6px !important;
            }
            .FIE_tools-bar {
                flex-wrap: wrap;
                justify-content: center;
                // background-color: $white !important;
            }
        }
        button {
            &:not(.FIE_watermark-add-menu-opener) {
                svg {
                    width: 17px !important;
                    height: 17px !important;
                }
            }
            &.FIE_watermark-add-button,
            &.FIE_watermark-add-menu-opener {
                .SfxButton-Label {
                    height: 100%;
                    font-size: size(15) !important;
                    display: flex;
                    align-items: center;
                }
            }
        }
        .SfxLabel-root {
            font-size: size(14) !important;
        }
    }
    .FIE_canvas-node {
        width: 100%;
        height: 100%;
        background: $dashboard-bg !important;
        > .konvajs-content {
            width: 100% !important;
            > canvas {
                width: 100% !important;
                height: 100% !important;
                object-fit: contain;
                &:first-child {
                    background: $dashboard-bg !important;
                }
            }
        }
    }
    .FIE_tab {
        background: $dashboard-bg-alt !important;
        width: 100% !important;
        height: 100% !important;
        padding: 20px;
        svg {
            width: 18px;
            height: 18px;
            margin-bottom: 5px;
        }
        label {
            color: $edit-image-button-text;
        }
        svg {
            path {
                fill: $edit-image-button-text;
            }
        }
        .SfxLabel-root {
            font-size: size(14);
        }
        &:hover {
            background: $green !important;
            svg {
                path {
                    fill: $white;
                }
            }
            label {
                color: $white !important;
            }
        }
        &[aria-selected="true"] {
            background: $green !important;
            svg {
                path {
                    fill: $white;
                }
            }
            label {
                color: $white !important;
            }
        }
    }
    @include max(1279.98) {
        .FIE_tabs {
            width: calc(100vw - 24px);
            display: flex;
            justify-content: center;
            overflow: auto;
            margin-top: 5px;
            height: 180px;
            scrollbar-width: none;
            padding-right: 0 !important;
            &::-webkit-scrollbar {
                display: none;
            }
            @include max(424.98) {
                height: 60px;
                justify-content: flex-start;
                width: calc(100vw - 6px);
            }
            .FIE_tab {
                margin: 0 5px;
                padding: 5px 15px;
                flex: 0 0 100px;
                width: 100px !important;
            }
        }
        .FIE_main-container {
            flex-direction: column-reverse;
        }
        .FIE_tool-options-wrapper {
            max-height: 100% !important;
        }
    }
    @include max(374.98) {
        .FIE_topbar {
            flex-direction: column;
            &-buttons-wrapper {
                margin-bottom: 10px;
            }
        }
    }
    .SfxInput-root,
    .SfxSelect-root {
        background-color: $body-background !important;
        border-color: $body-background !important;
        color: $text-color-alt !important;
        &.llULMD {
            &:focus-within {
                background-color: $body-background !important;
                border-color: $body-background !important;
                color: $text-color-alt !important;
            }
        }
        input {
            background-color: $body-background !important;
            border-color: $body-background !important;
            color: $text-color-alt !important;
            &:focus {
                background-color: $body-background !important;
                border-color: $body-background !important;
                color: $text-color-alt !important;
            }
        }
    }
    .SfxPopper-root {
        .SfxMenuItem-wrapper {
            .SfxMenuItem-root {
                background-color: $dashboard-bg;
                color: $text-color-alt;
                &:hover {
                    background-color: #ecf3ff;
                }
                &:last-child {
                    border-bottom: none;
                }
            }
            &:not(:last-child) {
                .SfxMenuItem-root {
                    border-bottom: 1px solid $border-opacity-dark;
                }
            }
        }
    }
    .FIE_carousel-next-button {
        background: linear-gradient(to left, $dashboard-bg, transparent) !important;
        svg {
            path {
                stroke: $text-color-alt;
            }
        }
    }
    .FIE_carousel-prev-button {
        background: linear-gradient(to right, $dashboard-bg, transparent) !important;
        svg {
            path {
                stroke: $text-color-alt;
            }
        }
    }
    .FIE_main-container {
        @include min(1280) {
            flex-direction: row-reverse;
        }
        .FIE_tabs {
            @include min(1280) {
                padding-top: 15px;
            }
            padding-top: 75px;
        }
    }
    .FIE_topbar-save-wrapper {
        width: 100px !important;
    }
    .FIE_topbar-save-button {
        height: 30px !important;
        background-color: $green !important;
        border-color: $green !important;
        transition: 0.2s ease filter;
        &:hover {
            filter: brightness(1.1);
        }
        span {
            &.SfxButton-Label {
                font-size: size(15) !important;
            }
        }
    }
    .sc-oxugzy-1 {
        transition: 0.1s ease padding;
        will-change: padding;
        border: 1px;
    }
    [aria-selected="true"] {
        .sc-oxugzy-1 {
            border-color: $green !important;
            + label {
                color: $green !important;
            }
        }
    }
    .FIE_tool-options-wrapper {
        .SfxLabel-root {
            margin-top: -5px;
            padding-top: 15px;
            margin-bottom: 8px;
        }
    }
    .FIE_tools-bar {
        .cHPFPE {
            border-radius: 4px;
            svg {
                path {
                    fill: $edit-image-button-text !important;
                    stroke: $edit-image-button-text !important;
                }
            }
            label {
                color: $edit-image-button-text !important;
            }
            .FIE_crop-presets-opener-button {
                span {
                    color: $edit-image-button-text !important;
                }
            }
            &[aria-selected="true"],
            &:hover {
                background-color: $edit-image-button-bg !important;
                svg {
                    path {
                        fill: $edit-image-button-text-hover !important;
                        stroke: $edit-image-button-text-hover !important;
                    }
                }
                label {
                    color: $edit-image-button-text-hover !important;
                }
                .FIE_crop-presets-opener-button {
                    span {
                        color: $edit-image-button-text-hover !important;
                    }
                }
            }
        }
    }
    .FIE_annotations-options {
        .bARUxi {
            svg {
                path {
                    fill: $edit-image-button-text !important;
                    stroke: $edit-image-button-text !important;
                }
            }
            label {
                color: $edit-image-button-text !important;
            }
            &[aria-selected="true"],
            &:hover {
                background-color: $edit-image-button-bg !important;
                svg {
                    path {
                        fill: $edit-image-button-text-hover !important;
                        stroke: $edit-image-button-text-hover !important;
                    }
                }
                label {
                    color: $edit-image-button-text-hover !important;
                }
            }
        }
    }
    .FIE_watermark-add-button,
    .FIE_watermark-add-menu-opener {
        background-color: $edit-image-button-bg !important;
    }
    .FIE_watermark-add-button {
        border-right: 0 !important;
    }
    .FIE_watermark-add-menu-opener {
        border-left: 0 !important;
        margin-left: -1px !important;
    }
    .FIE_resize-reset-button {
        background-color: $red !important;
        color: $white !important;
        &[disabled] {
            filter: brightness(1.3);
        }
    }
    .FIE_watermark-add-wrapper {
        height: 40px !important;
        button {
            height: 100%;
            display: flex;
            align-items: center;
        }
        .SfxButton-Label {
            line-height: 1 !important;
        }
    }
    .dark {
        .SfxMenuItem-root {
            &:hover {
                color: $dashboard-add-btn-hover;
            }
        }
        .FIE_resize-reset-button {
            &[disabled] {
                filter: brightness(0.5);
            }
        }
    }
    .SfxModalTitle-LabelPrimary {
        font-size: size(24) !important;
        font-weight: $font-bold !important;
        margin-bottom: 15px !important;
        color: $text-color;
        line-height: 1 !important;
        
    }
    .SfxModalTitle-LabelSecondary {
        font-size: size(16) !important;
        font-weight: $font-regular !important;
        color: $text-color;
        margin-bottom: 30px !important;
    }
    .SfxModal-Container {
        width: 400px !important;
        max-width: 400px !important;
        background-color: $modal-bg !important;
        .SfxModal-root {
            padding: 50px 45px !important;
            .SfxModalTitle-Icon {
                display: none !important;
            }
        }
    }
    .SfxModalTitle-Close {
        top: 20px !important;
        right: 20px !important;
        svg {
            width: 15px !important;
            height: 15px !important;
            opacity: 1;
            path {
                fill: $text-color-alt;
            }
        }
        &:hover {
            svg {
                opacity: 0.5;
            }
        }
    }
    .SfxModalTitle-root {
        padding: 0 !important;
        position: unset !important;
    }
    .SfxModalActions-root {
        padding: 0 !important;
        margin-top: 30px;
        .SfxButton-root {
            width: 100%;
            max-width: 150px;
            border: none;
            border-radius: 6px;
            font-weight: 500;
            font-size: size(15);
            padding: 15px 13px;
            margin: 0 7px;
            justify-content: center;
            transition: background-color 0.3s ease-in;
            span {
                font-family: $font-family !important;
            }
            &.fChmtv {
                background-color: $delete-red !important;
                border: 1px solid $delete-red !important;
                color: $white !important;
                &:hover {
                    color: $white !important;
                    background-color: $orange-red-alt !important;
                    border-color: $orange-red-alt !important;
                }
                &:focus {
                    color: $delete-red !important;
                    &:hover {
                        color: $white !important;
                    }
                }
            }
            &.bqChvH {
                background-color: transparent !important;
                border: 1px solid $button-grey !important;
                color: $text-color-alt !important;
            }
        }
    }
    .backto-gallery-btn{
        position: absolute;
        right: 25px;
        top: 15px;
        z-index: 1;
        color: rgba(118,129,132,1);
        border-color: rgba(118,129,132,1);
        font-size: size(14);
        padding: 7px;
        border-radius: 21px;
        background-color: transparent;
        @include max(1199.9){
            font-size: 0px;
            height: 35px;
            width: 35px;
            max-width: 35px!important;
            min-width: 35px!important;
            display: flex!important;
            align-items: center;
            justify-content: center;
            transform: translateX(1px);
        }
        svg{
            transform: translateY(-1px) translateX(0px);
            transition: transform 0.3s ease-in-out;
            path{
                transition: fill 0.3s ease-in-out;
                fill: rgba(118,129,132,1);
            }
        }
        &:hover{
            color: white;
            border-color: white;
            svg{
                @include min(1200){
                    transform: translateY(-1px) translateX(-3px);
                }
                path{
                    
                    fill: white;
                }
            }
        }
    }


    .FIE_topbar{
        justify-content: start;
        @include min-max(450,620){
            .FIE_topbar-buttons-wrapper{
                margin-left: auto;
            }
        }
        @include max(620){
            flex-direction: row!important;
            gap: 0 15px!important;
            
            .dPQmmf{
                margin-right: auto;
            }
            .FIE_topbar-buttons-wrapper{
                transform: translateY(3px);
            }
        }
        .sc-21g986-2{
            order: 2!important;
        }
        .FIE_topbar-buttons-wrapper{
            order: 1;
        }
        .FIE_topbar-center-options{
            order: 3;
            margin-inline: auto;
        }
        .FIE_topbar-redo-button,
        .FIE_topbar-undo-button{
            width: 30px;
            padding: 0!important;
        }
    }
    .FIE_save-file-name-input{
        padding: 0!important;
        overflow: hidden;
        input.dmlygs{
            padding: 8px 12px!important;
            
        }
    }



}
