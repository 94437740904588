@import "../../../styles/uitilities.scss";

.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 475px;
  box-shadow: -2px 0px 8px rgba(7, 20, 84, 0.07);
  position: fixed;
  top: 83px;
  right: 0;
  z-index: 99;
  height: calc(100vh - 84px);
  padding: 0px 25px;
  background-color: $dashboard-bg-alt;
  justify-content: center;
  align-items: center;
  // animation: slideAnim 0.5s;
  padding-bottom: 0;
  transform: translateX(0);
  // transition: 0.5s ease-in-out all;
  @include max(1279.98) {
    top: 0;
    padding-top: 62px;
    padding-bottom: 61px;
    height: 100vh;
  }
  @include max(1199.98) {
    padding-top: 52px;
  }
  @include max(575.98) {
    max-width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
  .sideDrawerWrapper {
    width: 100%;
    overflow-y: auto;
    padding-top: 30px;
    padding-bottom: 30px;
    height: 100%;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.closeWrapper {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 40px;
  font-size: 0px;
  border-radius: 50%;
  z-index: 999;
  overflow: hidden;
  position: absolute;
  top: 32px;
  right: 20px;
  @include max(1279.98) {
    top: 85px;
  }
  button {
    font-size: 0px;
    border: none;
    padding: 0px;
    margin: 0px;
    width: 30px;
    height: 30px;
    background-color: transparent;
    cursor: pointer;
    transition: 0.3s background-color ease-in-out, 0.3s ease-in-out opacity;
    &::after {
      position: absolute;
      width: 16px;
      height: 2px;
      background-color: $text-color-alt;
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      transition: 0.3s background-color ease-in-out;
    }
    &::before {
      position: absolute;
      width: 16px;
      height: 2px;
      background-color: $text-color-alt;
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
      transition: 0.3s background-color ease-in-out;
    }
    &:hover {
      opacity: 0.5;
    }
  }
}

.drawerWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
}

.drawerImage {
  width: 100%;
  position: relative;
  max-width: 200px;
  border-radius: 50%;
  border: 3px solid #e0e2e8;
  padding: 10px;
  margin: 0px auto 20px auto;
  .drawerImageContainer {
    width: 100%;
    padding-bottom: 100%;
    position: relative;
    border-radius: 50%;
    background-color: #e0e2e8;
    overflow: hidden;
    user-select: none;
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .drawerImageIcon {
    position: absolute;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    overflow: hidden;
    padding: 5px;
    border: 3px solid #fff;
    bottom: 20px;
    right: -5px;
    box-shadow: 0px 3px 6px rgba(211, 213, 219, 0.44);
    transition: 0.3s ease transform;
    > label {
      background-color: #23cc2b;
      display: inline-block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19.207' height='17.286' viewBox='0 0 19.207 17.286'%3E%3Cg id='Iconly_Bold_Camera' data-name='Iconly/Bold/Camera' transform='translate(-2 -3)'%3E%3Cg id='Camera' transform='translate(2 3)'%3E%3Cpath id='Path_76340' data-name='Path 76340' d='M11.86,0a2.345,2.345,0,0,1,2.132,1.353c.114.228.273.561.44.912l.2.424h0l.1.208.1.211a.218.218,0,0,0,.192.115,4.2,4.2,0,0,1,4.187,4.182h0v5.7a4.2,4.2,0,0,1-4.187,4.182H4.187A4.188,4.188,0,0,1,0,13.1H0v-5.7A4.188,4.188,0,0,1,4.187,3.223a.188.188,0,0,0,.182-.115h0l.058-.115c.269-.566.6-1.257.787-1.64A2.331,2.331,0,0,1,7.337,0H11.86ZM9.6,6.149A3.766,3.766,0,0,0,6.924,7.262,3.711,3.711,0,0,0,5.829,9.919,3.776,3.776,0,1,0,9.6,6.149Zm0,1.439A2.333,2.333,0,1,1,7.27,9.919h0v-.01a2.253,2.253,0,0,1,.672-1.631A2.326,2.326,0,0,1,9.6,7.588Zm5.387-1.669a.868.868,0,1,0,.874.873A.868.868,0,0,0,14.991,5.919Z' fill='%23fff'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
      background-size: auto;
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
    }
    input {
      font-family: "Ubuntu", sans-serif;
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      cursor: pointer;
    }
    &:hover {
      transform: scale(1.1);
      will-change: transform;
    }
  }
}
.RemoveImage {
  text-align: center;
  margin-top: -20px;
  background-color: transparent;
  border: 1px solid $input-border;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 65px;
  right: -20px;
  width: 42px;
  height: 42px;
  background-color: $red;
  border: 3px solid $white;
  transition: 0.3s ease transform;
  &:hover {
    transform: scale(1.1);
    will-change: transform;
  }
  svg {
    path {
      fill: $white;
    }
  }
}

.fieldWrapper {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  .fieldGroup {
    width: 100%;
    position: relative;
    margin-bottom: 15px;
    .date_input {
      position: relative;
      svg {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        pointer-events: none;
        path {
          fill: $text-color-alt;
        }
      }
    }
    label {
      color: $input-label;
      font-size: 14px;
      line-height: 1.5;
      text-decoration: none;
      font-weight: 500;
      display: block;
      width: 100%;
      margin-bottom: 5px;
      user-select: none;
    }
    input[type="date" i]::-webkit-calendar-picker-indicator {
      opacity: 0;
      cursor: pointer;
    }
    input,
    .textarea {
      font-family: "Ubuntu", sans-serif;
      width: 100%;
      position: relative;
      height: 50px;
      background-color: $input-background;
      border: 1px solid $input-border;
      color: $text-color-alt;
      border-radius: 10px;
      overflow: hidden;
      padding: 10px;
      font-size: 16px;
      line-height: 1;
      text-decoration: none;
      font-weight: 400;
      @media (max-width: 576px) {
          height: 40px;
      }
    }
    .textarea {
      height: 65px;
      textarea {
        resize: none;
        overflow: auto;
        border: none;
        width: 100%;
        height: 100%;
        background-color: $input-background;
        color: $text-color-alt;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }

  .checkBoxWrapper {
    width: 100%;
    position: relative;
    margin-bottom: 15px;
    .checkBox_buttons {
      display: flex;
    }
    .title {
      width: 100%;
      position: relative;
      color: $input-label;
      font-size: 14px;
      line-height: 1.5;
      text-decoration: none;
      font-weight: 600;
      display: block;
      margin-bottom: 5px;
      margin-top: 0;
      user-select: none;
    }
    .checkBoxItem {
      width: 100%;
      border-radius: 30px;
      border: 1px solid #d3d5db;
      height: 42px;
      margin-right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      transition: 0.3s all ease-in-out;
      max-width: 118.33px;
      &.state_disabled {
        background-color: transparent !important;
        border-color: #d3d5db !important;
        &.checked {
          margin-right: 10px;
          label {
            color: $text-color-alt;
          }
        }
      }
      label {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        // padding: 0 34px;
      }
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        border: 1px solid rgb(151, 151, 151);
      }
      &.checked {
        background-color: #1d5eff;
        border: 1px solid #1d5eff;
        color: #fff;
        label {
          color: #fff;
        }
      }
    }
    label {
      color: $text-color-alt;
      font-size: size(16);
      line-height: 1.5;
      text-decoration: none;
      font-weight: $font-medium;
      display: block;
      width: 100%;
      margin-bottom: 0px;
      transition: 0.3s all ease-in-out;
    }
    input {
      font-family: "Ubuntu", sans-serif;
      opacity: 0;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 9;
      cursor: pointer;
    }
  }
}
.actionButton {
  button {
    // background-color: transparent;
    // border-color: $text-color-alt;
    // color: $text-color-alt;
    svg {
      path {
        fill: $text-color-alt !important;
        transition: 0.3s ease fill;
      }
    }
    &:hover {
      background-color: transparent;
      border-color: $primary-color;
      color: $primary-color;
      svg {
        path {
          fill: $primary-color !important;
        }
      }
    }
  }
}
.deleteBtn {
  svg {
    path {
      transition: 0.3s ease fill;
    }
  }
  &:hover {
    svg {
      path {
        fill: $red !important;
      }
    }
  }
  button {
    &.disabled {
      background-color: $btn-disabled;
      border-color: $btn-disabled;
      cursor: default;
      &:hover,
      &:focus {
        background-color: $btn-disabled !important;
        border-color: $btn-disabled !important;
        color: #fff !important;
        svg {
          path {
            fill: #fff !important;
          }
        }
      }
    }
  }
}

.state_disabled {
  opacity: 0.4;
}

.error {
  font-size: 12px;
  line-height: 1.5;
  color: #f00;
  margin: 10px 0px;
}

// @keyframes slideAnim {
//   0% {
//     transform: translateX(100%);
//     opacity: 0;
//   }
//   100% {
//     transform: translateX(0);
//     opacity: 1;
//   }
// }

.drawer_display {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 200px);
  &_bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    margin-top: 10px;
    margin-bottom: 15px;
    button {
      min-width: inherit;
      padding: 8px 12px;
      font-size: size(14);
      font-weight: 400;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        padding-left: 8px;
      }
    }
  }
}
.display_profile_data {
  &:not(:last-child) {
    margin-bottom: 30px;
  }
  &_label {
    font-size: size(14);
    font-weight: $font-regular;
    margin-bottom: 16px;
    color: $input-label;
    display: block;
  }
  &_value {
    font-size: size(16);
    font-weight: $font-regular;
    color: $text-color-alt;
  }
}

// :global {
//   body {
//     &.sideDrawerOpen {
//       .SideDrawer_root {
//         transform: translateX(100%);
//       }
//     }
//   }
// }

:global {
  .sideDrawer-enter {
    transform: translateX(100%);
  }
  .sideDrawer-enter-active {
    transform: translateX(0);
    transition: 0.5s ease-in-out transform;
  }
  .sideDrawer-exit {
    transform: translateX(0);
  }
  .sideDrawer-exit-active {
    transform: translateX(100%);
    transition: 0.5s ease-in-out transform;
  }
}

.topActionBtn{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
  min-height: 25px;
  :global{
    .btn-link{
      min-width: auto;
      svg{
        margin-right: 8px;
        path{
          fill: #014afe !important;
        }
      }
      &:hover{
        svg path{
          fill: #014afe !important;
        }
      }
    }
  }


  .actionButton{
    margin-left: auto;
    :global{
      .btn-link{
        border: none;
        background-color: transparent;
        padding: 0px;
        font-weight: 600;
        color: #014afe;
        font-size: 16px;
        path{
          fill: #014afe;
        }
      }
    }
  }
}


.drawer_display_bottom{
  &:empty{
    display: none;
  }
  .actionButton{
    width: auto;
    :global{
      .btn-grey{
        width: 50%;
      }
      .btn {
        width: auto;
      }
      
    }
  }
  .cancel,
  .deleteBtn{
    width: auto;
    svg{
      margin-right: 6px;

    }
    
  }
  :global{
    .btn {
      border-radius: 6px;
      font-size: 0.9375rem;
      font-weight: 500;
      padding: 6px 20px;
      width: 100%;
      min-height: 39px;
      span{
        padding-left: 0;
      }
    }
    .btn-grey{
      background-color: transparent;
      border: 1px solid var(--input-border);
      color: var(--text-color-alt);
      width: 50%;
      &:hover{
        background-color: rgba(0,0,0,.05);
      }
    }
  }
  .cancel{
    :global{
      .btn-grey{
        width: 100%;
        max-width: 100%;
      }
    }
  }
  .deleteBtnWrapper{
    width: 100%;
    display: flex;
    column-gap: 10px;
    justify-content: center;
  }
}

.deleteBtnWrapper{
  :global{
    .btn{
      svg path{
        fill: #000 !important;
      }
      &.btn-red{
        svg path{
          fill: #fff !important;
        }
      }
    }
  }
}


:global(.dark){
  .deleteBtnWrapper{
    :global{
      .btn{
        color: #fff !important;
        svg path{
          fill: #fff !important;
        }
      }
    }
  }
    
  
}