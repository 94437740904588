@import "../../../../../styles/uitilities.scss";


.AlbumCreateSection {
    &_input {
      font-size: size(14);
      font-weight: $font-regular;
      color: $text-color-alt;
      opacity: 0.52;
    }
    span{
      margin: 8px 0 8px 8px;
      display: inline-block;
      color: red;

    }
    :global(.btn) {
      min-width: auto;
    }
    &_actions {
      button{

      }
      // display: flex;
      // justify-content: center;
      // align-items: center;
    }
  }

  .listContainer {
    overflow-y: scroll;
    height: 200px;
  }
  
  .listContainer::-webkit-scrollbar {
    display: none;
  }

  .albumList {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 13px 0;
    cursor: pointer;
    .gotoAction {
      opacity: 0;
      visibility: hidden;
      transform: translateX(-5px);
      transition: 0.2s ease all;
    }
    &:hover {
      background-color: #45453942;
      .gotoAction {
        opacity: 1;
        visibility: visible;
        transform: translateX(0);
      }
    }
  }

  .spinner {
    width: 35px;
    height: 35px;
    margin-left: 50px;
  }

  .add_to_new_album_btn_wrap {
    font-size: size(18);
    font-weight: 500;
    margin: 13px 0;
    display: flex;
    align-items: center;
    .new_album_label {
      padding-left: 15px;
      color: $text-color;
      cursor: pointer;
      display: flex;
      align-items: center;
      height: 58px;
    }
    &:hover {
      .add_to_album_btn {
        color: $text-alt-hover;
      }
    }
  }

  .add_to_album_btn {
    border: 1px solid $input-border;
    border-radius: 11px;
    color: $text-color;
    width: 58px;
    height: 58px;
    background-color: transparent;
    svg {
      color: currentColor;
      path {
        fill: currentColor;
      }
    }
  }


  .add_to_album {
    :global(.modal-dialog) {
      max-width: 335px;
      :global(.modal-content) {
        padding: 20px 30px;
      }
      :global(.modal-header) {
        height: auto;
        border-bottom: 1px solid $input-border;
        margin-bottom: 13px;
        :global(.modal-title) {
          color: $text-color;
        }
      }
      :global(.h4) {
        font-size: size(24);
        margin-bottom: 6px;
      }
      :global(.btn-close) {
        top: 18px !important;
        right: 19px !important;
        font-size: 15px !important;
      }
    }
  }

