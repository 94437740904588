@import '../../styles/uitilities.scss';

.home_create_explore {
  padding: 133px 0 112px;
  background: $dark-bg-alt url('../../assets/images/create-and-explore-bg.webp') no-repeat;
  background-size: cover;
  transition: 0.5s ease all;
  @include max(991.98) {
    padding: 70px 0;
  }
  @include max(767.98) {
    padding: 55px 0;
    background: $dark-bg-alt url('../../assets/images/create-and-explore-bg-mob.webp') no-repeat;
    background-size: cover;
  }
  :global(.player-controls) {
    padding: 0 20px;
  }
  :global(.col) {
      @include max(991.98) {
        flex: 0 0 100% !important;
        max-width: 100% !important;
      }
      &:nth-child(1) {
        flex: 0 0 46%;
        max-width: 46%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      &:nth-child(2) {
        flex: 0 0 54%;
        max-width: 54%;
      }
  }
  &_title {
    margin-top: -10px;
    margin-bottom: 50px;  
    // max-width: 380px; 
    max-width: 430px;
    @include max(991.98) {
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      max-width: 440px;
      margin-bottom: 40px;
    }
    @include max(767.98) {
      max-width: 370px;
    }
    h2 {
      @include max(767.98) {
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  &_button {
    @include max(991.98) {
      text-align: center;
      margin-top: 49px;
    }
  }
  :global(#myvideo:not(.full_screen)) {
    border: none!important;
    > div {
      border-radius: 6px;
    }
    video{
      border: 4px solid #D3D5DB;
      border-radius: 16px;
    }
  }
}