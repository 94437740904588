@import "../../../../styles/uitilities.scss";

.left_filtered_category {
  scrollbar-width: none;
  position: relative;
  height: 25px;

  &::-webkit-scrollbar {
    display: none;
  }
  &_list {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    @include max(1199.98) {
      display: flex;
    }
    div {
      text-transform: capitalize;
      display: inline-block;
      padding: 0 22px;
      user-select: none;
      @include max(1649.98) {
        padding: 0 12px;
      }
      &::before {
        content: "";
        width: 0;
        height: 2px;
        background: $blue;
        position: absolute;
        bottom: -25px;
        left: 0;
        transition: 0.3s ease all;
        @include max(1439.98) {
          bottom: -21px;
        }
        @include max(1199.98) {
          bottom: -16px;
        }
      }
      a {
        font-size: size(18);
        font-weight: $font-regular;
        line-height: 1;
        color: $text-color-alt !important;
        opacity: 0.61;
        transition: 0.3s ease all;
        display: flex;
        align-items: center;
        white-space: nowrap;
        @include max(1599.98) {
          font-size: size(16);
        }
        &.tab_active {
          opacity: 1;
          text-shadow: 0px 0px 0.3px #000;
        }
      }
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        @include max(991.98) {
          padding-right: 0;
        }
      }

      &:hover {
        a {
          opacity: 1;
        }
      }
    }
  }

  .floater {
    background-color: $modal-link;
    position: absolute;
    height: 2px;
    top: 39px;
    border-radius: 2px;
    transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  .back_button {
    border: none;
    background-color: transparent;
    font-size: size(17);
    font-weight: $font-regular;
    display: flex;
    align-items: center;
    color: $text-color-alt;

    svg {
      margin-right: 10px;

      path {
        fill: $text-color-alt;
      }
    }
  }

  .Album_item {
    display: flex;
  }
  .hide {
    display: none;
  }
}

.currentItemCount {
  display: block;
  padding: 4px 8px;
  font-size: size(13);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  margin-left: 5px;
  margin-top: 1px;
  background-color: $blank-folder-outer;
  color: $text-color-alt;
}

.left_filtered_category_list a{
  @include max(1800){
    font-size: 14px!important;
  }
  @include min-max(1200,1399.9){
    font-size: 13px!important;
  }
}
